import React, { Fragment, useContext } from 'react';

import { Button, Modal } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';

const PrivacyPolicy = () => {
  const rootStore = useContext(RootStoreContext);
  const {closeModal } = rootStore.modalStore;

  return (
       <Fragment>
       <Modal.Header>Privacy Policy</Modal.Header>
        <Modal.Content>
        <p>Privacy policy terms</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            onClick={() => closeModal()}  
          />
        </Modal.Actions>
       
     </Fragment>
    );
};

export default observer(PrivacyPolicy);
