import axios, { AxiosResponse } from 'axios';
import { IActivity, IActivitiesEnvelope } from '../models/activity';
import { toast } from 'react-toastify';
import { ISetpasswordFormValues, IUser, IUserFormValues, IUserValidationFormValues } from '../models/user';
import { IProfile, Photo } from '../models/profile';
import { router } from '../router/Routes';

axios.defaults.baseURL = `https://${process.env.REACT_APP_API_URL!.trim()}.azurewebsites.net`;

axios.interceptors.request.use(
  ( config: any ) => {
    const token = window.localStorage.getItem('appToken');
    // console.log('token = ', token)
    if (config.url !== "/dqopisqrt/image/upload" &&  config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      config.baseURL = 'https://api.cloudinary.com/v1_1';
      config.headers ? config.headers.Authorization = '' : console.error('We have NO config.headers');
    }
    // if (token) axios.defaults.withCredentials = true;
    // console.log('Axios config: ', config)
    return config;
  },
  (  error: any) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use((res: any) => {
  // console.log('response interceptors data = ', res.data);

  if (res.data.Code === 9006) { // failed login attempt
    let failTime = Date.now();
    let failCount: any | undefined;
    const previousFails = window.localStorage.getItem('loginFailCount');
    if (previousFails) {
      failCount = parseInt(previousFails) + 1;
    }  else {
      failCount = 1;
    }
    window.localStorage.setItem('loginFailTime', failTime.toString());
    window.localStorage.setItem('loginFailCount', failCount);
    toast.error(res.data.Message);
    return res.data.error = 9006;
  } else if (res.data.Code === 9005) {
    toast.error(res.data.Message);
    // 9005 (account locked) - this will only happen if front end account lock isn't working or
    // is bypassed i.e. call directly to API
    // history.push('/');
  }else if (res.data === "NotAuthorized"){
    toast.error("Your IP address is not authorised - Please contact Opus 2 support.");
    return res.data.error = 9006;
  } 
  else {
    return res;
  }
});

axios.interceptors.response.use(undefined, (error: { message: string; response: { status: any; data: any; config: any; headers: any; }; }) => {
  let failTime = Date.now();
  let failCount: any | undefined;
  window.localStorage.setItem('loginFailTime', failTime.toString());
  window.localStorage.setItem('loginFailCount', failCount);
  console.log('In axios.interceptors.response error')
  if (error.message === 'Network Error' && !error.response) {
    toast.error('Network error - Do you have internet connection?');
  }
  const { status, data, config, headers } = error.response;
  const apiError = error;
  console.log('error.response = ', apiError)
  // console.log('status = ', status)
  // console.log('data = ', data)

  if (status === 404) {
    router.navigate('/notfound');
  }
  if (status === 401 && headers['www-authenticate'] === 'Bearer error="invalid_token", error_description="The token is expired"') {
    window.localStorage.removeItem('jwt');
    router.navigate('/')
    toast.info('Your session has expired, please login again')
  }
  if (
    status === 400 &&
    config.method === 'get' &&
    data.errors.hasOwnProperty('id')
  ) {
    router.navigate('/notfound');
  }
  if (
    // status === 400 && data.hasOwnProperty('message') // where message is an error currently
    data.hasOwnProperty('Message') && data.Message === 'Invalid Username or Password' // where Message is an error currently
  ) {
    let failTime = Date.now();
    let failCount: any | undefined;
    const previousFails = window.localStorage.getItem('loginFailCount');
    if (previousFails) {
      failCount = parseInt(previousFails) + 1;
    }  else {
      failCount = 1;
    }
    window.localStorage.setItem('loginFailTime', failTime.toString());
    window.localStorage.setItem('loginFailCount', failCount);
  }
  if (status === 500) {
    toast.error('Server error - check the terminal for more info!');
  }
  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) =>
    axios
      .get(url)
      .then(responseBody),
  post: (url: string, body: {}) =>
    axios
      .post(url, body, {
        headers: { 'Content-type': 'application/json' }
      })
      .then(responseBody),
  put: (url: string, body: {}) =>
    axios
      .put(url, body)
      .then(responseBody),
  del: (url: string) =>
    axios
      .delete(url)
      .then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    formData.append('File', file);
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' }
      })
      .then(responseBody);
  }
};

const Activities = {
  list: (params: URLSearchParams): Promise<IActivitiesEnvelope> =>
    axios.get('/activities', {params: params}).then(responseBody),
  details: (id: string) => requests.get(`/activities/${id}`),
  create: (activity: IActivity) => requests.post('/activities', activity),
  update: (activity: IActivity) =>
    requests.put(`/activities/${activity.id}`, activity),
  delete: (id: string) => requests.del(`/activities/${id}`),
  attend: (id: string) => requests.post(`/activities/${id}/attend`, {}),
  unattend: (id: string) => requests.del(`/activities/${id}/attend`)
};

const Contacts = {
  // list: (id: number): Promise<IContact> => requests.get(`http://localhost:5000/UserContacts?&userConactId=${id}`),
  contactByEmail: (data: any) => requests.post(`/GraphQL`, data)
};

// const Groups = {
//   list: (): Promise<IGroup> => requests.get(`http://localhost:5000/UserGroups`),
//   create: (group: IGroup) => requests.post(`http://localhost:5000/UserGroups`, group)
// }

const User = {
  getAppToken: (name: string, val: string): Promise<any> => requests.post('/account/authenticate', {'username': name, 'password': val}),
  // current: (): Promise<IUser> => requests.get('http://localhost:5000/Users'),
  login: (user: IUserFormValues): Promise<IUser> =>
  // requests.get(`/user?&username=${user.email}&password=${user.password}`),
  // requests.post(`/Users/authenticate`, user),
  requests.post(`api/account/login`, user),
  // requests.post(`/account/authenticate`, user),
  // When we have API for user registration uncomment next two lines & remove others!!
  // register: (user: IUserFormValues): Promise<IUser> =>
  //   requests.post(`/user/register`, user)
  loginStepTwo: (user: IUserValidationFormValues): Promise<IUser> => requests.post(`/api/account/LoginTwoStep`, user),
  register: (newUser: IUserFormValues) => requests.post('/api/account/register', newUser),
    // window.localStorage.setItem('userRegistration', JSON.stringify(user)),
  resetPassword: (email: IUserFormValues): Promise<IUser> => requests.post(`/api/account/forgotpassword`, email),
  resendEmail: (emailAndReturnUrl: IUserFormValues) => requests.post('/api/account/resendConfirmation', emailAndReturnUrl),
  getUserOptions: (id: any) => requests.get(`/api/UserOptions/CanReadList/${id}`),
  setPassword: (user: ISetpasswordFormValues): Promise<any> =>  requests.post(`api/account/setpassword`, user)
};

const BarCouncilChambers = {
  barCouncilChamberById: (data: any) => requests.post(`/GraphQL`, data),
  allBarCouncilChambers: (data: any) => requests.post(`/GraphQL`, data)
};

const Sra = {
  sraOrgById: (data: any) => requests.post(`/GraphQL`, data),
  sraOrgByName: (data: any) => requests.post(`/GraphQL`, data),
  sraOfficeLookup: (data: any) => requests.post(`/GraphQL`, data),
  sRAOrganisationOfficeSearch: (data: any) => requests.post(`/GraphQL`, data),
  getSRAWorkAreas: (data: any) => requests.post(`/GraphQL`, data)
}

const Profiles = {
  get: (username: string): Promise<IProfile> =>
    requests.get(`/profiles/${username}`),
  uploadPhoto: async (file: Blob) => {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'dsolu45c');
    return await axios.post<Photo>('/dqopisqrt/image/upload', formData);
  },
  setMainPhoto: (id: string) => requests.post(`/photos/${id}/setMain`, {}),
  deletePhoto: (id: string) => requests.del(`/photos/${id}`),
  updateProfile: (profile: Partial<IProfile>) =>
    requests.put(`/profiles`, profile),
  follow: (username: string) =>
    requests.post(`/profiles/${username}/follow`, {}),
  unfollow: (username: string) => requests.del(`/profiles/${username}/follow`),
  listFollowings: (username: string, predicate: string) =>
    requests.get(`/profiles/${username}/follow?predicate=${predicate}`),
  listActivities: (username: string, predicate: string) =>
    requests.get(`/profiles/${username}/activities?predicate=${predicate}`)
};

const Businesses = {
  // getBusinesses: () => requests.get('/api/Business')
  getBusinesses: (params: string) => requests.get(`/api/Office${params}`),
  getBusinessOffices: (id: string) => requests.get(`/api/Office?BusinessId=${id}`),
  getBusinessProfile: (id: string) => requests.get(`/api/Business/${id}`),
  getOfficeProfile: (id: string) => requests.get(`/api/Office/${id}`),
  getSraWorkAreasForBusinesses: () => requests.get('/api/sra_workarea'),
};
const People = {
  getPeople: (searchString: string) => requests.get(`/api/Person${searchString}`),
  getPeoplePerBusiness: (id: string) => requests.get(`/api/Person?businessID=${id}`),
  getPerson: (id: string) => requests.get(`/api/Person/${id}`),
};

// param: GroupCode = BarMutualAreaOfPractice, LanguageSpoken etc
const NetworkGroups = {
  getNetworkGroupsByGroupCode: (GroupCode: string) => requests.get(`/api/NetworkGroups/${GroupCode}`),
};

 const agents = {
  Activities,
  User,
  Profiles,
  Contacts,
  BarCouncilChambers,
  Sra,
  Businesses,
  People,
  NetworkGroups
};

export default agents;
