import React, { useContext, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import PushMenu from './PushMenu';
import { useMediaQuery } from 'react-responsive';
import { toJS } from 'mobx';
import { useNavigate } from "react-router-dom";

const Navigation: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { logout , loggedInUser, getUserPermissions} = rootStore.userStore;
	const { setMenuLevel, currentMenuLevel } = rootStore.navigationStore;
	const { getPreviousActiveChambersPage, setPreviousActiveChambersPage } = rootStore.commonStore;
	//const [userName, setUserName] = useState( ${loggedInUser!.FirstName});
	const type: string = 'Over';
	const dockBar = useRef<any | null>();
	const configObj = useRef<any | null>();
	const systemObj = useRef<any | null>();
	const searchObj = useRef<any | null>();
	const billingObj = useRef<any | null>();
	const utilitiesObj = useRef<any | null>();
	const apiObj = useRef<any | null>();
	const userName = loggedInUser ? loggedInUser!.User!.UserName : '';
	const optionToShow = toJS(getUserPermissions());

	const ShowSearchObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'Search';});
	const showSystemObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'System';});
	const showDashboardObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'Dashboard';});
	const showCasesObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'MyCases';});
	const showNewsObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'News';});
	const showEventsObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'Events';});

	const [openSidebarList, setOpenSidebarList] = useState({
		systemObjBool: false,
		billingObj: false,
		configObj: false,
		utilitiesObj: false,
		apiObj: false,
		searchObj: false
	});

	const isMobile = useMediaQuery({
		query: '(min-width: 1224px)'
	});

	const navigate = useNavigate();

	const sidebarMenuSelect = (page: string) => {
		// reset previous active page marker is navigating away from chambers 
		setPreviousActiveChambersPage(1);
		switch (page) {
			case "Contacts":
				navigate('contacts');
				break;
			case "Chambers":	
				navigate('chambers');
				break;
			case "Solicitors":
				navigate('solicitors');
				break;
			case "Businesses":
				navigate('businesses');
				break;
			case "People":
				navigate('people');
				break;
			case "Barristers":
				navigate('people/barristers');
				break;
			case "Group List":
				navigate('groups');
				break;    
			case "Group Chat":
				navigate('groupschat');
				break;    
			case "Add Group":
				navigate('createGroup');
				break;    
			case "Add Activity":
				navigate('createActivity');
				break; 
			case "Profile Settings":
				navigate('profile/' + userName);
				break;  		
			case "Dashboard":
				navigate('dashboard')
				break;	
			case "Cases":
				navigate('cases')
				break; 		
			case "Reading List":
				navigate('reading-list')
				break; 	
			case "Events":
				navigate('events')
				break; 
			default:
				break;
		}
		closeAllSidebars();
	}

  const AccountMenuItem = [{ 
		  //text: userName,
		  //src: '/assets/user.png',
		  iconCss: 'e-icons e-file',
		  items: [
			  { text: 'Settings' },
			  { text: 'Sign out' },
		  ]
	  }];

  const userMenuSelect = (args: any) => {
		if(args.item.text === "Sign out") {
			logout();
		} else {
			navigate('profile/bob');
		}
	}

  const onCreate = () => {
	dockBar.current.hide();
	/*configObj.current.element.style.visibility = '';
	systemObj.current.element.style.visibility = '';
	billingObj.current.element.style.visibility = '';
	utilitiesObj.current.element.style.visibility = '';
	apiObj.current.element.style.visibility = '';*/
	}

	const toggleDockBar = () => {
		dockBar.current.toggle();
		
		let smMenu = document.querySelector('.sm__menu .sm__level');
		let smPusher = document.querySelector('.sm__pusher');
		
		// these are never going to be null so ignore TS warnings
		if(smMenu!.classList.contains('sm__level--overlay')){
			smMenu!.classList.remove('sm__level--overlay'); 
			smPusher!.classList.remove('sm--pushed'); 
		}
		else{
			smMenu!.classList.add('sm__level--overlay');
			smPusher!.classList.add('sm--pushed'); 
		}
	}

	const closeAllSidebars = () => {
		const list: string[] = ['sm--pushed', 'sm__pusher--level0', 'sm__pusher--level1', 'sm__pusher--level2'];
		let sb, allOpenSidebars;
		let smPusher = document.querySelector('.sm__pusher');
		smPusher!.classList.remove(...list);

		// don't select dockSidebar for closure
		allOpenSidebars = document.querySelectorAll('.e-open:not([id^="dockSidebar"])');
		setMenuLevel(0);

		for (const [key, value] of Object.entries(allOpenSidebars)) { 
			sb = eval(value.id);
			sb.current.hide();
			setOpenSidebarList(prevState => {
				return { ...prevState, [value.id]: false }
			});
		}
	}

	const toggleSidebar = (sidebarObj: any, homeOrCogs = '') => {
		let shouldOpen: boolean;
		let smPusher = document.querySelector('.sm__pusher');

		const pusherLevelList: string[] = ['sm__pusher--level1', 'sm__pusher--level2'];

		// ummm, currentMenuLevel needs to be 1 for mobile and 2 for laptop/desktop!
		let levelToUse = isMobile === true ? 1 : 2;

		const _closeSidebars = (lowerLevelSb = '') => { 
			let sb, selectedSidebars;
			
			// if lowerLevelSb = '' only close one sidebar at click event level
			if (lowerLevelSb === '') {
				selectedSidebars = document.querySelectorAll('.e-open:not([class^="level_1_"])');
				setMenuLevel(currentMenuLevel - 1);
			}
			else {
				selectedSidebars = document.querySelectorAll('.e-open');
				setMenuLevel(1);
				smPusher!.classList.remove(...pusherLevelList);
			}

			for (const [key, value] of Object.entries(selectedSidebars)) { 
				sb = eval(value.id);
				sb.current.hide();
				setOpenSidebarList(prevState => {
					return { ...prevState, [value.id]: false }
				});
			}
		}

		const _showAddHideRemove = (counter: number, addRemove: string, sidebarObj: any, homeOrCogs = '' ) => {
			setMenuLevel(currentMenuLevel + counter);
			
			if (addRemove === 'add') {
				sidebarObj.current.show();
				smPusher!.classList.add('sm--pushed', `sm__pusher--level${currentMenuLevel}`);
			} else {
				sidebarObj.current.hide();
				if (homeOrCogs === 'home') {
					console.log('In _showAddHideRemove hiding levels 1 & 2 because homeOrCogs =', homeOrCogs)
					smPusher!.classList.remove(...pusherLevelList);
				} else if(homeOrCogs === 'cogs') {
					console.log('In _showAddHideRemove hiding just level 2 because homeOrCogs =', homeOrCogs)
					smPusher!.classList.remove( `sm__pusher--level${currentMenuLevel - 1}`);
				}		
			}
		}

		switch (true){
			case sidebarObj.current.className.includes('level_2_billing'): 
				shouldOpen = currentMenuLevel === levelToUse ? true : false;
				if (shouldOpen && !openSidebarList.billingObj) { // open it
					setOpenSidebarList(prevState => {
						return { ...prevState, billingObj: shouldOpen }
					});
					_showAddHideRemove(1, 'add', sidebarObj);
				}
				else if (openSidebarList.billingObj) { // close it
					setOpenSidebarList(prevState => {
						return { ...prevState, billingObj: false}
					});
					_showAddHideRemove(-1, 'remove', sidebarObj, homeOrCogs);
				}
				else _closeSidebars();
				break;
			case sidebarObj.current.className.includes('level_2_config'):
				shouldOpen = currentMenuLevel === levelToUse ? true : false;
				if (shouldOpen && !openSidebarList.configObj) {
					setOpenSidebarList(prevState => {
						return { ...prevState, configObj: shouldOpen }
					});
					_showAddHideRemove(1, 'add', sidebarObj);
				}
				else if (openSidebarList.configObj) {
					setOpenSidebarList(prevState => {
						return { ...prevState, configObj: false}
					});
					_showAddHideRemove(-1, 'remove', sidebarObj, homeOrCogs);
				}
				else _closeSidebars();
				break;
			case sidebarObj.current.className.includes('level_2_utilities'):
				// shouldOpen = true if currentMenuLevel = 1 (for mobile) or 2 (for desktop)
				shouldOpen = currentMenuLevel === levelToUse ? true : false;
				if (shouldOpen && !openSidebarList.utilitiesObj) {
					setOpenSidebarList(prevState => {
						return { ...prevState, utilitiesObj: shouldOpen }
					});
					_showAddHideRemove(1, 'add', sidebarObj);
				}
				else if (openSidebarList.utilitiesObj) {
					setOpenSidebarList(prevState => {
						return { ...prevState, utilitiesObj: false}
					});
					_showAddHideRemove(-1, 'remove', sidebarObj, homeOrCogs);
				} 
				else _closeSidebars();
				break;
			case sidebarObj.current.className.includes('level_2_api'):
				shouldOpen = currentMenuLevel === levelToUse ? true : false;
				if (shouldOpen && !openSidebarList.apiObj) {
					setOpenSidebarList(prevState => {
						return { ...prevState, apiObj: shouldOpen }
					});
					_showAddHideRemove(1, 'add', sidebarObj);
				}
				else if (openSidebarList.apiObj) {
					setOpenSidebarList(prevState => {
						return { ...prevState, apiObj: false}
					});
					_showAddHideRemove(-1, 'remove', sidebarObj, homeOrCogs);
				} 
				else _closeSidebars();
				break;
			case sidebarObj.current.className.includes('level_1_system'):
				// only toggle systemObj state if no other sub-menus open
				let systState = currentMenuLevel > 1 ? openSidebarList.systemObjBool : !openSidebarList.systemObjBool;
				setOpenSidebarList(prevState => {
					return { ...prevState, systemObj: systState }
				});
				if (systState && currentMenuLevel < 2) {
					setOpenSidebarList(prevState => {
						return { ...prevState, systemObj: systState }
					});
					_showAddHideRemove(1, 'add', sidebarObj);
				}
				else if (!systState && currentMenuLevel < 2) {
					_showAddHideRemove(-1, 'remove', sidebarObj, homeOrCogs);
				}
				else _closeSidebars('level_1_');      
				break;
			
			case sidebarObj.current.className.includes('level_1_search'):
				// only toggle systemObj state if no other sub-menus open
				let searchState = currentMenuLevel > 1 ? openSidebarList.searchObj : !openSidebarList.searchObj;
				setOpenSidebarList(prevState => {
					return { ...prevState, searchObj: searchState }
				});
				if (searchState && currentMenuLevel < 2) {
					setOpenSidebarList(prevState => {
						return { ...prevState, searchObj: searchState }
					});
					_showAddHideRemove(1, 'add', sidebarObj);
				}
				else if (!searchState && currentMenuLevel < 2) {
					_showAddHideRemove(-1, 'remove', sidebarObj, homeOrCogs);
				}
				else _closeSidebars('level_1_');      
				break;

			default:
				break;
		}
	}

  return (
	<> 
		<PushMenu
			dockBar={dockBar}
			toggleDockBar={toggleDockBar}
			toggleSidebar={toggleSidebar}
			configObj={configObj}
			systemObj={systemObj}
			searchObj={ShowSearchObj.length > 0 ? searchObj : undefined}
			showDashboardObj={showDashboardObj.length > 0 ? searchObj : undefined}
			showCasesObj={showCasesObj.length > 0 ? searchObj : undefined}
			showNewsObj={showNewsObj.length > 0 ? searchObj : undefined}
			showEventsObj={showEventsObj.length > 0 ? searchObj : undefined}

			// searchObj={searchObj}
			billingObj={billingObj}
			utilitiesObj={utilitiesObj}
			apiObj={apiObj}
			type={type}
			onCreate={onCreate}
			sidebarMenuSelect={sidebarMenuSelect}
			userMenuSelect={userMenuSelect}
			AccountMenuItem={AccountMenuItem}
			closeAllSidebars={closeAllSidebars}
		/>
  </>
  );
};

export default observer(Navigation);
