import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Button, Modal, Header } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';

export default observer (function LoginStepTwoResponse() {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { validTwoFactorCode } = rootStore.userStore;

  const [responseMsg, setResponseMsg] = useState('');

  useEffect(() => {
    console.log('Ran useEffect in LoginStepTwoResponse')
    if (validTwoFactorCode === false) {
      setResponseMsg('Sorry, the authentication code is incorrect or has expired.');
    }
  });

  return (
    <Fragment >
        <Header content='Authentication Code' />
        <Modal.Content>
          <p>{responseMsg}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            onClick={() => closeModal()} 
          />
        </Modal.Actions>
    </Fragment>
    );
})
