// bar council chambers 
// - BarCouncil_Chambers, BarCouncil_Barristers
const barCouncilChamberById = (id: string) => { 
	let qStr = JSON.stringify({
	query: `query LexNetworkQuery($id: ID!){
		barCouncilChamberById (barCouncil_ChamberId: $id) {
		name
		lexName
		barCouncilNumber
		barristers{
		barCouncil_BarristerId
		forenames
		surName
		barMemberNumber
		barCouncil_AreaOfPractice
		category
		categoryCode
		}
		}
		}`,
			variables: {"id":id}
	});
	return qStr;
}

const allBarCouncilChambers = () => { 
	let qStr = JSON.stringify({
		query: `query LexNetworkQuery {
		barCouncilChambers {
		barCouncil_ChamberId
		name
		lexName
		barCouncilNumber
		}
		}`
	});
	return qStr;
}

// contacts
// - Contacts
const contactByEmail = (email: string) => {
	let qStr = JSON.stringify({
		query: `query LexNetworkQuery($email: String!){
		contactByEmail(email: $email){
		firstName
		lastName
		}
		}`,
			variables: {"email": email}
	});
	return qStr;
}

// SRA
const sraOrgsById = (id: string) => {
	let qStr = JSON.stringify({
		query: `query LexNetworkQuery($id: ID!){
		sRAOrganisationById(sRAOrganisationId: $id){
		sRA_OrganisationId
		sraNumber
		practiceName
		tradingNames
		type
		workAreas
		sRA_OrganisationId
		authorisationDate
		authorisationType
		authorisationStatusDate
		authorisationType
		companyRegNo
		constitution
		authorisationDate
		previousNames
		regulator
		freelanceBasis
		numberOfPeople
		numberOfOffices
		reservedActivities
		offices{
		address1
		address2
		address3
		address4
		country
		email
		name
		officeType
		phoneNumber
		postcode
		sRA_OfficeId
		town
		website
		}
		}
		}`,
		variables: {"id": id}
});
	return qStr;
}

const sraOrgsByName = (name: string) => {
	let qStr = JSON.stringify({
		query: `query LexNetworkQuery($name: String!) {
		sRAOrganisationLookUp(name: $name) {
		sRA_OrganisationId
		sraNumber
		practiceName
		tradingNames
		type
		workAreas
		sRA_OrganisationId
		authorisationDate
		authorisationType
		authorisationStatusDate
		authorisationType
		companyRegNo
		constitution
		authorisationDate
		previousNames
		regulator
		freelanceBasis
		numberOfPeople
		numberOfOffices
		reservedActivities
		offices {
		address1
		address2
		address3
		address4
		country
		email
		name
		officeType
		phoneNumber
		postcode
		sRA_OfficeId
		town
		website
		}
		}
		}`,
		variables: {"name": name}
});
	return qStr;
}

const sraOfficeLookup = (name: string) => {
	let qStr = JSON.stringify({
		query: `query LexNetworkQuery($name: String!, $city: String!, $postcode: String){
		sRAOfficeLookUp(name: $name, city: $city, postcode: $postcode) {
		sRA_OfficeId  
		address1
		address2
		address3
		address4
		country
		email
		name
		officeType
		phoneNumber
		postcode
		town
		website
		sRA_Organisation{
		practiceName
		sRA_OrganisationId
		sraNumber
		practiceName
		tradingNames
		type
		workAreas
		sRA_OrganisationId
		authorisationDate
		authorisationType
		authorisationStatusDate
		authorisationType
		companyRegNo
		constitution
		authorisationDate
		previousNames
		regulator
		freelanceBasis
		numberOfPeople
		numberOfOffices
		reservedActivities
		}
		}
		}`,
		variables: {"name": name}
});
	return qStr;
}

const getSRAWorkAreas = (name: string) => {
	let qStr = JSON.stringify({
		query: `query LexNetworkQuery {
			getSRAWorkAreas{
			workArea
			}
			}`
	});
	return qStr;
}

const sRAOrganisationOfficeSearch = (name?: string, city?: string, postcode?: string, workarea?: string, ukLatLongOnly?: boolean) => {
	let qStr = JSON.stringify({
		query: `query LexNetworkQuery($name: String, $city: String, $postcode: String, $workarea: String, $ukLatLongOnly: Boolean) {
		sRAOrganisationOfficeSearch(name: $name, city: $city, postcode: $postcode, workarea: $workarea, ukLatLongOnly: $ukLatLongOnly) {         
		address1,
		address2,
		address3,
		address4,
		town,
		postcode,
		country,
		website,
		latitude,
		longitude,
		sRA_Organisation {
		practiceName,
		workAreas
		}
		}
		}`,
		variables: {"name": name, "city": city, "postcode": postcode, "workarea": workarea, "ukLatLongOnly": ukLatLongOnly}
	});
	return qStr;
}


const queries = {
  barCouncilChamberById,
	allBarCouncilChambers,
	contactByEmail,
	sraOrgsById,
	sraOrgsByName,
	sraOfficeLookup,
	getSRAWorkAreas,
	sRAOrganisationOfficeSearch
}

export default queries;