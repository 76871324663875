import React, { Fragment, useContext } from 'react';

import { Button, Modal } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
export default observer (function TermsAndConditions() {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;

  return (
      
      <Fragment>
        <Modal.Header>Terms &amp; Conditions</Modal.Header>
          <Modal.Content>
          <p>The following are terms and conditions of use of the Opus 2 LEX Network and your continuous use of this site constitutes your acceptance of these terms.</p>
        <p>All products or service names mentioned on this site are trademarks or registered trademarks of their respective owners. The website, and all data, text, images, video or audio contained therein are for non-commercial and personal use only. No license to utilise the proprietary logo, service marks, or trademarks is granted by making them available on this website.
You are welcome to duplicate, store, and print copies of all information provided here for personal use, with the understanding that Opus 2 Limited retains the copyright on all media including text, graphics, and photographs.</p>
        <p>Opus 2 Limited has taken steps to ensure that all the product information featured on this website is accurate and up-to-date. However, whilst we take all reasonable steps to ensure that the information contained within these pages is accurate and up-to-date, no liability can be accepted for any errors or omissions. If you wish to rely on this information, then it may be in your best interests to seek verification by contacting us.</p>
        <p>Opus 2 Limited reserves the right to introduce technical modifications and disclaims any liability for errors. Opus 2 Limited reserves the right to terminate or modify the terms and conditions of any special offer/promotion on this site.</p>
        <p>This website may contain links to or from other websites. We are not responsible for the content appearing on, products or services available via, or the privacy or other practices used by, other websites. Some content on this site may be served from websites not under the control of Opus 2 Limited. Opus 2 Limited disclaims any liability for such content.
Opus 2 Limited.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Close"
              onClick={() => closeModal()}  
            />
          </Modal.Actions>
        
      </Fragment>

    );
})
