import React, { Fragment, useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';

import { Button, Modal } from 'semantic-ui-react'

const LoginResponse = () => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore

  return (
    <Fragment>
      <Modal.Header>Email verification required</Modal.Header>
      <Modal.Content>
        <Modal.Description>
        <p>
        <i className="icon large evelope"></i><br />Your registration is almost complete.</p>
        <p>It looks like you have registered but not confirmed your email address.</p>
        <p>Please click on the link within the email we sent you to complete your registration.<br /><br /></p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Close"
          onClick={() => closeModal()} 
          positive
        />
      </Modal.Actions>
    </Fragment>

    );
};

export default LoginResponse;
