import React from 'react';
const ProfileCases = () => {

  return (
    <div className="ui center aligned grid container margin-fix">
    <div className="fourteen wide computer sixteen wide mobile column">
        <div className="b-profile-case">
        <h2>Name <br/> -v- <br/> Name</h2>
        <p>In aliqua ex amet et labore sit sint excepteur irure.</p>
        <div className="b-profile-case-status">
            <p>Status: Ongoing</p>
            <p><img src="./assets/icons/court.svg"/>High</p>
            <p><img src="./assets/icons/law.svg"/>Commercial</p>
        </div>
        </div>
        <div className="b-profile-case">
        <h2>Name <br/> -v- <br/> Name</h2>
        <p>In aliqua ex amet et labore sit sint excepteur irure.</p>
        <div className="b-profile-case-status">
            <p>Status: Won</p>
            <p><img src="./assets/icons/court.svg"/>Crown</p>
            <p><img src="./assets/icons/law.svg"/>Employment</p>
        </div>
        </div>
    </div>
    </div>
  );
};

export default ProfileCases;