import React, { useContext, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import SubmitRegistration from '../user/SubmitRegistation';

const EmailConfirmation = () => {
  const rootStore = useContext(RootStoreContext);
  const{ openModal } = rootStore.modalStore;
	const { setUserAction, resendEmail } = rootStore.userStore;
  const resendEmailData = JSON.parse(localStorage.getItem('resendVerificationEmailData')!);

  const resendRegistration = (resendEmailData: any) => {
    try {
      resendEmail(resendEmailData);
      openModal(<SubmitRegistration />, 'small');
    } catch(error) {
      var regError = error;
      console.log('error resending Registration verification email');
    } finally {  
      if (regError === undefined) { // no error so all good
        openModal(<SubmitRegistration />, 'small');
      }  else {
        // TODO - open a model with an error message
        alert('Error resubmitting verification email');
      }
    }
  }

  return (
    <Fragment>
      <div className="reg__wrapper">
        <div className="ui middle center aligned horizontally padded grid" >
          <div className="column" style={{maxWidth: '800px'}}>
            <h2 className="ui teal image header center aligned">
              <div className="app-logo"></div>
            </h2>
            <div className="ui left aligned segment b-padding--x3 b-shadow b-border--none">
              <h2 className="ui center aligned icon header">
                <i className="circular envelope outline icon primary"></i>
              </h2>
              <h3 className="ui header">Welcome to the Opus 2 LEX Network</h3>
              <p>Thank you for registering with the Opus 2 LexNetwork. In order to provide the best service possible, we require you to verify your email address. </p>
              <p>An email has been sent to <strong>{localStorage.getItem('newUserEmailToBeConfirmed')}</strong>. Please click on the link within the email to verify your email address.<br /><br /></p>
              <button className="ui button primary" onClick={() => resendRegistration(resendEmailData)} >Resend Email</button>
              <button onClick={() => setUserAction('userLogin')} className="ui button">Back To Login</button>
            </div> 
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </Fragment>
  );
};

export default observer(EmailConfirmation);
