import { useEffect, useCallback, useState, useRef, RefObject, MutableRefObject } from 'react';

export default function useOnScreen<T extends Element>(ref: MutableRefObject<T>, rootMargin: string = "0px", defaultIntersect: boolean = false): boolean {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean>(defaultIntersect);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      // observer.unobserve(ref.current);
      console.log('In useOnScreen commented out observer.unobserve(ref.current) - causes Failed to execute unobserve on IntersectionObserver: parameter 1 is not of type Element. ref.current =', ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}