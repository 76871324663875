import React, { useRef } from 'react'

import { Button, Card, Grid, Icon, IconGroup, Label } from 'semantic-ui-react'

interface IProps {
  slideNext: (e: any) => void;
}

const Company: React.FC<IProps> = ({ slideNext }) => {
  const confirmAnchor: any = useRef();

  return (
    <div className="b-slidedeck__content text-align--left">
    <div className="b-slidedeck__title"><h3>Please confirm your company details:</h3></div>

    <Card className="left fluid b-shadow b-border--none company">
      <Card.Content textAlign="left">
        <div className="card__hero" style={{ }}>
            <div className="card__hero-col">
            
        <Card.Header><h2>Bar Squared Ltd</h2></Card.Header>
        <Card.Meta>
          <Label size="small" className="secondary" circular>Law Firm</Label>
          <span className="card__hero-location"><Icon name="map marker alternate" /> Leicester (HQ), 5+ other offices</span>  
        </Card.Meta>
            </div>
            <div className="card__hero-col">
            <Icon.Group size='huge' className="left floated card__image card__image-link">
              {/* with background image */}
              <span className="e-avatar e-avatar-large" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/company-blank.png)'}}></span>
              <Icon className="camera retro circle corner text--color-tertiary" /> 
            </Icon.Group>
            <Label pointing className="b-shadow--sm">Upload a square photo - 500 pixels by 500 pixels</Label>


            </div>
            {/*
            <Icon name="camera retro corner circle text--color-tertiary" size="large" />
  <Label pointing='right' className="b-shadow--sm">Upload a hero banner - 1280 pixels by 180 pixels</Label> */}
        </div>
        
        <Card.Description className="small">
          <h4>Bar Squared is one of the longest established sets in England with 209 members and 14 silks and operates as a single set from its three well-equipped locations. The set is fully accredited by the Bar Council to provide CPD courses and is IiP and BarMark accredited. Each office is staffed by friendly and experienced staff who can advise clients on the most suitable counsel for a particular case, as well as discuss fees and provide estimates for anticipated work.</h4>
         
          <Grid columns={2} stackable>
            <Grid.Row>
              <Grid.Column>
                <span className="card__full-links">
                  <a href="https://www.barsquared.com" className="card__link"><Icon name="linkify" size="small" />barsquared.com</a>
                  <a href="mailto:hello@barsquared.com" className="card__link"><Icon name="envelope" size="small" />hello@barsquared.com</a>
                  <a href="mailto:secure@barsquared.cjsm.com" className="card__link placeholder">
                    <IconGroup>
                      <Icon name="envelope" size="small" />
                      {/* <Icon name="lock corner" />  */}
                    </IconGroup> Add secure CJSM email address
                  </a>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span className="card__full-links">
                  <a href="https://www.linkedin.com/company/bar-squared-limited/" className="card__link placeholder"><Icon name="linkedin" size="small" />Add Linkedin Address</a>
                 <a href="https://twitter.com/barsquaredlex" className="card__link placeholder"><Icon name="twitter" size="small" />Add twitter address</a>
                 </span>
              </Grid.Column>
            </Grid.Row>
          </Grid > 
        </Card.Description>
      </Card.Content>
      <Card.Content extra className="text-align--right">
        <Button primary data-navigate='confirmCompanyDtls' onClick={(e: any) => slideNext(e)} ref={confirmAnchor}>Confirm Details</Button>
      </Card.Content>
    </Card>
  </div>
  );
};

export default Company;
