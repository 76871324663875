import React, { useRef } from 'react';

import { Card, Form, Icon, Label, Select, Image, Button, Dropdown } from 'semantic-ui-react';

interface IProps {
  toggleToolbar: (rte: any, btn: any, section: string) => void;
  cancelUpdate: (rte: any, btn: any, section: string) => void;
  isCurrentUser: boolean;
  cancelBtn: string;
  areasOfLaw: any[];
}

const Profile: React.FC<IProps> = ({ toggleToolbar, cancelUpdate, isCurrentUser, cancelBtn, areasOfLaw }) => {
  const btnCancelRec: any = useRef();
  const btnEditRec: any = useRef();
  const rteForRecommends: any = useRef();

  return (
    <div className="b-slidedeck__content text-align--left">
    <div className="b-slidedeck__title"><h3>Please add your directory profile:</h3></div>

    <Card className="left fluid b-shadow b-border--none contact">
      <Card.Content textAlign="left">
      <div className="card__hero" style={{ }}>
            <div className="card__hero-col">
            
        <Card.Header>
            <h2>John Smith</h2>
            <h4>Call 2008  &nbsp;|&nbsp; Silk 2019</h4>
            <Label size="small" className="secondary" circular>Barrister</Label><Label size="small" className="primary" circular><Icon className="handshake outline" />Mediator &nbsp;|&nbsp; Arbitrator</Label>
        </Card.Header>
        <Card.Meta>
          <span className="card__hero-location">
            Bar Squared LLP <Icon name="map marker alternate" /> Leicester (HQ)
          </span>  
        </Card.Meta>
            </div>
            <div className="card__hero-col">
            <Icon.Group size='huge' className="left floated card__image card__image-link">
              {/* with background image */}
              <span className="e-avatar e-avatar-large" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png)'}}></span>
              <Icon className="camera retro circle corner text--color-tertiary" /> 
            </Icon.Group>
            <Label pointing className="b-shadow--sm">Upload a square photo - 500 pixels by 500 pixels</Label>


            </div>
        </div>
        <Card.Description className="small profile">
          
          { isCurrentUser && (
            <Button fluid size='large' name='profileRecommends' className="dashed h-medium" ref={btnEditRec} onClick={() => toggleToolbar(rteForRecommends, btnEditRec, 'profileRecommends')}>Add a short summary</Button>
          )}
           {/* <h4><strong>John advises employers and employees in both the public and private sector on a broad range of contentious and non-contentious employment and HR issues.</strong></h4> */}
            
          <h5>Recommendations</h5>
          { isCurrentUser && (

            <Button fluid size='large' name='profileRecommends' className="dashed h-huge" ref={btnEditRec} onClick={() => toggleToolbar(rteForRecommends, btnEditRec, 'profileRecommends')}>Add a short description</Button>
            
          )}
          { cancelBtn === 'profileRecommends' && isCurrentUser &&  ( // can't pass btnCancelRec ref as a param to cancelUpdate because its null at this point 
            <button className="ui orange icon button" ref={btnCancelRec} onClick={() => cancelUpdate(rteForRecommends, btnEditRec, 'profileRecommends')}>
              Cancel 
            </button>
          )}
          {/* 
          <RichTextEditorComponent inlineMode={{enable: false, onSelection: false}} id="toolsRTE">
           
          </RichTextEditorComponent>
          */}
          {/*
          <p>John regularly advises on both day-to-day employment matters, including grievances, disciplinary matters, and sickness absence, as well as complex projects involving redundancy, restructuring and outsourcing. John also has considerable experience of conducting employment litigation, including advising on complex, multi-party discrimination and whistleblowing claims.</p>
          <p>In addition to his employment expertise, John specialises in business immigration, advising on a wide variety of immigration matters such as right to work issues, sponsor duties and visa applications.</p>
          <p>John is a member of the Employment Lawyers Association and the Immigration Law Practitioners Association. He also regularly runs client training seminars and is a contributor to XpertHR and Practical Law.</p>
          */}
          <Dropdown placeholder='Please add the areas of law you practice in' fluid multiple selection options={areasOfLaw} />
        </Card.Description>
      </Card.Content>
    </Card>
  </div>
  );

};

export default Profile;