import ActivityStore from './activityStore';
import UserStore from './userStore';
import { createContext } from 'react';
import { configure } from 'mobx';
import CommonStore from './commonStore';
import ModalStore from './modalStore';
import ProfileStore from './profileStore';
import ContactStore from './contactStore';
import GroupStore from './groupStore';
import NavigationStore from './navigationStore';
import SolicitorStore from './solicitorStore';
import BusinessStore from './businessStore';
import PersonStore from './personStore';

configure({enforceActions: 'always'});

export class RootStore {
    activityStore: ActivityStore;
    userStore: UserStore;
    commonStore: CommonStore;
    modalStore: ModalStore;
    profileStore: ProfileStore;
    contactStore: ContactStore;
    groupStore: GroupStore;
    navigationStore: NavigationStore;
    solicitorStore: SolicitorStore;
    businessStore: BusinessStore;
    personStore: PersonStore;

    constructor() {
        this.activityStore = new ActivityStore(this);
        this.userStore = new UserStore(this);
        this.commonStore = new CommonStore(this);
        this.modalStore = new ModalStore(this);
        this.profileStore = new ProfileStore(this);
        this.contactStore = new ContactStore(this);
        this.groupStore = new GroupStore(this);
        this.navigationStore = new NavigationStore(this);
        this.solicitorStore = new SolicitorStore(this);
        this.businessStore = new BusinessStore(this);
        this.personStore = new PersonStore(this);
    }
}

export const RootStoreContext = createContext(new RootStore());