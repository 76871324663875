import { useContext, useEffect, useState } from "react";
import L from "leaflet";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useMap } from "react-leaflet";
import { mapMarker } from "./MapMarker";
import { RootStoreContext } from "../../stores/rootStore";
import { router } from "../../router/Routes";

const mcg = L.markerClusterGroup();

const MarkerCluster = ({markers}: any) => {
  
  const Map = useMap();
  const [linkClicked, setLinkClicked] = useState(0);

  const rootStore = useContext(RootStoreContext);
  const {setSelectedBusinessOffices, setBusinessPeople} = rootStore.businessStore;

  const getSelectedBusiness = async (id: string, ind: number) => {
    await setSelectedBusinessOffices(id);
    await setBusinessPeople(id);
    router.navigate(`/business/${id}/${ind}`);
    setLinkClicked(linkClicked + 1);
  }

  useEffect(() => {
    // console.log('In markcluster useEffect!')
    mcg.clearLayers();
    if(markers) { // make sure we have markers before trying to iterate - could happen on new install
      markers.forEach((marker: any) => {
        if (marker.hasOwnProperty('sRA_Organisation')) {
        var name = marker.sRA_Organisation.practiceName;
        var address1 =  marker.address1 !== null ? `<br /> ${marker.address1}` : '';
        var address2 =  marker.address2 !== null ? `<br /> ${marker.address2}` : '';
        var postcode =  marker.postcode !== null ? `<br /> ${marker.postcode}` : '';
        var website =  marker.website !== null ? `<br /> ${marker.website}` : '';
        var distance =  marker.distanceFromYou !== null ? `<br /> ${marker.distanceFromYou} miles from your location` : '';
        var latToUse = marker.latitude;
        var longToUse = marker.longitude;
        var id = marker.BusinessId;
        var ind = marker.$id - 1;
        console.log('For some reason we have sRA_Organisation marker property!');
      } else {
        var name = marker.BusinessName;
        var address1 =  marker.AddressLine1 !== null ? `<br /> ${marker.AddressLine1}` : '';
        var address2 =  marker.AddressLine2 !== null ? `<br /> ${marker.AddressLine2}` : '';
        var postcode =  marker.PostCode !== null ? `<br /> ${marker.PostCode}` : '';
        var website =  marker.WebsiteUrl !== null ? `<br /> ${marker.WebsiteUrl}` : '';
        var distance =  marker.distanceFromYou !== null ? `<br /> ${marker.distanceFromYou} miles from your location` : '';
        var latToUse = marker.Latitude;
        var longToUse = marker.Longitude;
        var id = marker.BusinessId;
        var ind = marker.$id - 1;
      }
    
      L.marker(new L.LatLng(latToUse, longToUse), {
        icon: mapMarker
      })
      .addTo(mcg)
      .bindTooltip(`${name} ${address1} ${address2} ${postcode} ${website} ${distance}`, {
        offset: [-4, -42], direction: 'top'
      })
      .on('click', function() {
        getSelectedBusiness(id, ind);
      })
      });
    }

    try{
      // center the map around the markers
      Map!.fitBounds(mcg.getBounds());
    } catch (error) {
      console.warn('DOES THIS MATTER: ', error)
    }
    
    // add the marker cluster group to the map
    Map!.addLayer(mcg);

    try{
      // fly to marker
      let coords = JSON.parse(localStorage.getItem('flyToCoords')!);

      if (coords) Map?.flyTo([coords[0], coords[1]], 17);
    } catch (error) {
      console.warn('panTo error: ', error)
    }
  }, [markers, Map]);

  return null;
};

export default MarkerCluster;
