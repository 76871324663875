import React, { useContext, useEffect, useState } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';

import { Button, Label } from 'semantic-ui-react'
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import { observer } from 'mobx-react-lite';
import LoginStepTwoResponse from '../user/LoginStepTwoResponse';
import { toast } from 'react-toastify';

export default observer (function LoginStepTwo() {
  const rootStore = useContext(RootStoreContext);
  const { closeModal, openModal } = rootStore.modalStore;
  const { loginStepTwo, TwoFactorConfirmationToken, Email, validTwoFactorCode, setUserAction } = rootStore.userStore;
  const [pincodeSubmitted, setPincodeSubmitted] = useState<boolean>(false);
  
  const handleFormSubmit = (values: any, errors: any) => {
    values.TwoFactorConfirmationToken = JSON.parse(JSON.stringify(TwoFactorConfirmationToken));
    values.Email = JSON.parse(JSON.stringify(Email));
    console.log('values.... ', values);
    try{
      setPincodeSubmitted(true);
      loginStepTwo(values).catch((error: any) => errors(toast.error('Login validation code error, contact Opus 2 support'), setPincodeSubmitted(false)));


    } catch(error) {
      var loginStepTwoError = error;
      setPincodeSubmitted(false);
      
      toast.error("2fa error occured!");
      console.log('2fa error occured!');
      
    } finally {  
      setPincodeSubmitted(false);
      if (loginStepTwoError === undefined) { // no error so all good
        console.log('No 2fa errors - yay!');

      } else {
        console.log('In finally', loginStepTwoError);
      }
    }
  }

  const validationSchema = {
    TwoFactorCode: Yup.string().matches(/^\d+$/, 'Only numeric values please').required('Please enter validation code')
  };

  useEffect(() => {
    if (validTwoFactorCode === false) {
      console.log('In loginStepTwo.tsx useEffect validTwoFactorCode = ', validTwoFactorCode);
      setTimeout(() => {
        openModal(<LoginStepTwoResponse />, 'tiny');
      }, 1000);     
    }
  }, [validTwoFactorCode])

  return (
    <div className="reg__wrapper">
      <div className="ui middle center aligned horizontally padded grid" >
        <div className="column" style={{maxWidth: '800px'}}>
          <h2 className="ui teal image header center aligned">
            <div className="app-logo purple"></div>
          </h2>
          <div className="ui left aligned segment b-padding--x3 b-shadow b-border--none">
            <h2 className="ui center aligned icon header">
              <i className="circular key icon primary"></i>
            </h2>
            <h3 className="ui header">2 Step Verification</h3>
            <p>Please enter the verification code that was sent to <strong>{ Email } </strong><br /><br /></p>
            <Formik
              enableReinitialize={true}
              validationSchema={Yup.object().shape(validationSchema)}
              initialValues={{
                TwoFactorCode: '',
                TwoFactorConfirmationToken: JSON.parse(JSON.stringify(TwoFactorConfirmationToken)),
                Email: JSON.parse(JSON.stringify(Email)),
                error: null
              }}
              onSubmit={(values, {setErrors} ) => handleFormSubmit(values, setErrors)}
            >
              {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
                <Form 
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className="ui large form"
                >
                      <div className="ui stacked">
                      <div className="one field">
                        <div className="ui left icon input input__full-width">
                          <i className="lock icon"></i> 
                          <MyTextInput name='TwoFactorCode' placeholder='Verification Code' autoComplete="off"/>
                        </div>
                      </div>
                    </div>

                    {errors.error ? (
                        <>
                          <ErrorMessage
                            name='error'
                            render={() =>
                            <Label basic color='red' content={errors.error}/> }      
                          />      
                        </>
                      ) : (<></>)}
                      <br />
                      <div className="btn__wrapper">
                        <div className="btn__group right">
                            <Button loading={pincodeSubmitted} className="ui button primary" type='submit'>Submit Code</Button>
                            {/*<Button className="ui button">Resend Code</Button>*/}
                        </div>
                        <div className="btn__group left">
                            <button onClick={() => setUserAction('userLogin')} className="ui button">Back To Login</button>
                        </div>

                    </div>
                </Form>
              )}
            </Formik>
          </div> 
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
});
