import { RootStore } from "./rootStore";
import { observable, makeObservable, runInAction } from "mobx";
import agent from "../api/agent";

export default class PersonStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
      makeObservable(this, {
          offices: observable,
          previousPersonSearches: observable,
          selectedPersonProfile: observable,
      });

      this.rootStore = rootStore;
  }

  offices = [];
  previousPersonSearches = [];
  selectedPersonProfile = {Email: ''}; // Setting Email prevents ts warning in profileStore

  setPreviousPersonSearches = (prevSearch: any) => {
    this.previousPersonSearches = prevSearch;
  };

  getPreviousPersonSearches = () => {
    return this.previousPersonSearches;
  };

  setPersonProfile =async (id: string) => {
    try {
      let personProfile = await agent.People.getPerson(id);  
      runInAction(() => {
        if (personProfile) {
          this.selectedPersonProfile = personProfile;
        }
        else {
          console.log('No business profile data');
        }
      });
    } catch (error) {
      console.log('We have an error getting person profile data: ', error); 
    } 
  };

  getPersonProfile = () => {
    return this.selectedPersonProfile;
  };
}