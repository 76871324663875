import React, { useContext, Fragment } from 'react';
// import { Container, Segment, Header, Button, Image } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
// import { RootStoreContext } from '../../app/stores/rootStore';
import LoginForm from '../user/LoginForm';
// import RegisterForm from '../user/RegisterForm';
import EmailConfirmation from '../registration/EmailConfirmation';
import ForgottenPassword from '../registration/ForgottenPassword';
import EmailVerified from '../registration/EmailVerified';
import LoginStepTwo from '../registration/LoginStepTwo';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import { CSSTransition, TransitionGroup} from 'react-transition-group';

export default observer (function HomePage() {
  const token = window.localStorage.getItem('jwt');
  const userRegistration = window.localStorage.getItem('userRegistration');
  const passwordRequest = window.localStorage.getItem('passwordRequest');
  const emailVerified = window.localStorage.getItem('emailVerified');
  const LoginRequiresTwoFactorViaEmail = window.localStorage.getItem('LoginRequiresTwoFactorViaEmail');
  
  const rootStore = useContext(RootStoreContext);
  const { isLoggedIn, loggedInUser, userAction } = rootStore.userStore;
  // const {openModal} = rootStore.modalStore;

  // use findIndex to get to choose the object key

const RenderHomepage = () => {
  switch(userAction) {
    case 'userLogin':
      return <LoginForm />
    case 'AwaitingEmailConfirmation':
      return <EmailConfirmation />
    case 'LoginRequiresTwoFactorViaEmail':
      return <LoginStepTwo />
    case 'emailVerified':
      return <EmailVerified />
    case 'passwordRequest':
      return <ForgottenPassword />
    default:
      return <LoginForm />
  }
}

return (
  <div className="homepage">
    <TransitionGroup>
      <CSSTransition key={ userAction } classNames="glide--down" timeout={1000}>
        <div className="glide">
          <RenderHomepage />
        </div>
      </CSSTransition> 
    </TransitionGroup>
  </div>
);

});

