import React, { Fragment } from 'react';
import { router } from '../../app/router/Routes';

const EmailVerified = () => {

  return (
    <Fragment>
      <div className="reg__wrapper">
        <div className="ui middle center aligned horizontally padded grid" >
          <div className="column" style={{maxWidth: '800px'}}>
            <h2 className="ui teal image header center aligned">
              <div className="app-logo"></div>
            </h2>
            <div className="ui left aligned segment b-padding--x3 b-shadow b-border--none">
              <h2 className="ui center aligned icon header">
                <i className="circular check icon primary"></i>
              </h2>
              <h3 className="ui header">Congratulations!</h3>
              <p>Your email address has now been verified. Please login to continue your account setup.<br /><br /></p>
              <button className="ui button primary" onClick={() => router.navigate('/')}>Login</button>
            </div> 
          </div>
        </div>
        <br />
        <br />
        <br />
      </div>
    </Fragment>
  );
};

export default EmailVerified;
