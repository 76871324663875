import { action, observable, makeObservable} from "mobx";
import { RootStore } from "./rootStore";

export default class NavigationStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      currentMenuLevel: observable,
      setMenuLevel: action,
      showNavStack: observable,
      setShowNavStack: action,
      showFilter: observable,
      setShowFilter: action
    });

    this.rootStore = rootStore;
  }
  
  currentMenuLevel = 0;
  showNavStack: boolean = false;
  showFilter: boolean = false;
  
  setShowNavStack = (status: boolean) => {
    this.showNavStack = status;
  };
  setShowFilter = (status: boolean) => {
    console.log('filter current status: ' + this.showFilter);
    this.showFilter = status;
    console.log('filter new status: ' + this.showFilter);
  };


  setMenuLevel = (level: number) => {
    this.currentMenuLevel = level;
  };
}



