import React, { Fragment, useRef, useContext } from 'react';
import { router } from "../../app/router/Routes";
import { RootStoreContext } from '../../app/stores/rootStore';
import { Card, Icon, Label,  Button} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const LEXProfileCheck: React.FC= () => {
    
    const rootStore = useContext(RootStoreContext);
    const { setRegistrationComplete } = rootStore.userStore;
    var currentRegStatus = JSON.parse(localStorage.getItem('registrationStatus')!);

    const lexProfileComplete = () => {
        setCurrentStepComplete(currentRegStatus, 'LEXProfileCheck');
        setRegistrationComplete(true);
        router.navigate('/chambers');
    };
    
    const setCurrentStepComplete = (currentRegStat: any, step: any) => {
        currentRegStat[step] = 'complete';
        localStorage.setItem('registrationStatus', JSON.stringify(currentRegStat));
    }

  return (
    <Fragment>
        <div className="b-guide__wrap fullscreen" >
            <div className="b-guide__content">
              <div className="b-guide__slider">
                <div className="b-slidedeck__container">
                    <div className="b-slidedeck__wrap b-slidedeck__wrap--slide-indv">



                            <div className="b-slidedeck__content text-align--left">
                                <Card className="left fluid b-shadow b-border--none contact">
                                <Card.Content textAlign="left">
                                <div className="card__hero" style={{ }}>
                                        <div className="card__hero-col">
                                            <Card.Header>
                                                <h2>John Smith</h2>
                                                <h4>Call 2008  &nbsp;|&nbsp; Silk 2019</h4>
                                                <Label size="small" className="secondary" circular>Barrister</Label><Label size="small" className="primary" circular><Icon className="handshake outline" />Mediator &nbsp;|&nbsp; Arbitrator</Label>
                                            </Card.Header>
                                            <Card.Meta>
                                            <span className="card__hero-location">
                                                Bar Squared LLP <Icon name="map marker alternate" /> Leicester (HQ)
                                            </span>  
                                            </Card.Meta>
                                        </div>
                                        <div className="card__hero-col">
                                        <Icon.Group size='huge' className="left floated card__image ">
                                        {/* with background image */}
                                        <span className="e-avatar e-avatar-large" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png)'}}></span>
                                        
                                        </Icon.Group>
                                        </div>
                                    </div>
                                    <Card.Description className="small profile">
                                    <p>Your LEX Network profile has been generated by LEX.</p>
                                    <p>Please confirm your details.</p>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="text-align--right">
                                    <Button primary onClick={(e) => { lexProfileComplete();e.preventDefault();return false; }}>Confirm</Button>
                                </Card.Content>
                                </Card>
                                {/* access failed */}
                                {/*
                                <Card className="left fluid b-shadow b-border--none contact">
                                <Card.Content textAlign="left">
                                    <Card.Description className="small profile">
                                    <p>You don't currently have access to the LEX Network. Please contact our support team via <Link to={"mailto:support@opus2.com?subject=LEX%20Network%3A%20Give%20me%20access%20please&body=Please%20give%20me%20access%20to%20your%20program."}>support@opus2.com</Link> to join our program.</p>
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra className="text-align--right">
                                    <Button primary onClick={(e) => { window.location.href = 'mailto:support@opus2.com?subject=LEX%20Network%3A%20Give%20me%20access%20please&body=Please%20give%20me%20access%20to%20your%20program.';e.preventDefault();return false;}}>Join</Button>

                                    
                                </Card.Content>
                                </Card>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </Fragment>
  );

};

export default LEXProfileCheck;