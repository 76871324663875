import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router, RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
// import 'react-widgets/dist/css/react-widgets.css';
import './app/layout/styles.css';
import App from './app/layout/App';
import './assets/css/global.css';
import * as serviceWorker from './serviceWorker';
import ScrollToTop from './app/layout/ScrollToTop';
import { router } from './app/router/Routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  );

root.render(
  <RouterProvider router={router} />
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
