import { useField } from 'formik';
import React from 'react'
// @ts-ignore
import { Form, Label, Icon, Button } from 'semantic-ui-react';
import { router } from '../../router/Routes';

interface Props {
  name: string;
  type?: string;
  placeholder: string;
  label?: string;
  disabled?: boolean;
  autoComplete?: string;
  hidden?: boolean;
  tabIndex?: number;
}

export default function MyTextInput(props: Props) {
  const [field, meta] = useField(props.name);
  return (
    <Form.Field style={{width: '100%'}} error={meta.touched && !!meta.error}>
      <label>{props.label}</label>
      <input {...field} {...props} />
      {meta.touched && meta.error ? (
        <Label basic color='red'> <Icon name="ban" fitted /> {meta.error}</Label>
      ) : null}
    </Form.Field>
    
  )
}
export function MyButton(id?: any) {
  
  return (
    <Button className="ui small button primary" onClick={() => _myFunc(id)}>View Profile</Button> 
    
  )
}

const _myFunc = (id: any) => {
  router.navigate(`/business/${id}`);
}