// import { observable, computed, action, runInAction } from 'mobx';
// import { IUser, IUserFormValues } from '../models/user';
// import { IGroup } from '../models/group';
// import agent from '../api/agent';
import { RootStore } from './rootStore';
// import { IContact } from '../models/contact';


export default class GroupStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  // @observable newGroup: IGroup | null = null;
  // @observable allGroups: IGroup | null = null;
  // @observable listAllGroups: IGroup | null = null;
  // @observable markTest: string = "";

  // @action createGroup = async (group: IGroup) => {
  //     try {
  //         this.markTest = "This is groupStore data";
  //         await agent.Groups.create(group);
  //         const allGroups = await agent.Groups.list();
  //         console.log('markTest', this.markTest)
  //         console.log('allGroupsallGroups', allGroups)
  //         runInAction(() => {
  //           this.newGroup = group;
  //           this.allGroups = allGroups;
  //           console.log('new group is ', group)
  //           console.log('all group are ', this.allGroups)
  //         });
  //     } catch (error) {
  //         console.error('Oops, somethings wrong: ', error);
  //     }

  // }

  // @action listGroups = async () => {
  //   const allGroups = await agent.Groups.list();
  //   runInAction(() => {
  //     this.allGroups = allGroups;
  //     console.log('all group are ', this.allGroups)
  //   });
  // }
 
}
