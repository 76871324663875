import React from 'react';

import { Card, Form, Select } from 'semantic-ui-react';

interface IProps {
  title: any;
}

const Details: React.FC<IProps> = ({ title }) => {

  return (
    <div className="b-slidedeck__content text-align--left">
      <div className="b-slidedeck__title"><h3>Please confirm your details:</h3></div>
      <Card className="left fluid b-shadow b-border--none address">  
        <Card.Content textAlign="left">
          <Form>
            <Form.Group>
              <Form.Field className="b-float-input" width={4}>
                <Select  options={title} placeholder="Select"  />
                <label>Title</label>
              </Form.Field>
              <Form.Field className="b-float-input" width={6}>
                <input placeholder=' ' />
                <label>First name</label>
              </Form.Field>
              <Form.Field className="b-float-input" width={6}>
                <input placeholder=' ' />
                <label>Last name</label>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="b-float-input" width={8}>
                <input placeholder='+44 (0)208 444 0000' />
                <label>Business Phone Number</label>
              </Form.Field>   
              <Form.Field className="b-float-input" width={8}>
                <input placeholder='+44 (0)7739 000 000' />
                <label>Mobile</label>
              </Form.Field>
            </Form.Group>
            <Form.Group>                            
              <Form.Field className="b-float-input" width={16}>
                <input placeholder='...@barsquared.com' />
                <label>Business Email</label>
              </Form.Field>
            </Form.Group>
            <Form.Group> 
              <Form.Field className="b-float-input"  width={16}>
                <input placeholder='.....cjsm.com' />
                <label>Secure Email</label>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="b-float-input" width={16}>
                  <input placeholder=' ' />
                  <label>Private Email</label>
              </Form.Field>
            </Form.Group> 
            <Form.Group>
              <Form.Field className="b-float-input" width={8}>
                  <div className="b-toggle">
                    <input type="checkbox" id="2FAswitch1" />
                    <div className="b-toggle__control"></div>
                    <label htmlFor="2FAswitch1">2FA via text</label>
                  </div>
              </Form.Field>
              <Form.Field className="b-float-input" width={8}>
                  <div className="b-toggle">
                    <input type="checkbox" id="2FAswitch2" />
                    <div className="b-toggle__control"></div>
                    <label htmlFor="2FAswitch2">2FA via email</label>
                  </div>
              </Form.Field>
            </Form.Group>
            {/*Only show for barristers*/}
            <Form.Group>
              <Form.Field className="b-float-input"  width={8}>
                <input placeholder='YYYY' />
                <label>Year of Call</label>
              </Form.Field>
              <Form.Field className="b-float-input"  width={8}>
                <input placeholder='YYYY' />
                <label>Year of Silk</label>
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field className="b-float-input" width={8}>
              Record type?
              </Form.Field>
            </Form.Group>    
          </Form>
        </Card.Content>
      </Card>
    </div>
  );
};

export default Details;