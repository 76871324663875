import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

import { Button, Modal } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { ProfileDragAndDrop }  from '../dragAndDrop/ProfileDragAndDrop';
import { ListContainer }  from '../dragAndDrop/ListContainer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import agent from '../../../app/api/agent';
import queries from '../../../app/api/queries';
import { MultiSelectComponent, FilteringEventArgs } from '@syncfusion/ej2-react-dropdowns';
import { toJS } from 'mobx';
import PhotoUploadWidget from '../../../app/common/photoUpload/PhotoUploadWidget';

const ProfileModal = ( id: any ) => {
	const rootStore = useContext(RootStoreContext);
	const { closeModal } = rootStore.modalStore;
	const { addProfileAol, getProfileAreasOfLaw, removeProfileAol, profile } = rootStore.profileStore;

	const  { isCurrentUser, uploadPhoto, 
		uploading, loading, setMainPhoto, deletePhoto }  = rootStore.profileStore;
	const [addPhotoMode, setAddPhotoMode] = useState(true);
	const [target, setTarget] = useState('');

  const introText: any = useRef();
  const recommendations: any = useRef();
  const emailAddr: any = useRef();
  const contactNo: any = useRef();
  const tenancy: any = useRef();
  const facebookLink: any = useRef();
  const linkedInLink: any = useRef();
  const twitterLink: any = useRef();
  const prevStoredPD: any = JSON.parse(localStorage.getItem('profileData')!);
  const profileData: any = prevStoredPD;
  const activePnl = useRef<string>('myProfile');

  const [searchAolResults, setSearchAolResults] = useState<any[]>([]);
  const [firstRun, setFirstRun] = useState(true);
  const [counting, setCounting] = useState(0);
  const [selectedAols, setSelectedAols] = useState<any>(JSON.parse(localStorage.getItem('multiselectcomboElement')!));
	const [preselected, setPreselected] =  useState(() => {
		let sl = JSON.parse(localStorage.getItem('sortableList')!);
			let selectedAols = sl.map((item: any) => {
				let textOnly: any[] = [];
				textOnly.push(item.text);
				return textOnly;
			});
			return selectedAols;
	});

  const sraFields: object = { value: 'WorkArea', text: 'WorkArea', SRA_WorkAreaId: 'SRA_WorkAreaId' };

  const getAreasOfLaw = async () => {
		let aolQuery = queries.getSRAWorkAreas('');
		
		try {
			let aol = await agent.Businesses.getSraWorkAreasForBusinesses();

			const aolWithInd = aol.map((aol: any, index: number) => {
				let SRA_WorkAreaId = {'SRA_WorkAreaId': index};
				return aol = {...aol, ...SRA_WorkAreaId};
			})

			if (aol) {
				// setSearchAolResults(aol.data.getSRAWorkAreas);
				setSearchAolResults(aolWithInd);
			}
			else {
				console.log('No solicitor aol data from API');
			};
		} catch (error) {
			console.log('We have an error getting AOL: ', error);
		};
  }

  const saveProfile = () => {
		// TODO save profile data to API & open modal to confirm storage
		alert(localStorage.getItem('profileData')!);

		closeModal();
  }

  const resetProfile = () => {
		// loose current changes and ie reset localStorage to previous state
		// Note, won't have to do this when API is working - just don't save it back

		localStorage.setItem('profileData', localStorage.getItem('prevProfileData')!);
		closeModal();
  }

  const changePanel = (evt: any, panelName: string) => {
		const sW = window.innerWidth;
		const content = document.getElementById('js-content');
		const navigation = document.getElementById('js-navigation');
		let i, panels, links;

		activePnl.current = panelName;

		panels = document.getElementsByClassName('content-panel');
		for (i = 0; i < panels.length; i++) {
			panels[i].classList.remove('content-panel-active');
		}

		links = document.getElementsByClassName('link');
		for (i = 0; i < links.length; i++) {
			links[i].classList.remove('link-active');
		}

		document.getElementById(panelName)!.classList.add('content-panel-active');
		evt.currentTarget.className += ' link-active';

		if (sW <= 768) {
			navigation!.classList.add('navigation-hide');
			content!.classList.add('content-active');
		}
  }

  function closeContentPanel() {
    const content = document.getElementById('js-content');
    const navigation = document.getElementById('js-navigation');

    navigation!.classList.remove('navigation-hide');
    content!.classList.remove('content-active');
  }

  const updateField = (field:any) => {
		profileData[field.current.name] = field.current.value
		localStorage.setItem('profileData', JSON.stringify(profileData));
  }

  const addAolToProfile = (args: any) => {
		/*@ts-ignore */
		let multiselectObj = document.getElementById('comboElement').ej2_instances[0]; 
		setSelectedAols({id: 1, text: args.itemData[multiselectObj.fields.value] }) // not used atm

		let aols =  toJS(getProfileAreasOfLaw());
		let nextId = aols.length + 1;
		let newAol =  {id: nextId, text: args.itemData[multiselectObj.fields.value]};
		
		const list = JSON.parse(localStorage.getItem('sortableList')!);
		list.push(newAol);
	
		localStorage.setItem('sortableList', JSON.stringify(list));
		
		addProfileAol(newAol);
		
		// addProfileAol({id: nextId, text: args.itemData[multiselectObj.fields.value]});

		setCounting(counting + 1);
  }

  const removeAolFromProfile = (args: any) => {
		/*@ts-ignore */
		let multiselectObj = document.getElementById('comboElement').ej2_instances[0]; 
		// console.log('you want to remove value ', args.itemData[multiselectObj.fields.value])
		// console.log('you want to remove text ', args.itemData[multiselectObj.fields.text])
		// console.log('you want to remove SRA_WorkAreaId ', args.itemData[multiselectObj.fields.SRA_WorkAreaId])
		// removeProfileAol(args.itemData[multiselectObj.fields.SRA_WorkAreaId]);
		removeProfileAol(args.itemData[multiselectObj.fields.value]);
		setCounting(counting + 1);
		// console.log('.profile!.areasOfLaw ---- ', toJS(profile!.areasOfLaw))
  }

	useEffect(() => {
		// console.log('selectedAols ---- ', preselected)
		// if (typeof localStorage.getItem('sortableList') === null) {
		// 	localStorage.setItem('sortableList', JSON.stringify([]));
		// }
		if (firstRun) {
			setFirstRun(false);
			getAreasOfLaw();
		};
	}, [])

	function OnSelect(args: any) { 
		/*@ts-ignore */
		let multiselectObj = document.getElementById('comboElement').ej2_instances[0]; 
		console.log(args.itemData[multiselectObj.fields.value]);
		setSelectedAols({id: 1, text: args.itemData[multiselectObj.fields.value] }) 
	}

	function handlePhotoUpload(file: Blob) {
		uploadPhoto(file).then(() => {
			setAddPhotoMode(true)

			const profImgData = {'userId': id.userId, 'imgUrl': profile!.image}
			localStorage.setItem('profileImage', JSON.stringify(profImgData))
		})
	}

  return (
	<DndProvider backend={HTML5Backend} debugMode={true}>
	   <Fragment>
			<Modal.Content style={{padding:0}}>
				<div className="modal small-h"> 
					<div className="navigation" id="js-navigation">
						<div className="link link-active" onClick={(e) => changePanel(e, 'myProfile')}>
							<i className="address card icon"></i>My profile 
						</div>
						<div className="link" onClick={(e) => changePanel(e, 'contactDetails')}>
							<i className="newspaper icon"></i> Contact details
						</div>
						<div className="link" onClick={(e) => changePanel(e, 'areasOfLaw')}>
							<i className="list icon"></i> Areas of law
						</div>
						<div className="link" onClick={(e) => changePanel(e, 'socialMedias')}>
							<i className="users icon"></i> Social media
						</div>
						<div className="link" onClick={(e) => changePanel(e, 'security')}>
							<i className="user secret icon"></i> Security
						</div>
						<div className="link" onClick={(e) => changePanel(e, 'myAccount')}>
							<i className="cog icon"></i> My account
						</div>
						<div className="link" onClick={(e) => changePanel(e, 'resetPassword')}>
							<i className="exclamation triangle icon"></i> Reset password
						</div>
					</div>
					<div className="content" id="js-content">
						<div className="content-panel content-panel-active" id="myProfile">
						<h3>My profile <i className="close icon modal-header-icon" onClick={() => closeModal()}></i><i className="arrow alternate circle left icon modal-mobile-control" onClick={(e) => closeContentPanel()}></i></h3>
							<div className="ui form">
								<PhotoUploadWidget uploadPhoto={handlePhotoUpload} loading={uploading}/>
								<div className="field">
									<label>Intro text</label>
									<textarea defaultValue={prevStoredPD.introText} ref={introText} onChange={() => {updateField(introText)}} name='introText' style={{height: '8em'}}></textarea>
								</div>
								<div className="field">
									<label>Recommendations</label>
									<textarea defaultValue={prevStoredPD.recommendations} ref={recommendations} onChange={() => {updateField(recommendations)}} name='recommendations' style={{height: '8em'}}></textarea>
								</div>
							</div>
						</div>
						<div className="content-panel" id="contactDetails">
							<h3>Contact details <i className="close icon modal-header-icon" onClick={() => closeModal()}></i><i className="arrow alternate circle left icon modal-mobile-control" onClick={(e) => closeContentPanel()}></i></h3>
							<form className="ui form">
								<div className="field">
									<label>Email address</label>
									<input type="text" defaultValue={prevStoredPD.emailAddr} ref={emailAddr} onChange={() => {updateField(emailAddr)}} name="emailAddr" placeholder="Email address" />
								</div>
								<div className="field">
									<label>Contact number</label>
									<input type="text" defaultValue={prevStoredPD.contactNo} ref={contactNo} onChange={() => {updateField(contactNo)}} name="contactNo" placeholder="Contact number" />
								</div>
								<div className="field">
									<label>Tenancy</label>
									<input type="text" defaultValue={prevStoredPD.tenancy} ref={tenancy} onChange={() => {updateField(tenancy)}} name="tenancy" placeholder="Tenancy" />
								</div>
							</form>
						</div>
						<div className="content-panel" id="areasOfLaw">
							<h3>Add areas of law to your profile <i className="close icon modal-header-icon" onClick={() => closeModal()}></i><i className="arrow alternate circle left icon modal-mobile-control" onClick={(e) => closeContentPanel()}></i></h3>
							<MultiSelectComponent id="comboElement" placeholder="Select area of law" fields={sraFields} 
								dataSource={searchAolResults} allowFiltering={true} removed={removeAolFromProfile} hideSelectedItem={true}
								enablePersistence={false} enableSelectionOrder={true} select={addAolToProfile} allowCustomValue={true} value={preselected} />
							<ListContainer />
						</div>
						<div className="content-panel" id="socialMedias">
							<h3>Social media <i className="close icon modal-header-icon" onClick={() => closeModal()}></i><i className="arrow alternate circle left icon modal-mobile-control" onClick={(e) => closeContentPanel()}></i></h3>
							<form className="ui form">
								<div className="field">
									<label>Facebook link</label>
									<input type="text" defaultValue={prevStoredPD.facebookLink} ref={facebookLink} onChange={() => {updateField(facebookLink)}} name="facebookLink" placeholder="Facebook link" />
								</div>
								<div className="field">
									<label>LinkedIn link</label>
									<input type="text" defaultValue={prevStoredPD.linkedInLink} ref={linkedInLink} onChange={() => {updateField(linkedInLink)}} name="linkedInLink" placeholder="LinkedIn link" />
								</div>
								<div className="field">
									<label>Twitter link</label>
									<input type="text" defaultValue={prevStoredPD.twitterLink} ref={twitterLink} onChange={() => {updateField(twitterLink)}} name="twitterLink" placeholder="Twitter link" />
								</div>
							</form>
						</div>
						<div className="content-panel" id="security">
							<h3>Security <i className="close icon modal-header-icon" onClick={() => closeModal()}></i><i className="arrow alternate circle left icon modal-mobile-control" onClick={(e) => closeContentPanel()}></i></h3>
							Security stuff
						</div>
						<div className="content-panel" id="myAccount">
							<h3>My account <i className="close icon modal-header-icon" onClick={() => closeModal()}></i><i className="arrow alternate circle left icon modal-mobile-control" onClick={(e) => closeContentPanel()}></i></h3>
							My account stuff
						</div>
						<div className="content-panel" id="resetPassword">
							<h3>Reset password <i className="close icon modal-header-icon" onClick={() => closeModal()}></i><i className="arrow alternate circle left icon modal-mobile-control" onClick={(e) => closeContentPanel()}></i></h3>
							Reset password stuff
						</div>
					</div>
				</div>

			</Modal.Content>
			<Modal.Actions style={{minHeight: '70px'}}>
			<Button className="ui right floated primary button"
				content="Save"
				onClick={() => saveProfile()}  
			/>
			<Button className="ui right floated secondary button"
				content="Discard"
				onClick={() => resetProfile()}  
			/>
			<Button className="ui right floated button"
				content="Close"
				onClick={() => closeModal()}  
			/>
			</Modal.Actions>   
		</Fragment>
	  </DndProvider>
	);
};

export default observer(ProfileModal);
