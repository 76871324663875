import { RootStore } from "./rootStore";
import { observable, makeObservable, action } from "mobx";

export default class ModalStore {
    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        makeObservable(this, {
            modal: observable.shallow,
            openModal: action,
            closeModal: action
        });

        this.rootStore = rootStore;
    }

    modal = {
        open: false,
        body: null,
        size: undefined as any
    };
    
    openModal = ( content: any, size: any ) => {
        console.log('open');
        this.modal.open = true;
        this.modal.body = content;
        this.modal.size = size;
    };

    closeModal = () => {
        console.log('close');
        this.modal.open = false;
        this.modal.body = null;
        this.modal.size = <const> 'small';
    };
}