import React, { useEffect, useState } from 'react';

import { Segment, Header, Icon } from 'semantic-ui-react';
import { router } from '../router/Routes';


const NotFound = () => {
  const [token] = useState(window.localStorage.getItem('jwt'));

  useEffect(() => {
    if (!token) {
      router.navigate('/');
    }
  }, [token]);
   

  return (
    <Segment placeholder>
      <Header icon>
        <Icon name='search' />
        Oops - we've looked everywhere but couldn't find this.
      </Header>
      <Segment.Inline>
        Oops - we've looked everywhere but couldn't find this.<br />
        Please try navigating to another page. 
      </Segment.Inline>
    </Segment>
  );
};

export default NotFound;