import React, { useContext} from 'react';

import { Button, Icon, Label, Image, Grid} from 'semantic-ui-react';
import { router } from '../../../../app/router/Routes';

interface IProps {

}

const BusinessEvents: React.FC<IProps> = () => {
    /*const loadSelectedEvent = async (id: string) => {*/
    const loadSelectedEvent = () => {
        //await setEvent(id);
        router.navigate('/event/');
    };
  
  return (
    <div className='b-section center'>
            
        <Grid className="container margin-fix">
        <Grid.Row columns={1}>
          <Grid.Column>
            <div className='b-result__title'><h2 className='text--color-primary-shade'>Upcoming This Month</h2></div>
            <div className='ui b-event-card b-shadow b-padding--x3 active' onClick={() => loadSelectedEvent()}>
                <div className='b-event-card__details'>
                    <span className='h2'>Professional Negligence Webinar</span>
                    <span className='h4'><span className='b-event-card__date text--color-primary'>Thu 24 <span className='month--long'>November</span><span className='month--short'>Nov</span></span> | <span className='b-event-card__time text--color-primary'>15:00 until 18:30</span></span>
                    <span className='h4'>Via Zoom</span>
                    <span className="p">This free event from senior members of the team will look a variety of relevant and topical talks. Full details of the event, topics being covered and timings can be found in the programme...</span>
                    <span className='b-event-card__user'>
                        <Label as='a' image>
                            <Image src={ process.env.PUBLIC_URL + '/assets/images/avatar/tiny/exchangechambers.png'} />
                            Exchange Chambers 
                        </Label>
                    </span>
                    <span className='b-event-card__labels'>
                        <Label size='tiny'>Personal Injury</Label><Label size='tiny'>CPD 3.5</Label>
                    </span>
                </div>
                <div className='b-event-card__img' style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/events/small/exchange-example.jpg)'}}></div>
            </div>
            <div className='ui b-event-card b-shadow b-padding--x3 active' onClick={() => loadSelectedEvent()}>
                <div className='b-event-card__details'>
                    <span className='h2'>Annual Local Government &amp; Social Housing Seminar 2022</span>
                    <span className='h4'><span className='b-event-card__date text--color-primary'>Wed 30 <span className='month--long'>November</span><span className='month--short'>Nov</span></span> | <span className='b-event-card__time text--color-primary'>13:00 until 17:30</span></span>
                    <span className='h4'>Liverpool Town Hall, High Street, Liverpool, L2 3SW</span>
                    <span className="p">A Mock Tribunal: Part 1 - During this mock tribunal we will cover procedural and substantive issues which arise in employment tribunals. Attendees for this session also need to book “J” in session 2...</span>
                    <span className='b-event-card__user'>
                        <Label as='a' image>
                            <Image src={ process.env.PUBLIC_URL + '/assets/images/avatar/tiny/exchangechambers.png'} />
                            Exchange Chambers 
                        </Label>
                    </span>
                    <span className='b-event-card__labels'>
                        <Label size='tiny'>Housing</Label><Label size='tiny'>CPD 5</Label>
                    </span>
                </div>
                <div className='b-event-card__img' style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/events/small/exchange-example2.jpg)'}}> </div>
            </div>
            <div className='b-result__title'><h2 className='text--color-primary-shade'>This December</h2></div>
            <div className='ui b-event-card b-shadow b-padding--x3 active'  onClick={() => loadSelectedEvent()}>
                <div className='b-event-card__details'>
                    <span className='h2'>Annual Insolvency Conference 2022 – Manchester</span>
                    <span className='h4'><span className='b-event-card__date text--color-primary'>Fri 2 <span className='month--long'>December</span><span className='month--short'>Dec</span></span> | <span className='b-event-card__time text--color-primary'>13:00 until 17:30</span></span>
                    <span className='h4'>The Edwardian, Free Trade Hall, Peter Street, Manchester M2 5GP</span>
                    <span className="p">Thank you for your support with our webinars, however, we are looking forward to meeting up, networking and enjoying breakfast together once again. The conference will be a half day event...</span>
                    <span className='b-event-card__user'>
                        <Label as='a' image>
                            <Image src={ process.env.PUBLIC_URL + '/assets/images/avatar/tiny/exchangechambers.png'} />
                            Exchange Chambers 
                        </Label>
                    </span>
                    <span className='b-event-card__labels'>
                        <Label size='tiny'>Insolvency</Label><Label size='tiny'>CPD 5</Label>
                    </span>
                </div>
                <div className='b-event-card__img' style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/events/small/exchange-example3.jpg)'}}></div>
            </div>

            <div className='b-result__title'><h2 className='text--color-primary-shade'>On Demand</h2></div>
            <div className='ui b-event-card b-shadow b-padding--x3 active' onClick={() => loadSelectedEvent()}>
                <div className='b-event-card__details'>
                    <span className='h2'>Professional Negligence Webinar</span>
                    <span className='h4'><span className='b-event-card__date text--color-primary'>On Demand</span> | <span className='b-event-card__time text--color-primary'>3.5 hours</span></span>
                    <span className="p">This free event from senior members of the team will look a variety of relevant and topical talks. Full details of the event, topics being covered and timings can be found in the programme...</span>
                    <span className='b-event-card__user'>
                        <Label as='a' image>
                            <Image src={ process.env.PUBLIC_URL + '/assets/images/avatar/tiny/exchangechambers.png'} />
                            Exchange Chambers 
                        </Label>
                    </span>
                    <span className='b-event-card__labels'>
                        <Label size='tiny'>Personal Injury</Label><Label size='tiny'>CPD 3.5</Label>
                    </span>
                </div>
                <div className='b-event-card__img' style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/events/small/exchange-example.jpg)'}}></div>
            </div>
            </Grid.Column>
        </Grid.Row>
    </Grid>      
    </div>
  )
};

export default BusinessEvents;
