import { action, observable, makeObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { RootStore } from './rootStore';
import { IContact } from "../models/contact";

export default class ContactStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      contacts: observable,
      loadingInitial: observable,
      getContacts: action
    });

    this.rootStore = rootStore;
  }

  contacts: IContact | null = null;
  loadingInitial = false;
    
  getContacts = async (id: number) => {
  //   try {
  //     const contacts = await agent.Contacts.list(id);
  //     runInAction(() => {
  //       this.contacts = contacts;
  //       console.log('contacts are ', contacts)
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  };
}