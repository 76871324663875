import React, { Fragment, useContext } from 'react';
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';
//@ts-ignore
import { Icon, Button, Label } from 'semantic-ui-react';

const FilterContainer:React.FC = ()  => {
    const rootStore = useContext(RootStoreContext);
    //const {modal: {open, body, size}, closeModal} = rootStore.modalStore;
    const { showFilter, setShowFilter } = rootStore.navigationStore;
  return ( 
      <Fragment>
            <div className={ 'side-filter__shade ' + (showFilter ?  'open' : '')} onClick={() => { setShowFilter(false)}}></div>
            <div className={ 'side-filter ' + (showFilter ?  'open' : '')}>
                
                <div className="side-filter__header">
                    <h3>Refine your search <Button className='icon'  onClick={(e: any) => { setShowFilter(false); e.preventDefault();return false;}}><Icon name="close" ></Icon></Button></h3>
                </div>
                <div className='side-filter__content'>
                    {/*
                    <div className="side-filter__filters">
                    <Label as='a'  title="Clear filter">
                            Filter 1 <Icon name='delete' />
                    </Label>
                    
                    <Label as='a'  title="Clear filter">
                            Filter 2 <Icon name='delete' />
                    </Label>
                    </div>
                    <div className="side-filter__topic">
                        <p>Distance</p>
                        <div className="ui form">
                            <div className="grouped fields">
                                <div className="field">
                                    <div className="ui radio checkbox">
                                        <input type="radio" tabIndex={0} className="hidden" />
                                        <label>0 - 5 miles</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui radio checkbox">
                                        <input type="radio"  className="hidden" />
                                        <label>6 - 10 miles</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui radio checkbox">
                                        <input type="radio"  className="hidden" />
                                        <label>11 - 15 miles</label>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui radio checkbox">
                                        <input type="radio"  className="hidden" />
                                        <label>16+ miles</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="side-filter__topic">
                        <p>Keywords</p>
                        <div className="ui form">
                            <div className="field">
                                <textarea rows={3}></textarea>
                            </div>
                            <div className="ui submit button">Add</div>                    
                        </div>
                    </div>
                    */}
                    <div className="side-filter__topic" >
                        <p>Languages</p>
                        <div className="ui form">
                        <div className="field">
                            <select className="ui fluid dropdown">
                                <option value="">State</option>
                                <option value="AL">English</option>
                                <option value="AK">French</option>
                                <option value="AZ">Spanish</option>
                            </select>
                            </div>                
                        </div>
                    </div>
                    <div className="side-filter__topic" >
                        <p>Business Type</p>
                        <div className="ui form">
                        <div className="field">
                            <select className="ui fluid dropdown">
                                <option value="">Select...</option>
                                <option value="0">Barristers</option>
                                <option value="1">Solicitors</option>
                                <option value="2">other</option>
                            </select>
                            </div>                
                        </div>
                    </div>
                </div>
                <div className="side-filter__footer">
                    <Button className="right floated" primary onClick={() => { setShowFilter(false)}}>Apply</Button>
                    <Button className="left floated" secondary onClick={() => { setShowFilter(false)}}>Cancel</Button>
                </div>
            </div>
        </Fragment>
    );
};

export default observer(FilterContainer);