import React, { Fragment, useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
// @ts-ignore
import { Button, Modal } from 'semantic-ui-react';

const SubmitRegistation = () => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { userAction } = rootStore.userStore;
  const firstLine = localStorage.getItem('resendVerificationEmailData') ? 'Registration details resent.' : 'Thank you for registering your details.';

  
  const verifyEmailJsx = <Fragment>
          <Modal.Header>Verification email sent</Modal.Header>
          <Modal.Content>
            <Modal.Description>
            <p>
            <i className="icon large evelope"></i><br />{firstLine}</p>
            <p>An email has been sent to your mailbox to verify your email address.</p>
            <p>Please click on the link to complete your registration.<br /><br /></p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Close"
              onClick={() => closeModal()} 
              positive
            />
          </Modal.Actions>
        </Fragment>;

  const userAlreadyExistsJsx = <Fragment>
          <Modal.Header>Registration email must be unique</Modal.Header>
          <Modal.Content>
            <Modal.Description>
            <p>
            <i className="icon large exclamation"></i></p>
            <p>The email address you have chosen is already in use.</p>
            <p>Please choose a unique email.<br /><br /></p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Close"
              onClick={() => closeModal()} 
              positive
            />
          </Modal.Actions>
        </Fragment>;
  
  const jsx = () => {
    switch(userAction) {
      case 'userAlreadyExists':
        return userAlreadyExistsJsx;
      default:
        return verifyEmailJsx;
    }
  }

  return jsx();
};

export default SubmitRegistation;
