import React, { useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import MyPasswordInput from '../../app/common/form/MyPasswordInput';
import { ISetpasswordFormValues } from '../../app/models/user';
import { useLocation } from 'react-router-dom';
import { router } from '../../app/router/Routes';
import { Button } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import SubmitSetPassword from '../user/SubmitSetPassword';

export default observer (function PasswordRese() {
  const rootStore = useContext(RootStoreContext);
  const { setPassword, getAppToken } = rootStore.userStore;
  const{ openModal } = rootStore.modalStore;
  const { search } = useLocation();

  // parse the query string into an object
  const params = new URLSearchParams(search);

  // we expect "token" & "email" parameters
  const email = params.get('email');
 
  var token = params.get('token');
  // the '+' symbol gets uri encoded to a whitespaces (%20) when params are extracted so need
  // to find and replace all occurrences of and convert back before sending to api
  token = token ? token.replace(/ /g, '+') : null;
  
  useEffect(() => {
    // if no search params go to login page
    if (search === '') router.navigate('/', {replace: true});

    // if either "token" or "email" parameters are null go to login page
    if (token === null || email === null) router.navigate('/', {replace: true});

    // we need the app token set here just as in the login page to
    // prevent the 401 notAuthorized response from the api
    getAppToken();
  }, []);

  const handleFormSubmit = (values: ISetpasswordFormValues, errors: any) => {
    try {
    setPassword(values).catch((error) => errors({error: 'Unable to set password pleaser contact Opus 2 Lex'}))
    .then(() => openModal(<SubmitSetPassword />, 'small'));
    }
    catch (error) {
      console.error('try catch error ', error)
    }
  } 

  const clickHandlerSubmit = (e:any) => {
  
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      // handle form submit on formik form
    }
  }

  const validationSchema = {
    Password: Yup.string().required('Password is required').matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Your password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
    ConfirmPassword: Yup.string()
     .oneOf([Yup.ref('Password'), null], 'Passwords must match')
  };

  return (
    <>
    <div className='b-container__scroller'>
      <div className="login__container">
        <div className="login__bg">
          <div className="login__bg-sec1"></div>
          <div className="login__bg-sec2"></div>
          <div className="login__bg-sec3"></div>
        </div>
        <div className="login__wrap login__wrap--open">
          
          <div className="login__center">
            <div className="login login--wide">
              <div className="login__logo-wrap">
                <div className="logo__logo-center">
                  <div className="login__logo-owner owner-logo"></div>
                  <div className="login__logo-reveal">
                      <div className="login__logo app-logo"></div>
                  </div>
                </div>
              </div>
              <div className={'login__form-wrap '}>
                <div className="login__form">
                  <Formik
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape(validationSchema)}
                    initialValues={{
                      Email: email,
                      Password: '',
                      ConfirmPassword: '',
                      Token: token,
                      Message: '',
                      ReturnUrl:'https://lexnetwork.opus2.com',
                      error: null
                    }}
                    onSubmit={(values, {setErrors} ) => handleFormSubmit(values, setErrors)} >
                    {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
                      <Form
                        onSubmit={handleSubmit}
                        className="ui large form"
                        autoComplete="off"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleSubmit();
                          }
                        }}
                      >
                        <div className="ui stacked segment">
                          <div className="field">
                            <div className="ui left icon input">
                              <i className="lock icon"></i> 
                              <MyPasswordInput tabIndex={1} name='Password' placeholder='Password' KeyDownHandler={clickHandlerSubmit} />   
                            </div>
                          </div>
                          <div className="field">
                            <div className="ui left icon input">
                              <i className="lock icon"></i>
                              <MyPasswordInput tabIndex={2} name='ConfirmPassword' placeholder='Confirm Password' KeyDownHandler={clickHandlerSubmit} />   
                            </div>
                          </div>
                          <div className="field" hidden>
                            <input  name="ReturnUrl" defaultValue='https://lexnetwork.opus2.com/setPassword' />
                          </div>
                          <div className="field" hidden>
                            <input  name="Email" type='text' defaultValue={email!} />
                          </div>
                          <div className="field" hidden>
                            <input  name="Token" type='text' defaultValue={token!} />
                          </div>
                          <div className="field" hidden>
                            <input  name="Message" type='text' defaultValue="Set password msg from LN" />
                          </div>
                          <div className="field">
                            <Button
                              content='Set Password'
                              className={'ui fluid large secondary submit button '}
                              type='submit'
                            />
                        </div> </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer */}
        <div className="footer">
          <div className="footer__col left">
            <div className="footer__link">
              <div className="footer__menu">
                <div className="footer__menu-row">
                  <span className="footer__menu-link--disabled">
                    <span className="icos icos--small icos-palette"></span>
                    v10.005.012
                  </span>
                </div>
                <div className="footer__menu-row">
                  <span className="footer__menu-link--disabled">
                    <span className="icos icos--small icos-history"></span>21 Feb
                    12: 41: 27
                  </span>
                </div>
              </div>
              <span className="footer__link-text">Opus 2 LEX Network v1.01</span>
            </div>

            <div className="footer__separator">|</div>
            <div className="footer__link">
              <div className="footer__menu">
                <div className="footer__menu-row">
                  <a href="tel:+441162725015" tabIndex={10} className="footer__menu-link">
                    <span className="icos icos--small icos-phone"></span>0116 272 5015
                  </a>
                </div>
                <div className="footer__menu-row">
                  <a
                    href="mailto:support@lexnetwork.co.uk"
                    tabIndex={11}
                    className="footer__menu-link">
                    <span className="icos icos--xsmall icos-email"></span>
                    support@lexnetwork.co.uk
                  </a>
                </div>
              </div>
              <span className="icos icos--xxsmall icos-help"></span>
              <span className="footer__link-text">Support</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
});


