import React, { Fragment, useContext } from 'react';

import { Button, Modal, Header } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import { Formik, Form, ErrorMessage } from 'formik';
import { IUserFormValues } from '../../app/models/user';
import MyTextInput from '../../app/common/form/MyTextInput';
import * as Yup from 'yup';
import EmailPasswordResponse from './EmailPasswordResponse';

export default observer (function EmailPasswordReset() {
    const rootStore = useContext(RootStoreContext);
    const { closeModal, openModal } = rootStore.modalStore;
    const { resetPassword } = rootStore.userStore;

    const handleFormSubmit = (values: IUserFormValues, errors: any) => {
      try {
        resetPassword(values).catch((error) => errors({error: 'Password reset failed please contact Opus 2 support'}));
        closeModal();

        setTimeout(() => {
          openModal(<EmailPasswordResponse />, 'tiny');
        }, 1000);
       
      } catch(error) {
        var rpError = error;
        console.log('Password reset error occured!');
      } finally {  
        if (rpError === undefined) { // no error so all good
          console.log('Should have password reminder email');
        } else console.log('In finally', rpError)
      }
     } 
     const validationSchema = {
        Email: Yup.string().required('Your email address is required')
    };

  return (
    <Fragment >
        <Header content='Password Reset' />
        <Modal.Content>
            <h2 className="ui center aligned icon header border--none">
                <i className="circular key icon primary"></i>
            </h2>
            <Formik
                enableReinitialize={true}
                validationSchema={Yup.object().shape(validationSchema)}
                initialValues={{
                  Email: ''
                }}
                onSubmit={(values, {setErrors} ) => handleFormSubmit(values, setErrors)} >
                {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
                
                <Form
                    onSubmit={handleSubmit}
                    className="ui large form"
                    autoComplete="off"
                  >
                    <div className="ui stacked">
                      <div className="one field">
                        <div className="ui left icon input input__full-width">
                          <i className="envelope icon"></i> 
                          <MyTextInput name='Email' placeholder='Email Address' type='email' autoComplete="off"/>
                        </div>
                      </div>
                    </div>
                      <p>Please enter the email address associated with your account and we'll send you an email with instructions to reset your password.</p>
                      <div className="btn__wrapper">
                    <div className="btn__group right">
                      <Button primary content="Send email instructions" type='submit' />
                    </div>
                    <div className="btn__group left">
                      <Button content="Close" onClick={() => closeModal()} />
                    </div>
                  </div>               
                </Form>       
                )}     
              </Formik>   
        </Modal.Content>
    </Fragment>
    );
})
