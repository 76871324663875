import L from 'leaflet';
import blueMarker from './Map_marker--blue.svg';

const mapMarker = new L.Icon({
    iconUrl: blueMarker,
    iconRetinaUrl: blueMarker, 
    iconSize: [41, 42],
    iconAnchor: [20, 42],
    popupAnchor: [0, -200],
    className: 'leaflet-div-icon__blue'
});

export { mapMarker };