import React, { Fragment, useContext} from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { Button, Icon, IconGroup, Label } from 'semantic-ui-react';
const Dashboard = () => {

    
	const rootStore = useContext(RootStoreContext);
    
	const { logout, loggedInUser } = rootStore.userStore;
	const firstName = loggedInUser ? loggedInUser!.User!.FirstName : '';
    const dayTheOfWeek = format(new Date(), 'EEEE')
    const date = format(new Date(), 'd LLLL')
    const dateSmall = format(new Date(), 'd LLL')
    const timeOfDay = () => {

        var data = [
            [22, 'Working late'],
            [18, 'Good evening'],
            [12, 'Good afternoon'],
            [4,  'Good morning'],
            [0,  'Working very late']
        ],
        hr = new Date().getHours();
        for (var i = 0; i < data.length; i++) {
            if (hr >= data[i][0]) {
                return data[i][1]
            }
        }
    }

  return (
    <Fragment>
        <div className='b-container__scroller'>
        <div className="parallax__wrap">
        <div className="parallax__section flex-center intro">
            <div className="parallax__layer align--top mid ">
                <div className="b-profile-header">
                    <div className="b-profile-hero container wide">
                        <div className="ui grid container">
                            <div className="sixteen wide column">           
                                <div className="dash__header">
                                    <span className='dash__header-title'>
                                        <h2>{ dayTheOfWeek }</h2>
                                        <h1><span className='month--full'>{ date }</span><span className='month--small'>{ dateSmall }</span></h1>
                                    </span>
                                    <span className='dash__weather'>
                                        <div className="weather">
                                            <div className='weather__details'>
                                                <span className='weather__type'>Scattered Showers</span>
                                                <span className='weather__location'>Leicester</span>
                                            </div>
                                            <div className='weather__icon'><Icon name="cloud" size='big'></Icon></div>
                                            <div className='weather__temp'>10&deg;c</div>
                                        </div>
                                    </span>
                                    <div className="dash__header-bg"></div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="parallax__layer top">
                <div className='b-welcome__wrap'>
                    <div className='b-welcome'>
                        <div className="b-welcome__col">
                            <div className='b-welcome__intro'>
                                <h2><strong>{ timeOfDay() } { firstName },</strong><br />
                                    Today you have a busy day ahead, with <Link to=" " className='link secondary underline'>2 appointments</Link> and <Link to=" "  className='link secondary underline'>3 tasks</Link> to complete.
                                </h2>
                            </div>
                            <div className='b-welcome__intro-btns'>
                                <Button href="#" className='primary'>
                                    <IconGroup>
                                        <Icon name='handshake' size='large' />
                                        <Label color='teal' floating size='tiny' circular>3</Label>
                                    </IconGroup>
                                    
                                    

                                    <span className="h5">Opportunities</span>
                                </Button>
                                <Button href="#" className='secondary inverted' >
                                    <Icon name='clock' size='large' />
                                    <span className="h5">Add Time Recording</span>
                                </Button>
                                <Button href="#" className='secondary inverted ' >
                                    <Icon name='pound sign' size='large' />
                                    <span className="h5">Submit Expenses</span>
                                </Button>
                            </div>
                        </div>
                        <div className="b-welcome__col">
                            <div className='b-welcome__cards'>
                                <div className="b-welcome__card stacked ui b-shadow--flat">
                                    <div className="project project--simple">
                                        <div className="project__side-bar  bg--color-secondary-shade">
                                            <Icon name="calendar alternate outline" size='large' />
                                        </div> 
                                        <div className="project__contents padding--x2">
                                            <div className="project__header center">
                                                <span className="h3">
                                                    <strong>10:00 - 11:30 Hearing</strong> <br />    
                                                    <Link to=' ' className='link primary underline'><Icon name="map marker alternate" size='small' />Manchester County Court (Deansgate)</Link><br />     
                                                    <Link to=' ' className='link primary underline'>Opus 2 LEX Chambers Management -v- Opus 2 Counsel</Link>
                                                    <a href="#" className="project__header-people-link">
                                                        <div className="e-avatar-stack">
                                                            <span className="e-avatar e-avatar-small e-avatar-circle bg--color-blue3">JEN</span>
                                                            <span className="e-avatar e-avatar-small e-avatar-circle bg--color-green3">JEN</span>
                                                            <span className="e-avatar e-avatar-small e-avatar-circle bg--color-aqua3" style={{ backgroundImage: `url(/assets/images/avatar/tiny/example-barrister.jpg)`}}>&nbsp;</span>
                                                            <span className="e-avatar e-avatar-small e-avatar-circle bg--color-primary-shade">+6</span>
                                                        </div>
                                                    </a>
                                                </span>
                                               
                                            </div>
                                            <div className="project__des">
                                                Appointment notes -  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. 
                                                
                                            
                                            </div>
                                            <div className="project__footer">
                                               
                                                <Button className="float--right primary" inverted size='tiny'>View Details</Button>
                        
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*second card*/}
                                <div className="b-welcome__card stacked ui b-shadow--flat">
                                    <div className="project project--simple">
                                        <div className="project__side-bar bg--color-blue3">
                                            <Icon name="calendar alternate outline" size='large' />
                                        </div> 
                                        <div className="project__contents padding--x2">
                                            <div className="project__header center">
                                                <span className="h3">
                                                    <strong>10:00 - 11:30 Hearing</strong> <br />    
                                                    <Link to=' ' className='link primary underline'><Icon name="map marker alternate" size='small' />Manchester County Court (Deansgate)</Link><br />     
                                                    <Link to=' ' className='link primary underline'>Opus 2 LEX Chambers Management -v- Opus 2 Counsel</Link>
                                                    <a href="#" className="project__header-people-link">
                                                        <div className="e-avatar-stack">
                                                            <span className="e-avatar e-avatar-small e-avatar-circle bg--color-blue3">JEN</span>
                                                            <span className="e-avatar e-avatar-small e-avatar-circle bg--color-green3">JEN</span>
                                                            <span className="e-avatar e-avatar-small e-avatar-circle bg--color-aqua3" style={{ backgroundImage: `url(/assets/images/avatar/tiny/example-barrister.jpg)`}}>&nbsp;</span>
                                                            <span className="e-avatar e-avatar-small e-avatar-circle bg--color-primary-shade">+6</span>
                                                        </div>
                                                    </a>
                                                </span>
                                               
                                            </div>
                                            <div className="project__des">
                                                Appointment notes -  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. 
                                                
                                            
                                            </div>
                                            <div className="project__footer">
                                               
                                                <Button className="float--right primary" inverted size='tiny'>View Details</Button>
                        
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                {/*third card*/}
                                <div className="b-welcome__card stacked ui b-shadow--flat">
                                    <div className="project project--simple">
                                        <div className="project__side-bar bg--color-purple3">
                                            <Icon name="calendar alternate outline" size='large' />
                                        </div> 
                                        <div className="project__contents padding--x2">
                                            
                                        </div>
                                    </div>
                                </div>
                                {/*fourth card*/}
                                <div className="b-welcome__card stacked ui b-shadow--flat">
                                    <div className="project project--simple">
                                        <div className="project__side-bar bg--color-blue3">
                                            <Icon name="calendar alternate outline" size='large' />
                                        </div> 
                                        <div className="project__contents padding--x2">
                                            
                                        </div>
                                    </div>
                                </div>
                                {/*fifth card*/}
                                <div className="b-welcome__card stacked ui b-shadow--flat">
                                    <div className="project project--simple">
                                        <div className="project__side-bar bg--color-green4">
                                            <Icon name="calendar alternate outline" size='large' />
                                        </div> 
                                        <div className="project__contents padding--x2">
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='b-welcome__card-nav'>
                                <Button icon circular secondary disabled>
                                    <Icon name='chevron left' size='large' />
                                </Button>
                                <Button icon circular secondary>
                                    <Icon name='chevron right' size='large' />
                                </Button>
                            </div>
                        </div>
                    </div>

                    {/*
                    <div className="ui grid container">
                        <div className="sixteen wide column">
                            <div className="ui segment b-shadow b-border--none">
                                <h2>Next appointment</h2>
                                <div className="dash__appointments-focus">
                                    <div className="dash__appointments-section__l">
                                        <h3 className="dash__appointments-parties">
                                            Some person <br />
                                            --v-- <br />
                                            Some other person
                                        </h3>
                                        <p className="dash__appointments-title">Eiusmod eu mollit sunt eiusmod officia Lorem laborum adipisicing proident anim sit ex.</p>
                                        <p className="dash__appointments-details"><span>10:30 - 11:00</span><span>Hearing 1 of 5</span><span>Barrister Name</span><span>Lead Advocate</span></p>
                                    </div>
                                    <div className="dash__appointments-section__s">
                                        <div className="dash__appointments-card">
                                            <p><span>0</span>Documents</p>
                                        </div>
                                        <div className="dash__appointments-card">
                                            <p><span>0</span>Messages</p>
                                        </div>
                                        <div className="dash__appointments-card">
                                            <p><span><i className="icon pin"></i></span>Location</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="dash__appointments-more">
                                    <span>Show more +</span>
                                </div>
                            </div>
                        </div>
                        <div className="eight wide computer sixteen wide column mobile">
                            <div className="ui segment b-shadow b-border--none">
                                <h3>Payments</h3>
                            </div>
                        </div>
                        <div className="eight wide computer sixteen wide column mobile">
                            <div className="ui segment b-shadow b-border--none dash__contacts">
                                <h3>Pinned contacts</h3>
                                <p>Contact Name<i className="icon chat"></i><i className="icon phone"></i></p>
                                <p>Contact Name<i className="icon chat"></i><i className="icon phone"></i></p>
                            </div>
                        </div>
                        <div className="eight wide computer sixteen wide column mobile">
                            <div className="ui segment b-shadow b-border--none">
                                <h3>Time recording</h3>
                                <p>Time recorded entries</p>
                            </div>
                        </div>
        </div> */}
                </div>
            </div>
        </div>
        <div className="parallax__section parallax__section--1 flex-center">
            <div className="parallax__layer base">
                <h1>Section 1</h1>
            </div>
            <div className="parallax__layer mid">
                <h1>Copy</h1>
            </div>
        </div>
        <div className="parallax__section parallax__section--2 flex-center">
            <div className="parallax__layer base">
                <h1>Section 2</h1>
            </div>
            <div className="parallax__layer mid">
                <h1>Copy</h1>
            </div>
        </div>
        
        <div className="parallax__section flex-center ">
            <div className="parallax__layer base">
                <h1>Section 3</h1>
            </div>
            <div className="parallax__layer mid">
                <h1>Copy</h1>
            </div>
        </div>
        
        <div className="parallax__section  flex-center outro">
            <h1>Section 4</h1>
        </div>
    </div>
    </div>
    </Fragment>
  )
}

export default Dashboard;