import React, { Fragment, useContext, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../app/stores/rootStore';
import TopMenu from './TopMenu';
import { router } from '../../app/router/Routes';

const TopNavigation: React.FC = () => {
	const rootStore = useContext(RootStoreContext);
	const { logout, loggedInUser } = rootStore.userStore;
  const { setMenuLevel, currentMenuLevel } = rootStore.navigationStore;
	const userName = loggedInUser ? loggedInUser!.User!.UserName : '';
	const firstName = loggedInUser ? loggedInUser!.User!.FirstName : '';
	const lastName = loggedInUser ? loggedInUser!.User!.LastName : '';
	const type: string = 'Over';
	const apiObj = useRef<any | null>();
  // const dockBar = useRef<any | null>();

  const AccountMenuItem = [
      {
          text: `${firstName} ${lastName}`,
          src: '/assets/user.png',
          items: [
              { text: 'My Profile' },
              { text: 'My Settings' },
              { text: 'Sign out' },
          ]
      }
  ];

  const userMenuSelect = (args: any) => {
      if(args.item.text === "Sign out") {
        logout();
      }
      else if(args.item.text === "My Profile") {
        router.navigate('/profile/' + userName);
      }
  }

  const toggleMobileSideBar = () => {
    // dockBar.current.toggle();
    
    let smMenu = document.querySelector('.sm__menu .sm__level');
    let smPusher = document.querySelector('.sm__pusher');
    
    // these are never going to be null so ignore TS warnings (smMenu! & smPusher!)
    if(smMenu!.classList.contains('sm--pushed')){
      setMenuLevel(0);
      smPusher!.classList.remove( 'sm--pushed', `sm__pusher--level${currentMenuLevel}`); 
    }
    else{;
      setMenuLevel(currentMenuLevel + 1);
      smPusher!.classList.add('sm--pushed', `sm__pusher--level${currentMenuLevel}`); 
    }
  }
  // const onCreate = () => {
  //   dockBar.current.hide();
  // }

  return (
	<Fragment> 
		<TopMenu
      // dockBar={dockBar}
      toggleMobileSideBar={toggleMobileSideBar}
			userMenuSelect={userMenuSelect}
			AccountMenuItem={AccountMenuItem}
		/>
  </Fragment>
  );
};

export default observer(TopNavigation);
