import React, { useContext, useEffect, useRef, useState } from 'react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import agent from '../../../app/api/agent';
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-react-popups';

import { Card, Icon, Label, Form, Pagination, Dropdown, Button } from 'semantic-ui-react';
import MarkerCluster from "../../../app/common/map/MarkerCluster";
import { toJS } from 'mobx';
import { getDistance, getAreasOfLaw } from '../../../app/common/functions/commonFunctions';
import { MapContainer, Marker, Popup, TileLayer, ZoomControl} from "react-leaflet";
import { observer } from 'mobx-react-lite';
import { v4 as uuid } from 'uuid';
import { mapMarker } from "../../../app/common/map/MapMarker";
import { router } from '../../../app/router/Routes';

const SolicitorsDashboard = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    getPreviousOfficeSearches,
    setPreviousOfficeSearches,
    getBusinesses,
    setBusinesses,
    setSelectedBusinessOffices,
    setBusinessPeople,
    setSelectedBusinessHq,
    reorderBusinesses,
    setShowMap,
    showMap 
  } = rootStore.businessStore;
  const {
    setInitialScrollPositionsPerPage,
    getScrollPosition,
    setScrollPosOnPage,
    getPreviousActiveChambersPage,
    setPreviousActiveChambersPage
  } = rootStore.commonStore;
  const { setShowFilter } = rootStore.navigationStore;

  const [showResults, setShowResults] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  // const [mapResults, setMapResults] = useState<any[]>([]);
  const [searchByName, setSearchByName] = useState('');
  const [searchByCity, setSearchByCity] = useState('');
  const [searchByAol, setSearchByAol] = useState<any[]>([]);
  const [activePageState, setActivePageState] = useState<number>(getPreviousActiveChambersPage());

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchAolResults, setSearchAolResults] = useState<any[]>([]);
  const [searchBmaopResults, setSearchBmaopResults] = useState<any[]>([]);
  const [clusterMapPosition, setClusterMapPosition] = useState<any[]>([52.5, 0]);
  const [orderValues, setOrderValues] = useState({orderName: "Order by...", orderValue: "REORDERBY"});
  const searchDisabled = useRef(true);
 
  const searchDistance = useRef<any>('1000');
  const businessOffices: any | null = useRef();
  const pastSearches = useRef<any[]>(toJS(getPreviousOfficeSearches()));
  const distanceSelector = useRef<HTMLSelectElement>(null);
  const aolSelector = useRef<HTMLSelectElement>(null);
  const aopSelector = useRef<HTMLSelectElement>(null);
  const activePageRef = useRef<number>(1);
  
  const defaultPosition = [51.5, 0];
  const [panningCoords, setPanningCoords] = useState<number[]>([]);

  const scrollPosRef = useRef(0);
  const indexToUse = useRef(0);

  // const updateMap = async () => {
  //   if (searchResults.length > 0) {
  //     searchResults.map(async (office: any, index) => {   
  //       setMapResults(mapResults => [...mapResults, office]);
  //     });
  //   }
  // };

  const handleScroll = () => {
    const currentScrollY = document.querySelector("#scrollDiv")!.scrollTop;
    scrollPosRef.current = currentScrollY;
    const newPos = {
        page: activePageRef.current,
        scrollPos: scrollPosRef.current,
      }

    setScrollPosOnPage(newPos);
  };

  const changePage = (e: any, data: any) => {
    activePageRef.current = data.activePage;
    setPreviousActiveChambersPage(activePageRef.current);
    setActivePageState(activePageRef.current);
    const scrollPositions = getScrollPosition();
    // console.log('scrollPositions!!!!! ', toJS(scrollPositions))
    document.querySelector("#scrollDiv")!.scrollTo({left: 0, top: scrollPositions[activePageRef.current].scrollPos, behavior: 'smooth'});
  }

  useEffect(() => {
    setActivePageState(getPreviousActiveChambersPage());
    document.querySelector("#scrollDiv")!.addEventListener('scroll', handleScroll, { passive: true });
    // console.log('In businessDashboard useEffect!')

    if (window.location.pathname.includes('navstack') && firstRun) {
      let searchId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
      let prevSearches: any = JSON.parse(localStorage.getItem('navstackSearchParams')!);
      let prevSearchParams = prevSearches.find(({id}: any) => id === searchId);
      
      // if previous search inluded aol usse 1st element of array, if not pass empty string
      let aolStr = prevSearchParams.aol.length > 0 ? prevSearchParams.aol[0] : '';      
      
      getBusinessesSearch(
        true,
        false,
        prevSearchParams.city,
        prevSearchParams.name,
        aolStr,
        prevSearchParams.distance,
        prevSearchParams.businessType
        )
    }
    else if (localStorage.getItem('solicitorsSearchHistory') !== null && firstRun) {
      setSearchResults(JSON.parse(localStorage.getItem('solicitorsSearchHistory')!));
      getBusinessesSearch(true, true);
      setShowResults(true);
    };
    
    // if (searchResults.length > 0) {
    //   updateMap();
    // };
    
    if (firstRun) {
      setFirstRun(false);
      
      let aol: any = getAreasOfLaw();
      aol.then((result: any) => {setSearchAolResults(result)}); // aols are returned from async fn so need to use .then method
    
      navigator.geolocation.getCurrentPosition(geoLocSuccess, geoLocError, geoLocOptions);
      console.log('solicitor clusterMapPosition = ', clusterMapPosition)
    };
    
    businessOffices.current = toJS(getBusinesses());

    businessOffices.current.forEach(
      (element: { distanceFromYou: number; Latitude: any; Longitude: any; City: string, BusinessId: string, BusinessIndex: number }, index: number, arr: any) => {
        element.distanceFromYou = getDistance(element.Latitude, element.Longitude, element.City, clusterMapPosition);
        element.BusinessIndex = 0;
        if (index > 0 ) {
          if (element.BusinessId === arr[index - 1].BusinessId) {
            indexToUse.current = ++indexToUse.current;
            element.BusinessIndex = indexToUse.current;
          } 
          else {
            element.BusinessIndex = 0;
            indexToUse.current = 0; // reset index if more than one businesses returned be the search
          }
        }
      }
    )

    return () => {
      // setPreviousActiveChambersPage(1);
      document.querySelector("#scrollDiv")?.removeEventListener('scroll', handleScroll);
    };
  }, [showResults, searchResults, clusterMapPosition, panningCoords]);
  
  const geoLocOptions = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  
  const geoLocSuccess = (pos: any) => {
    let crd = pos.coords;
    let posArr = [crd.latitude, crd.longitude];

    setClusterMapPosition(posArr);
  }
  
  const geoLocError = (err: any) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    setClusterMapPosition(defaultPosition);
  }

  const getBusinessesSearch = async (usePrev?: boolean, fromPrevLogin?: boolean, city?: string, name?: string, aol?: string, distance?: string, busType?: string) => {
    // reset state in case of previous searches, reset clustermap and show spinner
    createSpinner({target: document.getElementById('spinner')!});
    showSpinner(document.getElementById('spinner')!);
    // setMapResults([]);
    if (!fromPrevLogin) setSearchResults([]);
    localStorage.removeItem('flyToCoords');
    setShowResults(fromPrevLogin!);
    setActivePageState(1);

    let n, c, a, d: string | number | undefined;
    let searchString: any = '';
    let orderBy = orderValues.orderValue !== 'REORDERBY' ? `&orderBy=${orderValues.orderValue}` : '';

    if (usePrev === true && !fromPrevLogin) {
      n = name === '' ? '' : `&businessName=${name}`;
      c = city === '' ? '' : `&city=${city}`;
      // a = aol === '' ? '' : `&workArea=${aol}`;
      a = typeof aol![0] !==  'undefined' ? aol![0] : '';
      d = distance === 'UK wide' ? distance = '1000' : distance;
      searchDistance.current = distance; 
    } else if (fromPrevLogin) {
      d = '1000';
    } else {
      n = searchByName === '' ? '' : `&businessName=${searchByName}`;
      c = searchByCity === '' ? '' : `&city=${searchByCity}`;
      a = searchByAol.length > 0 ? `&workArea=${searchByAol[0]}` : '' ;
    }
    // build the search string
    searchString = `?businessType=2${c}${n}${a}${orderBy}&take=1000`;
 
    if (window.location.pathname.includes('navstack')) {  // THIS CAUSES A BUG IE NEW SEARCH CAN BE PERFORMED FROM THIS PATH - Will be fixed in PBI 646
      let searchId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
      let prevSearches: any = JSON.parse(localStorage.getItem('navstackSearchParams')!);
      let prevSearchParams = prevSearches.find(({id}: any) => id === searchId);

      if (usePrev) {
        n = prevSearchParams.name === '' ? '' : `&businessName=${prevSearchParams.name}`;
      } else n = `&businessName=${searchByName}`;

      c = prevSearchParams.city === '' ? '' : `&city=${prevSearchParams.city}`;
      // a = prevSearchParams.aol === '' ? '' : `&workArea${prevSearchParams.aol}`;
      a = typeof prevSearchParams.aol[0] !==  'undefined' ? `&workArea=${prevSearchParams.aol[0]}` : '';
      d = prevSearchParams.distance;
      
      searchString = `?businessType=${prevSearchParams.businessType}${c}${n}${a}${orderBy}`;
    }

    // fromPrevLogin true if previous search & firstRun i.e. if use just logged in - to preload clustermap
    if (fromPrevLogin) {searchString = localStorage.getItem('solicitorsSearchString')!;} 

    try {
      let businessData = await agent.Businesses.getBusinesses(searchString);

      setBusinesses(searchString).then(() => {
        businessOffices.current = toJS(getBusinesses());
        businessOffices.current.forEach(
          (element: { distanceFromYou: number; Latitude: any; Longitude: any; City: string, BusinessId: string, BusinessIndex: number }, index: number, arr: any) => {
            element.distanceFromYou = getDistance(element.Latitude, element.Longitude, element.City, clusterMapPosition);
            element.BusinessIndex = index;
          }
        );
      }); // wait for Mobx call to be fulfilled then update ref variable

      // save search string localstorage for next login initial search
      localStorage.setItem('solicitorsSearchString', searchString);

      if (businessData.length > 0 ) {
        let businessDataWithDistances = businessData;
        let distanceFromUser: any;
        businessDataWithDistances.forEach((element: any) => {
          distanceFromUser = getDistance(element.Latitude, element.Longitude, element.City, clusterMapPosition);
          element.distanceFromUser = distanceFromUser;
        });
 
        let search = {name: searchByName, city: searchByCity, aol: searchByAol, aolText: searchByAol, distance: searchDistance.current, noOfResults: searchResults.length};
        let prevSearches = toJS(getPreviousOfficeSearches());

        if (!search.distance) searchDistance.current = '1000';

        const filteredByDistance = businessDataWithDistances.filter((el:any) => {
          if (usePrev) {
            return el.distanceFromUser <= search.distance;
          }
          return el.distanceFromUser <= searchDistance.current;
        });

        // prepend item to previousSraSearches ONLY if new search
        if (!usePrev) setPreviousOfficeSearches([{...search}, ...prevSearches]);

        // pastSearches.current = prevSearches;
        pastSearches.current = toJS(getPreviousOfficeSearches());

        if (pastSearches.current.length > 5 && !usePrev) {
          pastSearches.current.splice(-1); // remove last element

          setPreviousOfficeSearches(pastSearches.current);
        }

        // choose between data filtered by distance or not
        let dataToUse = businessData.length === filteredByDistance.length ? businessData : filteredByDistance;

        setSearchResults(dataToUse);
        localStorage.setItem('solicitorsSearchHistory', JSON.stringify(dataToUse));
        businessOffices.current = dataToUse;

        if (localStorage.getItem('navstackSearchParams') === null && !usePrev) {
          // insert a uuid before saving to localStorage
          let searchArray = [{ ...search, id: uuid(), businessType: '2' }];
          localStorage.setItem('navstackSearchParams', JSON.stringify(searchArray));
        } else {
          if (!usePrev) {
            let prevSearches = JSON.parse((localStorage.getItem('navstackSearchParams')!));
            let addedSearch = [{...search, id: uuid(), businessType: '2'}, ...prevSearches];
            localStorage.setItem('navstackSearchParams', JSON.stringify(addedSearch));
          }
        }

        setShowResults(true);
        const noOfScrollPages = Math.ceil(businessData.length / 20)
        const pageInfo: {scrollPos: number; page: number;}[] = [];
        for (let index = 0; index <= noOfScrollPages; index++) {
          pageInfo.push({page: index, scrollPos: 0});
        }
        setInitialScrollPositionsPerPage(pageInfo);
        onActionComplete();
      } else if (businessData.length === 0) {
        setSearchResults(['noResults']);
        setShowResults(true);
        onActionComplete();
      }
    } catch (error) {
      console.log('Error', error);
      setSearchResults(['noResults']);
      hideSpinner(document.getElementById('spinner')!);  
    }
  }

  const updateSearchByNameState = (e: any) => {
    let searchVal = e.target.value;
    setSearchByName(searchVal);
    let disableSearch = isSearchDisabled(searchVal, e.target.name);
    searchDisabled.current = disableSearch;
  }

  const isSearchDisabled = (searchVal: string, target: string) => {

    if (searchVal === '') {
      if (target === 'location' && searchByName === '') {
        return true; //disabled
      } else if (target == 'businessName' && searchByCity === '') {
        return true; //disabled
      } else {
        return false; //enabled
      }
    } 
    return false; //enabled
  }

  const updateSearchByCityState = (e: any) => {
    let searchVal = e.target.value;
    setSearchByCity(searchVal);
    let disableSearch = isSearchDisabled(searchVal, e.target.name);
    searchDisabled.current = disableSearch;
  }

  const updateSearchByAol = (e: any) => {
    let searchVal = e.target.value;
    // what a pain just to get a custom attribute value from a dropdown!!!
    let idx = e.target.selectedIndex;
    let searchKey = e.target.options[idx].dataset.workareaid;
    
    // setSearchByAol(searchKey);
    setSearchByAol([searchKey, searchVal]);
  }

  const updateSearchDistance = (e: any) => {
    let distance = e.target.value;
    searchDistance.current = distance;
  }

  const onActionComplete = () => {
    try {
      hideSpinner(document.getElementById('spinner')!)
      setSearchByName('');
      setSearchByCity('');
      const aop: HTMLInputElement = document.querySelector('[name="areaOfPractice"]')!; // ! tells ts not to worry about possible NULL
      const searchDist: HTMLInputElement = document.querySelector('[name="searchDistance"]')!;
    
      aop.value = ''; // we've cast aop to HTMLInputElement so ts knows it has a value property
      searchDist.value = '1000';
      searchDistance.current = '1000';  
    } catch (error) {
      console.log('In onActionComplete error', error);
    }
    setShowMap(false);
  }

  const removeSearchNavHistory = () => {
    localStorage.setItem('solicitorsSearchHistory', '[]');
    setShowResults(false);
    setSearchByName('');
    setSearchByCity('');
    const aop: HTMLInputElement = document.querySelector('[name="areaOfPractice"]')!; // ! tells ts not to worry about possible NULL
    const searchDist: HTMLInputElement = document.querySelector('[name="searchDistance"]')!;

    aop.value = ''; // we've cast aop to HTMLInputElement so ts knows it has a value property
    searchDist.value = '1000';
    
    searchDistance.current = '1000';
    distanceSelector.current!.selectedIndex = 0;
    // aolSelector.current!.selectedIndex = 0;
    aopSelector.current!.selectedIndex = 0;
  };

  const pageSettings = {
    activePage: getPreviousActiveChambersPage(),
    boundaryRange: 1,
    siblingRange: 0,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
    totalPages: Math.ceil(searchResults.length / 20),
  }

  // const orderOptions = [
  //   { key: "1", text: "Seniority", value: "SENIORITY" },
  //   { key: "2", text: "Alphabetical", value: "ALPHABETICAL" },
  //   { key: "3", text: "City", value: "City" },
  //   { key: "4", text: "Name", value: "BusinessName" },
  //   { key: "5", text: "Price", value: "PRICE" },
  //   { key: "6", text: "Cases", value: "CASES" }
  // ];
  const orderOptions = [
    { key: "3", text: "City", value: "City" },
    { key: "4", text: "Name", value: "BusinessName" }
  ];

  const onOrderChange = (event:any , data:any ) => {
    // deconstruct data object
    const { value } = data;

    let textLabel = data.options.filter((opt: any, index: any) => {
      return opt.value === value;
    })

    // construct object to replsce default
    let selection = {'orderName': textLabel[0].text, 'orderValue': value};

    setOrderValues(selection);
    let orderby = value;
    reorderCurrentSearch(orderby);
  };

  const reorderCurrentSearch = (orderby: any) => {
    if (localStorage.getItem('solicitorsSearchHistory') !== null) {
      let reorder = JSON.parse(localStorage.getItem('solicitorsSearchHistory')!);
      reorder.sort((a: any, b: any) => {
        if (!a.City) a.City = ''; // check for falsey values i.e. null, undefined, "",  
        if (!b.City) b.City = ''; //  false, NaN, 0 and just set value to empty 
        if (!a.Name) a.Name = ''; // string to prevent localeCompare 
        if (!b.Name) b.Name = ''; // throwing an error

        return a[orderby].localeCompare(b[orderby]);
      });

      // we update $ids to coincide with reindexed search results before
      // resaving to localStorage and then resave to mobx businesses
      reorder.map((item: any, index: number) => {
        item.$id = (index + 1).toString();
      });

      localStorage.setItem('solicitorsSearchHistory', JSON.stringify(reorder));
      reorderBusinesses(reorder);
    
      setSearchResults(JSON.parse(localStorage.getItem('solicitorsSearchHistory')!));
    }
  };

  const loadSelectedBusinessProfile  = async (id: string, ind: string) => {
    createSpinner({target: document.getElementById('spinner')!});
    showSpinner(document.getElementById('spinner')!);
    await setSelectedBusinessOffices(id);
    await setBusinessPeople(id);
    await setSelectedBusinessHq(id);
    onActionComplete();
    router.navigate('/business/' + id + '/' + ind);
  };
  const flyToOffice  = async (coordsStr: string) => { 
    let coordsStrArr = coordsStr.split(',');
    let coordsNumArr = coordsStrArr.map(Number);

    // panningCoords.current = coordsNumArr; 
    setPanningCoords(coordsNumArr);
    localStorage.setItem('flyToCoords', JSON.stringify(coordsNumArr)) 
  };

  const handleKeypressSearch = (e:any) => {
    
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      // if (e.key === 'Enter') {
      //   getBusinessesSearch(false, false);
      // }
    }
  }

  return (
    
    <div className='b-container__scroller' id='scrollDiv'>
    <div className="b-section__wrap b-search-page">

      <div className={ 'b-map ' + (showMap ?  'open' : '')}>
        <div className="b-map__switch"> 
          <Button className="icon" title="Map View"  onClick={() => { setShowMap(!showMap); }}><Icon name='map marker alternate' /></Button>
        </div>
        <div className="b-map__switch b-map__switch--list"> 
          <Button className="icon" title="List View"  onClick={() => { setShowMap(!showMap); }}><Icon name='list' /></Button>
        </div>
        <div className="b-map__image">
            {/* @ts-ignore */}

          {/*
            pastSearches.current.map((srch, index) => {
              if (pastSearches.current.length > 0 && index === 0) {
                return <>
                         <div className="b-result-previous__end b-shadow"></div>
                       </>
              }
            })*/
          }
  
          {/* @ts-ignore */}
          <MapContainer center={clusterMapPosition} zoom={13} zoomControl={false} maxZoom={17} scrollWheelZoom={false}>
            <TileLayer
              url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <ZoomControl position="bottomright"/>
            <MarkerCluster markers={JSON.parse(localStorage.getItem('solicitorsSearchHistory')!)} /> 
          </MapContainer>

        </div>
      </div>

      <div className={ 'b-section ' + (showMap ?  'b-left-panel' : 'center')}>
      <div className="ui grid padded b-searchbar">
        <div className="sixteen wide column ">
            <div className='ui segment b-shadow b-border--radius-12'>
            <Form autoComplete="off">
                <Form.Group widths='equal'>
                  <Form.Field>
                    <input
                     name="businessName"
                      className="field"
                      type="text"
                      placeholder={`Enter Solitor Name`}
                      value={searchByName}
                      onChange={updateSearchByNameState}
                      onKeyPress= {(e) => { handleKeypressSearch(e); } } />
                  </Form.Field>
                  <Form.Field>
                      <input 
                        onChange={updateSearchByCityState} 
                        value={searchByCity} 
                        type="text" 
                        name="location" 
                        placeholder="City"
                        onKeyPress= {(e) => { handleKeypressSearch(e); } } 
                       />
                  </Form.Field>
                  <Form.Field>
                    <select defaultValue='' name="areaOfLaw" ref={aolSelector} onChange={updateSearchByAol}>
                        <option value=''>Select Area of Law</option>
                        {searchAolResults.map((area) => {
                          return <option key={area.SRA_WorkAreaId} data-workareaid={area.SRA_WorkAreaId} value={area.WorkArea}>{area.WorkArea}</option>
                        })}
                    </select>
                  </Form.Field>
                  <Form.Field>
                    <select defaultValue='' name="searchDistance" ref={distanceSelector} onChange={updateSearchDistance}>
                      <option value='1000' selected>Search Distance</option>
                      <option key='5' value='5'>5 miles</option>
                      <option key='10' value='10'>10 miles</option>
                      <option key='25' value='25'>25 miles</option>
                      <option key='50' value='50'>50 miles</option>
                      <option key='75' value='75'>75 miles</option>
                      <option key='100' value='100'>100 miles</option>
                      <option key='1000' value='1000'>Whole of UK</option>
                    </select>
                  </Form.Field>
                  <button className="primary ui button" title="Advanced filters" onClick={(e) => {setShowFilter(true);e.preventDefault();return false; }}><Icon name="sliders horizontal" /><span className="ui button__text hidden">Advanced Filter</span></button>
                  <button disabled={searchDisabled.current} className="secondary ui button" type="submit" onClick={() => getBusinessesSearch(false, false)} ><Icon name="search" /><span className="ui button__text hidden">Search</span></button>
                </Form.Group>
              </Form>
          </div>
        </div>
      </div>
      <div className="ui stackable three column grid b-result__grid b-grid--3">
        <div className="filter">
          <div className="filter__col">
            <Dropdown
              text={ orderValues.orderName }
              icon='sort amount down'
              floating
              labeled
              button
              className='icon'
              size="small"
              onChange= { onOrderChange }
              options= { orderOptions }
            >
            </Dropdown>
            {showResults ?  (
              <Label as='a' title="Clear filter" onClick={removeSearchNavHistory}s> 
                  Clear Results   
                <Icon name='delete' />
              </Label>
            ): ("")
            }
          </div>
          <div className="filter__col"> 
            <Button.Group className="small">
              <Button icon active= { !showMap } onClick={() => { setShowMap(!showMap); }}>
                <Icon name='list' />
              </Button>
              <Button icon active= { showMap } onClick={() => { setShowMap(!showMap); }}>
                <Icon name='map marker alternate' />
              </Button>
            </Button.Group>
            {showResults ?  ( 
              <Label className="bg--none">
              <Icon name='search' />
              {`${searchResults[0] === 'noResults' ? '0' : searchResults.length} found`}
              </Label>
            ) : ("")}
            {/*
            <Label className="bg--none">
              <Icon name='map marker alternate' size='large' /><Checkbox className='small' toggle  />
            </Label>
            */}
          </div>
        </div>
      </div>
      {showResults ? (
        <>
          <div className="ui stackable three column grid b-result__grid b-grid--3">
            {searchResults.map((office: any, index) => {
              let lat: number = 51.5;
              let lon: number = 0;

              lat = office.Latitude;
              lon = office.Longitude;

              let pageMin = getPreviousActiveChambersPage() === 1 ? 0 : ((getPreviousActiveChambersPage() - 1) * 10) * 2;
              let pageMax = pageMin + 19;
              let nameToUse = '';

              if (index >= pageMin && index <= pageMax) {
              return (
                <>
                  {(office.hasOwnProperty("AddressLine1") && office.distanceFromUser <= searchDistance.current) ? (
                    <div className='column' key={index}>
                    <div className='ui fluid card b-result office b-shadow' key={office.AddressLine1}>
                      <Card.Content>
                        <Button 
                          className={ 'b-result__content-button ' + (showMap ?  '' : 'disabled')} 
                           value={
                            (Array.isArray(businessOffices.current) && businessOffices.current.length > 0 ? lat : '51.5') + ',' +
                            (Array.isArray(businessOffices.current) && businessOffices.current.length > 0 ? lon : '0') }
                            onClick={(e: any) => flyToOffice((e.target as HTMLInputElement).value)}
                            title="View in map"
                          >
                        </Button>
                        <div className="b-result__content">
                          <Card.Header>{nameToUse = window.location.pathname === '/businesses' ? office.BusinessName : office.BusinessName} 
                            { (office.HeadOffice && office.NumberOfOffices > 1) && <span> (HQ)</span>} 
                             
                          </Card.Header>
                          <Card.Description>
                              {office.AddressLine1}<br />
                              {office.AddressLine2}{office.AddressLine2 && <br />}
                              {office.AddressLine3}{office.AddressLine3 && <br />}
                              {/* {office.address4}{office.address4 && <br />} */}
                              {office.City}{office.City && <br />}
                              {office.Postcode}{office.Postcode && <br />}
                              {/* {office.officeType}{office.officeType && <br />} */}
                              <div className="b-result__type">
                                <div className=
                                  {'ui mini circular label ' + 
                                    (office.BusinessTypeCode === 0 ? 'primary' : '') + 
                                    (office.BusinessTypeCode === 1 ? 'secondary' : '') + 
                                    (office.BusinessTypeCode === 2 ? 'tertiary' : '')} >{office.BusinessTypeCodeName}</div>
                              </div>
                          </Card.Description>
                        </div>
                        <span className="b-result__avatar">
                          <span className="b-result__avatar-skew">
                            <span className="b-result__avatar-img building"></span>
                            {/*<span className="b-result__avatar-img building" style={{ backgroundImage: `url(${office.imgUrl ? office.imgUrl : ""})` }}></span>*/}
                          </span>
                        </span>
                      </Card.Content>

                      <Card.Content extra>
                        <Button 
                          className="float-right primary"
                          size='small'
                          value={index}
                          onClick={(e: any) => loadSelectedBusinessProfile(office.BusinessId, (e.target as HTMLInputElement).value)}
                        >View Profile
                        </Button>
                        {office.WebsiteUrl && <Button className='icon' size='small' circular title={ office.WebsiteUrl } onClick={() => window.open(`https://${office.website}`)}><Icon name='world' /></Button>}
                        {office.Telephone && <Button className='icon' size='small' circular title={ office.Telephone } ><Icon name='phone' /></Button> }
                        {office.EmailAddress && <Button className='icon' size='small' circular title={ office.EmailAddress }><Icon name='envelope' /></Button> }
                      </Card.Content>
                      <Card.Content extra className='b-result__map zero-padding' style={{ zIndex: 1 }}>
                       
                        {showResults && lat !== null ? (
                        // {/* {showResults ? ( */}
                          <>                          
                          <Label className='b-result__dist secondary' size="tiny"  ><Icon name='map marker alternate' />{`${office.distanceFromUser} mile(s) away`}</Label>
                          <MapContainer center={[lat, lon]} zoom={15} scrollWheelZoom={false} wheelPxPerZoomLevel={50} zoomControl={false}>
                            <TileLayer
                              attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            <Marker key={`marker-${lat}${lon}`} position={[lat, lon]} icon={mapMarker} >                                                       
                              <Popup position={[lat, lon]} offset={[-3, 150]}>
                                <span>{office.BusinessName}</span>
                              </Popup>                          
                            </Marker>
                            <ZoomControl position="bottomright" /* size="small" */ />
                          </MapContainer>
                          </>
                        ) : (
                          <div className='b-result__map-error'>
                            <Icon name='map marker alternate' size='big' />
                            <p>Sorry no map information is available</p>
                        </div>
                        )}
                      </Card.Content>
                    </div>
                  </div>   
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </>
              );}
            })}
          </div>
        </>
      ) : (
        <>
          { pastSearches.current.length > 0 ? (
            <>
              <div className="b-result-previous__header">
                <h4><Icon name='clock outline' />Recent</h4>
              </div>
            </>):(
              <span></span>
            )
          }
          {
            pastSearches.current.map((srch, index) => (
              <div className="b-result-previous__item">
                <a onClick={() => {getBusinessesSearch(true, false, srch.city, srch.name, typeof srch.aolText[0] !==  'undefined' ? srch.aolText[0] : '', srch.distance);}}><Icon name="search" size='small' />  
                {srch.name} {srch.name && <span className='b-result-previous__add'>+</span> }
                {srch.city} {srch.city && <span className='b-result-previous__add'>+</span> }
                {typeof srch.aolText[1] !== 'undefined' ? srch.aolText[1] : ''} {srch.aol && <span className='b-result-previous__add'>+</span> }
                {srch.distance === 1000 ? 'UK wide' : 'within ' + srch.distance + ' miles'}
                </a>
              </div>         
            ))
          } 
          
        </>        
      )}
      <div id="spinner" />
      </div>
      {showResults && (
        (pageSettings.totalPages > 1) && (
          <div className="b-pagination">
            <div className="b-pagination-controls" style={{width: 'fit-content'}}>
              <Pagination
                activePage={getPreviousActiveChambersPage()}
                siblingRange={pageSettings.siblingRange}
                ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                firstItem={null}
                lastItem={null}
                prevItem={{ content: <Icon name='angle left' />, icon: true, className: 'b-pagination-controls__back' }} 
                nextItem={{ content: <Icon name='angle right' />, icon: true, className: 'b-pagination-controls__forward' }}
                totalPages={pageSettings.totalPages}
                onPageChange={changePage}
              />
            </div>
          </div>
        )
      )}
    </div>
    </div>
  );
}

export default observer(SolicitorsDashboard);