import React, { useContext, useEffect, useState } from 'react';

import { Button, Checkbox, Icon } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import Recaptcha from 'react-recaptcha';
import SubmitRegistration from '../user/SubmitRegistation';
import LoginResponse from '../user/LoginResponse';
import TermsAndConditions from '../user/TermsAndConditions';
import PrivacyPolicy from '../user/PrivacyPolicy';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
import MyPasswordInput from '../../app/common/form/MyPasswordInput';
import { observer } from 'mobx-react-lite';
import EmailPasswordReset from './EmailPasswordReset';
import { toast } from 'react-toastify';
import { router } from '../../app/router/Routes';

export default observer (function LoginForm() {
  const rootStore = useContext(RootStoreContext);
  const { login, register, getAppToken, openUnconfirmedEmailModal, setOpenUnconfirmedEmailModal } = rootStore.userStore;
	const{ openModal } = rootStore.modalStore;
  
  const [isHuman, setIsHuman]  = useState<Boolean>(false);
  const [showRecaptchaCheckbox, setShowRecaptchaCheckbox]  = useState<Boolean>(false);
  const [hideReg, setHideReg] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [userRegistered, setUserRegistered] = useState<boolean>(false);
  const [shakeForm, setShakeForm] = useState<Boolean>(false);
  const [loginSubmitted, setLoginSubmitted] = useState<boolean>(false);
  


  const RequiredForRegistration = (requiredText: any) => ({
    is: false,
    then: Yup.string().required(requiredText)
  });

  const RequiredAndNumericForRegistration = (requiredText: any) => ({
    is: false,
    then: Yup.string().matches(/^\d+$/, 'Only numeric values please').required(requiredText)
  });

  const validationSchema = {
    FirstName: Yup.string().when(
      "isRegistering",
      RequiredForRegistration("First Name is required")
    ),
    LastName: Yup.string().when(
      "isRegistering",
      RequiredForRegistration("Last Name is required")
    ),
    MobileNumber: Yup.string().when(
      "isRegistering",
      RequiredAndNumericForRegistration("Your mobile number is required for 2FA security")
    ),
    Email: Yup.string().required('Email is required'),
    Password: Yup.string().required('Password is required').matches(
      /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Your password must contain at least 8 characters, one uppercase, one number and one special case character"
    ),
  };

  const timer = (finish: number, count: string) => {
    let x = setInterval(function() {
      let now = Math.floor(Date.now() / 1000);
     
      // Find the difference between now and the count down delay
      let timeRemaining = finish - now;
      let nthFail: string = '';
      
      if (count === '1') nthFail = '1st';
      if (count === '2') nthFail = '2nd';
      if (count === '3') nthFail = '3rd';

      // To Do - swap this vanilla js for React refs
      document.getElementById("countdown")!.innerHTML = `${nthFail} login attempt failed, try again in ${timeRemaining} seconds`;
      document.getElementsByName('Email')[0]!.setAttribute("disabled","disabled");
      document.getElementsByName('Password')[0]!.setAttribute("disabled","disabled");
 
      // If the countdown is over, clean up & show Recaptcha if 3rd attempt failed
      if (timeRemaining === 0) {
        clearInterval(x);
        document.getElementById("countdown")!.innerHTML = "";
        document.getElementsByName('Email')[0]!.removeAttribute("disabled");
        document.getElementsByName('Password')[0]!.removeAttribute("disabled");
        if (count === '3') showRecaptcha();
      }
    }, 1000);  
  }

  const lockUnlockAccount = () => {
    const countdownDivExists = !!document.getElementById("countdown");
    const isLocked = !!window.localStorage.getItem('accountLockTime');
    const accountLockTimeStr = window.localStorage.getItem('accountLockTime');
    const now = Date.now();

    // if account locked but locktime expired, re-enable login inputs
    if ( isLocked && (now > parseInt(accountLockTimeStr!)) ) {
      window.localStorage.removeItem('accountLockTime');
      window.localStorage.removeItem('loginFailTime');
      window.localStorage.removeItem('loginFailCount');
    } else if (countdownDivExists) {
      // To Do - swap this vanilla js for React refs
      document.getElementById("countdown")!.innerHTML = 'This account will be locked for 1hr due to multiple failed login attempts.';
      document.getElementsByName('Email')[0]!.setAttribute("disabled","disabled");
      document.getElementsByName('Password')[0]!.setAttribute("disabled","disabled");
      
      // 300000 = 5mins
      const accLockTime = Date.now() + 30000;
      window.localStorage.setItem('accountLockTime',  accLockTime.toString()); 
    }
  }
  
  const loginFailure = () => {
    let count = window.localStorage.getItem('loginFailCount');
  
    const lastFailTimeStr = window.localStorage.getItem('loginFailTime');
    const lastFailTimeInt = Math.floor(parseInt(lastFailTimeStr!) / 1000);

    let delay: number;
    if (parseInt(count!) === 1) {
      delay = 6;
    } else if (parseInt(count!) === 2) {
      delay = 11;
    } else if (parseInt(count!) === 3) {
      delay = 16;
    } else {
      delay = 0;
    }

    let nowPlusDelay = Math.floor(Date.now() / 1000) + delay;
    let finish = nowPlusDelay;

    switch (count) {
      case '1':
        if ((lastFailTimeInt + delay) > Math.floor(Date.now() / 1000)) timer(finish, count);
        break;
      case '2':
        if ((lastFailTimeInt + delay) > Math.floor(Date.now() / 1000)) timer(finish, count);
        break; 
      case '3':
        if ((lastFailTimeInt + delay) > Math.floor(Date.now() / 1000)) timer(finish, count);
        break;  
      case '4':
        lockUnlockAccount();
        break;  
      default:
        break;
    }
    // This return string prevents typings error when calling this function
    return '';
  }

  // values is either of type IUser(login) or IUserFormValues(register)
  const handleFormSubmit = (values: any, errors: any) => {
    console.log('In handleFormSubmit....');
    setShakeForm(false);
    // values.Email = values.Email;
      if (!hideReg) { // user signing up
        try {
          setLoginSubmitted(true);
          values.ConfirmPassword = values.Password; // ConfirmPassword req'd by API but not UI

          register(values).catch((error) => errors({error: 'Registration failed please contact Opus 2 support'}))
          .then(() => openModal(<SubmitRegistration />, 'small'));

        } catch(error) {
          var regError = error;
          console.log('Registration error occured!');
          setShakeForm(true);
          setLoginSubmitted(false);
          if ("vibrate" in navigator) {
            navigator.vibrate(1000);
          }
        } finally {  
          if (regError === undefined) { //no error so all good
            setShakeForm(false);
            setLoginSubmitted(false);
            console.log('Registration good so should go to confirm email');
            setUserRegistered(true);
          } else console.log('In finally', regError)
        }   
      }
      else { // user is logging in
        setLoginSubmitted(true);
        login(values).catch((error) => errors({error: 'Invalid email or password'}, setShakeForm(true), setLoginSubmitted(false)));
      }
   } 

  const showRecaptcha = () => { setShowRecaptchaCheckbox(true)}
  const recaptchaLoaded = () => { console.log('recaptcha Loaded') }

  const verifyHuman = (response: any) => {
    if(response) setIsHuman(true);
  }

  const updateFormState = () => {
    setHideReg(!hideReg);
  }

  useEffect(() => {
    if (userRegistered) {
      router.navigate('/confirmEmailAddress');
    }

    if (openUnconfirmedEmailModal === true) {
      setTimeout(() => {
        openModal(<LoginResponse />, 'tiny');
        setOpenUnconfirmedEmailModal(false);
      }, 1000);
    }

    getAppToken();
  }, [userRegistered, openUnconfirmedEmailModal]);

  const today = new Date();

  const toggleRegSubmitBtn = () => {
    setTermsAccepted(!termsAccepted);
  }
  const MicrosoftSignOn = (e:React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toast.error("Microsoft 365 Redirect Error");
  }
  const clickHandlerSubmit = (e:any) => {
    
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      // handle form submit on formik form
    }
  }


  return (
    <>
    <div className='b-container__scroller'>
    <div className="login__container">
    <div className="login__bg">
        <div className="login__bg-sec1"></div>
        <div className="login__bg-sec2"></div>
        <div className="login__bg-sec3"></div>
      </div>
    <div className="login__wrap login__wrap--open">
      
      <div className="login__center">
        <div className="login login--wide">
          <div className="login__logo-wrap">
            <div className="logo__logo-center">
              <div className="login__logo-owner owner-logo"></div>
              <div className="login__logo-reveal">
                  <div className="login__logo app-logo"></div>
              </div>
            </div>
          </div>
          <div className={'login__form-wrap ' + (shakeForm ? 'shake' : '')}>
            <div className="login__form">
              <Formik
                enableReinitialize={true}
                validationSchema={Yup.object().shape(validationSchema)}
                initialValues={{
                  Email: '',
                  Password: '',
                  FirstName: '',
                  LastName: '',
                  MobileNumber: '',
                  ReturnUrl:'https://lexnetwork.opus2.com',
                  isRegistering: hideReg,
                  error: null
                }}
                onSubmit={(values, {setErrors} ) => handleFormSubmit(values, setErrors)} >
                {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
                  <Form
                    onSubmit={handleSubmit}
                    className="ui large form"
                    autoComplete="off"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                  >
                    <div className="ui stacked segment">
                      <div className="one field">
                        <div className="ui left icon input">
                          <i className="envelope icon"></i> 
                          <MyTextInput tabIndex={1} name='Email' placeholder='Email' type='email' autoComplete="on"/>
                        </div>
                      </div>
                      <div className="field">
                        <div className="ui left icon input">
                          <i className="lock icon"></i>
                          <MyPasswordInput tabIndex={2} name='Password' placeholder='Password' KeyDownHandler={clickHandlerSubmit} />
                          
                        </div>
                        <a href="/" className={hideReg ? 'field__text-link right' : 'field__text-link hidden'}  tabIndex={-1} 
                          onClick={(e) => { openModal(<EmailPasswordReset />, 'tiny');e.stopPropagation();e.preventDefault();return false; }}>
                          FORGOTTEN PASSWORD
                        </a>
                      </div>
                      <div className={hideReg ? 'login__reg hidden' : 'login__reg'}>
                        <div className="field">
                          <div className="ui left icon input">
                            <i className="user circle icon"></i>
                            <MyTextInput tabIndex={hideReg ? -1 : 3} name='FirstName' placeholder='First Name'  type='text' autoComplete="off"/>
                          </div>
                        </div>

                        <div className="field">
                          <div className="ui left icon input">
                            <i className="user circle icon"></i>
                            <MyTextInput tabIndex={hideReg ? -1 : 4} name='LastName' placeholder='Last Name'  type='text' autoComplete="off"/>
                          </div>
                        </div>

                        <div className="field ">
                          <div className="ui left icon input">
                            <i className="mobile icon"></i>
                            <MyTextInput tabIndex={hideReg ? -1 : 5} name='MobileNumber' placeholder='Mobile Number'  type='tel' autoComplete="off"/>
                          </div>                         
                        </div>

                        <div className="field">
                          <Checkbox name="privacy" tabIndex={hideReg ? -1 : 6} onClick={() => toggleRegSubmitBtn()}
                            label={
                              <label className="small">
                                I agree to the&nbsp;<a href="/" tabIndex={hideReg ? -1 : 7} onClick={(e) => {openModal(<TermsAndConditions />, 'small');e.stopPropagation();e.preventDefault();return false;}}>Terms &amp; Conditions</a>
                                &nbsp;and&nbsp;
                                <a href="/" tabIndex={hideReg ? -1 : 8} onClick={(e) => {openModal(<PrivacyPolicy />, 'small');e.stopPropagation();e.preventDefault();return false;}}>Privacy Policy</a>
                              </label>
                            }
                          />
                        </div>
                      </div>
                      <div className="field" hidden>
                        <input  name="ReturnUrl" defaultValue='https://lexnetwork.opus2.com' />
                      </div>
                      <div className="field" hidden>
                        <Checkbox  name="isRegistering" /*tabIndex={9}*/ checked={hideReg}/>
                      </div>
                      {errors.error || window.localStorage.getItem('loginFailTime') ? (
                        <>
                          {/*<ErrorMessage
                            name='error'
                            render={() =>
                            <Label basic color='red' content={errors.error}/> }      
                          />*/}
                          <div id='countdown'>
                            {loginFailure()}
                          </div>
                            </>
                      ) : (<></>)}

                      {(showRecaptchaCheckbox) && (
                        <Recaptcha
                          sitekey="6LdWnIQaAAAAAGUzCorW1kVmby4nWn3I6jMCeU8f"
                          render="explicit"
                          onloadCallback={recaptchaLoaded}
                          verifyCallback={verifyHuman}
                          style={{ width: '300px !important' }} />
                      )}
                      <Button
                        loading={loginSubmitted}
                        disabled={(!isValid || !dirty) || (showRecaptchaCheckbox && !isHuman)}
                        content='Login'
                        className={'ui fluid large secondary submit button ' + (!hideReg ? 'hidden' : '')}
                        type='submit'
                        tabIndex={hideReg ? 3 : -1}
                      />
                      <Button
                        loading={loginSubmitted}
                        disabled={!termsAccepted}
                        content='Register Account'
                        className={'ui fluid large secondary submit button ' + (hideReg ? 'hidden' : '')}
                        type='submit'
                        tabIndex={!hideReg ? 9 : -1}
                      />
                      { process.env.REACT_APP_CAN_SIGN_UP!.trim() === 'true' ? (
                        <>
                          <Button
                            basic
                            content={hideReg ? "Become a member - it's FREE" : "Cancel"}
                            className="ui fluid large secondary button" onClick={() => updateFormState()}
                            type='reset'
                            tabIndex={1002}
                          />
                          <Button
                            className={'ui fluid small secondary button ' + (!hideReg ? 'hidden' : '')}
                            type='submit'
                            tabIndex={1001}
                            onClick={(e:React.MouseEvent) => MicrosoftSignOn(e)} 
                          ><Icon name='microsoft' />Microsoft 365 Sign On</Button>
                        </>
                      ): (<></>)}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

          </div>
          <div className="login__terms desktop--hide">
            <div className="login__terms-logo owner-logo"></div>
            <button className="link" onClick={(e) => {openModal(<TermsAndConditions />, 'small');e.preventDefault();return false;}}>Terms &amp; Conditions</button>
            {" "}<span className="spacer">|</span>{" "}
            <button className="link" onClick={(e) => {openModal(<PrivacyPolicy />, 'small');e.preventDefault();return false;}}>Privacy Policy</button><br />
            <a href="https://www.opus2.com/" className="link">&copy; {today.getFullYear()} Opus 2 Ltd</a>
          </div>
        </div>
      </div>
    </div>
    <div className="footer">
      <div className="footer__col left">
        <div className="footer__link">
          <div className="footer__menu">
            <div className="footer__menu-row">
              <span className="footer__menu-link--disabled">
                <span className="icos icos--small icos-palette"></span>
                v10.005.012
              </span>
            </div>
            <div className="footer__menu-row">
              <span className="footer__menu-link--disabled">
                <span className="icos icos--small icos-history"></span>21 Feb
                12: 41: 27
              </span>
            </div>
          </div>
          <span className="footer__link-text">Opus 2 LEX Network v1.01</span>
        </div>

        <div className="footer__separator">|</div>
        <div className="footer__link">
          <div className="footer__menu">
            <div className="footer__menu-row">
              <a href="tel:+441162725015" tabIndex={10} className="footer__menu-link">
                <span className="icos icos--small icos-phone"></span>0116 272 5015
              </a>
            </div>
            <div className="footer__menu-row">
              <a
                href="mailto:support@lexnetwork.co.uk"
                tabIndex={11}
                className="footer__menu-link">
                <span className="icos icos--xsmall icos-email"></span>
                support@lexnetwork.co.uk
              </a>
            </div>
          </div>
          <span className="icos icos--xxsmall icos-help"></span>
          <span className="footer__link-text">Support</span>
        </div>
      </div>
      <div className="footer__col right">
        <a href="https://www.opus2.com" tabIndex={14} className="footer__link">&copy; {today.getFullYear()} Opus 2</a>
        <div className="footer__separator">|</div>
        <button className="footer__link" tabIndex={13} onClick={(e) => { openModal( <PrivacyPolicy />, 'small');e.preventDefault();return false;}}>Privacy Policy</button>
        <div className="footer__separator">|</div>
        <button className="footer__link" tabIndex={12} onClick={(e) => { openModal( <TermsAndConditions />, 'small');e.preventDefault();return false;}}>Terms &amp; Conditions</button>
      </div>
    </div>
    </div>
    </div>
  </>
  );
})
