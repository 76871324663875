import React, { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { RootStoreContext } from '../stores/rootStore';

interface Props{
  children: any
}

const ProtectedRoute = ({ children }: Props) => { 
  const rootStore = useContext(RootStoreContext);
	const { isLoggedIn } = rootStore.userStore;

  if ( !isLoggedIn ) {
    return <Navigate to='/' />
  } 
  return children;
};

export default ProtectedRoute;