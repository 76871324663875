import React from 'react';

import { Card, Icon, Button } from 'semantic-ui-react';

interface IProps {
  slideNext: (e: any) => void;
}

const Location: React.FC<IProps> = ({ slideNext }) => {

  return (
    <div className="b-slidedeck__content text-align--left">

    <div className="b-slidedeck__title"><h3>Please confirm your office location:</h3></div>

    <Card className="left fluid b-shadow b-border--none address">
      
      <Card.Content textAlign="left">
        <Card.Description className="small">
          <div className="b-address">
              <div className="b-address__map google__map">
                <div className="b-address__map-inner"> 
                  <iframe className="b-address__map-iframe"  src="https://maps.google.com/maps?q=%206%20Wemyss%20Place,%20Edinburgh%20EH3%206DH&t=&z=13&ie=UTF8&iwloc=&output=embed&disableDefaultUI=true"  frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}></iframe>
                </div>
              </div>
              <div className="b-address__content">
                  <div className="b-address__title">EDINBURGH</div>
                  <div className="b-address__info">
                      <div className="b-address__info-column">
                          Bar Squared Ltd<br />
                          6 Wemyss Place<br />
                          Edinburgh<br />
                          EH3 6DH
                      </div>
                      <div className="b-address__info-column">
                        <a href="#" className="b-address__link placeholder"><Icon name="box" size="small" />Add DX address</a>
                        <a href="tel:+441316291424" className="b-address__link"><Icon name="phone" size="small" />+44 (0)13 1629 1424</a>
                        <a href="fax:+441316291424" className="b-address__link"><Icon name="fax" size="small" />+44 (0)13 1629 4444</a>
                        <a href="#" className="b-address__link placeholder"><Icon name="envelope" size="small" />Add location email address</a>
                      </div>                                    
                  </div>
              </div>
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra className="text-align--right">
        <Button primary data-navigate='selectOffice' onClick={(e: any) => slideNext(e)} >Select Office</Button>
      </Card.Content>
    </Card>
    <Card className="left fluid b-shadow b-border--none address">
      
      <Card.Content textAlign="left">
        <Card.Description className="small">
          <div className="b-address">
              <div className="b-address__map  google__map">
                <div className="b-address__map-inner"> 
                  <iframe className="b-address__map-iframe"  src="https://maps.google.com/maps?q=Bar%20Squared%20Ltd%206%20Warren%20Park%20Way%20Enderby%20Leicestershire%20LE19%204SA&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}></iframe>
                </div>
              </div>
              <div className="b-address__content">
                  <div className="b-address__title">LEICESTER</div>
                  <div className="b-address__info">
                      <div className="b-address__info-column">
                          Bar Squared Ltd<br />
                          6 Warren Park Way<br />
                          Enderby<br />
                          Leicestershire<br />
                          LE19 4SA
                      </div>
                      <div className="b-address__info-column">
                  <a href="#" className="b-address__link placeholder"><Icon name="box" size="small" />Add DX address</a>
                  <a href="tel:+441316291424" className="b-address__link"><Icon name="phone" size="small" />+44 (0)116 272 5000</a>
                  <a href="fax:+441316291424" className="b-address__link"><Icon name="fax" size="small" />+44 (0)116 272 5055</a>
                  <a href="#" className="b-address__link placeholder"><Icon name="envelope" size="small" />Add location email address</a>
                      </div>                                    
                  </div>
              </div>
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra className="text-align--right">
        <Button primary data-navigate='selectOffice' onClick={(e: any) => slideNext(e)} >Select Office</Button>
      </Card.Content>
    </Card>
    <Card className="left fluid b-shadow b-border--none address">
      
      <Card.Content textAlign="left">
        <Card.Description className="small">
          <div className="b-address">
              <div className="b-address__map  google__map">
                <div className="b-address__map-inner"> 
                  <iframe className="b-address__map-iframe"  src="https://maps.google.com/maps?q=5%20New%20Street%20Square,%20London,%20%20EC4A%203BF&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0}></iframe>
                </div>
              </div>
              <div className="b-address__content">
                  <div className="b-address__title">LONDON</div>
                  <div className="b-address__info">
                      <div className="b-address__info-column">
                        Bar Squared Ltd<br />
                        5 New Street Square<br />
                        London<br />
                        EC4A 3BF<br />
                      </div>
                      <div className="b-address__info-column">
                        <a href="#" className="b-address__link placeholder"><Icon name="box" size="small" />Add DX address</a>
                        <a href="tel:+442030085900" className="b-address__link"><Icon name="phone" size="small" />+44 (0)20 3008 5900</a>
                        <a href="fax:+441316291424" className="b-address__link"><Icon name="fax" size="small" />+44 (0)20 3008 5955</a>
                        <a href="#" className="b-address__link placeholder"><Icon name="envelope" size="small" />Add location email address</a>
                      </div>                                    
                  </div>
              </div>
          </div>
        </Card.Description>
      </Card.Content>
      <Card.Content extra className="text-align--right">
        <Button primary data-navigate='selectOffice' onClick={(e: any) => slideNext(e)} >Select Office</Button>
      </Card.Content>
    </Card>
    </div>   
  );
};

export default Location;
