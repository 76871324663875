import { MenuComponent } from '@syncfusion/ej2-react-navigations';
import React, { Fragment, useContext } from 'react';
import NavStack from './NavStack';

import { Icon, Button } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { router } from '../../app/router/Routes';

interface IProps {
	userMenuSelect: (args: any) => void;
	AccountMenuItem: object[];
	toggleMobileSideBar: any;
}


const TopNavigation: React.FC<IProps> = ({
  userMenuSelect,
  AccountMenuItem,
	toggleMobileSideBar,
}) => {

const handleBackClick = () =>{
    if (router.navigate.length > 2) {
        // this will take you back but not back to login screen 
        router.navigate(-1);
    }
}
const rootStore = useContext(RootStoreContext);
const { showNavStack, setShowNavStack } = rootStore.navigationStore;



  
  return (
    <Fragment>
      <div
        className="nav"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="nav__icons nav__icons--main">
          <a className="icos-button branding" id="menuTrigger1" onClick={toggleMobileSideBar} href="#">
            <span className="branding-logo"></span>
            <span className="icos hamburger hamburger--elastic">
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </span>
          </a>
          <div className='icon__divider'></div>
          {/*}
          <a id="btn-search" className="icos-button tablet--hide" href="#">
            <i className="icon searchengin"></i>
          </a> */}
          
          <Button className="icon" onClick={(e: { preventDefault: () => void; }) => {e.preventDefault();return false;}}>
            <Icon className="add" />
          </Button>

          <div className='icon__divider hide--desktop'></div>
          <Button className="icon hide--desktop" onClick={(e: { preventDefault: () => void; }) => {setShowNavStack(!showNavStack);e.preventDefault();return false;}}>
            <Icon className="inbox" />
          </Button>
        </div>
        <NavStack />

        <div className="nav__icons nav__icons--page white">
          <a className="icos-button">
            <span className="icos icos-save"></span>
          </a>
          <a className="icos-button">
            <span className="icos icos-bin"></span>
          </a>
        </div>

        <div className="nav__controls nav__controls--mobile-top">
          <div className="nav__mobile-right">
            <Button className='icon'>
              <Icon name="chat" ></Icon>
            </Button>
          </div>
          <div className="nav__mobile-left">
            <Button  onClick={(e: { preventDefault: () => void; }) => { handleBackClick(); e.preventDefault();return false;}} className='icon desktop-hide phablet-hide' >
              <Icon name="angle left"></Icon>
            </Button>
            <MenuComponent
              items={AccountMenuItem}
              select={userMenuSelect}
              cssClass="dock-menu mobile-hide"
            ></MenuComponent>
            
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TopNavigation;