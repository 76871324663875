import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";

import { Icon, Button, Label } from "semantic-ui-react";
import { router } from "../../app/router/Routes";

const NavStack: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { getPreviousOfficeSearches } = rootStore.businessStore;
  const { showNavStack, setShowNavStack } = rootStore.navigationStore;

	const [allPrevSearches, setAllPrevSearches]: any = useState([]);

  useEffect(() => {
    if (localStorage.getItem("navstackSearchParams")) {
      let aps: any = JSON.parse(localStorage.getItem("navstackSearchParams")!);

      setAllPrevSearches(aps);
    }
  }, [showNavStack]);

  const handleBackClick = () => {
    // MUCH OF THIS FUNCTION'S CODE MAY BE REDUNDENT SINCE ROUTER DOM v6 MIGRATION
    if (router.navigate.length > 1) {
      // this will take you back but not back to login screen
      // if (router.navigate.hasOwnProperty('state') && history.location.state !== undefined) {
      if (router.navigate.hasOwnProperty('state') ) {
        console.log('Back button message - NO GOBACK: ', router.navigate)
        // history.goBack();
      } else {
        console.log('Back button message: ', router.navigate)
        router.navigate(-1);
      }
    }
  };

  const previouSearch = (id: any, type: string) => {
    setShowNavStack(!showNavStack);
    if (type === 'Person Search') {
      router.navigate(`people/navstack/${id}`);
    } else if (type === 'Chambers Search') {
      router.navigate(`chambers/navstack/${id}`);
    } else router.navigate(`solicitors/navstack/${id}`);
  }

  const typeOfSearch = {
    '0': 'Person Search',
    '1': 'Chambers Search',
    '2': 'Solicitors Search',
  }

  return (
    <Fragment>
      <div className={"nav__stack " + (showNavStack ? "nav__stack--open" : "")}>
        <span className="nav__stack-divider border--color1 tablet--hide"></span>
        <div className="nav__stack-icons">
          <Button
            className="icon"
            onClick={(e: { preventDefault: () => void; }) => {
              handleBackClick();
              e.preventDefault();
              return false;
            }}
          >
            <Icon className="angle left" />
          </Button>
          <Button
            id="openStack"
            className="icon"
            onClick={(e: { preventDefault: () => void; }) => {
              setShowNavStack(!showNavStack);
              e.preventDefault();
              return false;
            }}
          >
            <Icon className="inbox" />
          </Button>
          <Button
            className="icon"
            onClick={(e: { preventDefault: () => void; }) => {
              router.navigate(+1);
              e.preventDefault();
              return false;
            }}
          >
            <Icon className="angle right" />
          </Button>
        </div>
        <div
          className="stack__shade"
          id="stackShade"
          onClick={() => setShowNavStack(!showNavStack)}
        ></div>
        <div className="stack__wrapper">
          <div className="stack__close-popup">
            <Button
              className="icon"
              id="closeStack"
              aria-label="Close"
              onClick={(e: { preventDefault: () => void; }) => {
                setShowNavStack(!showNavStack);
                e.preventDefault();
                return false;
              }}
            >
              <Icon className="close" />
            </Button>
          </div>
          <div className="stack" id="stack">
            <div className="stack__section-title border--primary">
              <Icon name="folder open" /> Open
            </div>
            {/* <div className="stack__list">

            </div>  */}
            {allPrevSearches && (
              <>
              {/* {console.log('allPrevSearches = ', allPrevSearches)} */}
                <div className="stack__section-title border--primary">
                  <Icon name="clock outline" /> Recent
                </div>
                {allPrevSearches.map((search: { id: any; name: string | ''; city: string | ''; businessType: string | undefined, aol: string[] | undefined; distance: string | undefined }, index: number) => {
                  let searchType = '';
                  if (typeof(search.aol) !== 'undefined') {
                    searchType = search.businessType === '1' ? typeOfSearch['1'] : typeOfSearch['2'];
                  } else {
                    searchType = typeOfSearch['0'];
                  }

                  return (
                    <div className="list" key={index}>
                      <section className="stack__item recent">
                        <div className="stack__item-link">
                          <span className="stack__item-icon">
                            <Icon.Group size="big">
                              { typeof(search.aol) !== 'undefined' ? (
                              <Icon name="building" />
                              ) : (
                                <Icon name="user" />            
                              )}
                              <Icon corner size="tiny" className="search primary" />
                            </Icon.Group>
                          </span> 
                          <a onClick={() => previouSearch(search.id, searchType)}>
                            <span className="stack__item-detail">
                              <span className="stack__item-title">
                                { searchType }
                              </span>
                                
                              {search.name !== '' && (<Label size="mini" >{search.name}</Label>)}
                              {search.city !== '' && (<Label size="mini" >{search.city}</Label>)}
                              { (typeof(search.aol) !== 'undefined' && search.aol.length > 1 ) ? (
                                <Label size="mini" >{search.aol[1]}</Label>
                              ) : (
                                ''
                              )}
                              { typeof(search.distance) !== 'undefined' ? (
                                <Label size="mini" >{search.distance} mile(s)</Label>
                              ) : (
                                ''
                              )}
                            </span>
                          </a>
                        </div>
                      </section>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default observer(NavStack);
