import LatLon from 'mt-latlon';
import agent from '../../api/agent';

export const getDistance = (lat: any, lon: any, town: string, clusterMapPosition: any, busId?: any) => {
  // console.log('in getDistance lat =', lat)
  // console.log('in getDistance lon =', lon)
  // console.log('in getDistance town =', town)
  // console.log('in getDistance clusterMapPosition =', clusterMapPosition)
  // console.log('in getDistance busId =', busId)
  const p1 = new LatLon(clusterMapPosition[0],clusterMapPosition[1]);
  const p2 = new LatLon(lat, lon);
  const noneStraigthtLineCF = 1.18;
  const kmToMiles =  0.621371;
  /* @ts-ignore */
  var dist =  parseInt((p1.distanceTo(p2, 4)) * kmToMiles * noneStraigthtLineCF);
  return dist;
}

export const getAreasOfLaw = async () => {
  try {
    let aol = await agent.Businesses.getSraWorkAreasForBusinesses();
    if (aol) {
      return aol;
    }
    else {
      return console.log('No aol data from API');
    };
  } catch (error) {
    return console.log('We have an error getting AOL: ', error);
  };
}

export const getAreasOfPractice = async () => {
  try {
    let aop = await agent.NetworkGroups.getNetworkGroupsByGroupCode('BarMutualAreaOfPractice');
    if (aop) {
      return aop;
    }
    else {
      return console.log('No aop data from API');
    };
  } catch (error) {
    return console.log('We have an error getting AOP: ', error);
  };
}

export const getPeople = async (searchString: string) => {
  try {
    let people = await agent.People.getPeople(searchString);
    if (people) {
      return people;
    }
    else {
      return console.log('No people data from API');
    };
  } catch (error) {
    return console.log('We have an error getting people: ', error);
  };
}

export const getUserLocation = () => {
  return navigator.geolocation.getCurrentPosition(geoLocSuccess, geoLocError, geoLocOptions);
}

const geoLocSuccess = (pos: any) => {
  let crd = pos.coords;
  let posArr = [crd.latitude, crd.longitude];

}

const geoLocOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

const geoLocError = (err: any) => {
  console.warn(`ERROR(${err.code}): ${err.message}`);
  // return [51.5, 0];
}