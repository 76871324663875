import React, { useContext,useState } from 'react';

import { Button, Icon, Dropdown} from 'semantic-ui-react';
import { router } from '../../../../app/router/Routes';
import { RootStoreContext } from '../../../../app/stores/rootStore';
import lodash from 'lodash';

interface IProps {
  clerks: any;
}

const Clerks: React.FC<IProps> = ({ clerks }) => {

  const rootStore = useContext(RootStoreContext);
  const { setPersonProfile } = rootStore.personStore;
  const [orderValues, setOrderValues] = useState({orderName: "Order by...", orderValue: "REORDERBY"});
  const orderOptions = [
    { key: "2", text: "Alphabetically", value: "Alphabetically" },
    { key: "3", text: "Department", value: "Department" },
    { key: "4", text: "Seniority", value: "Seniority" }
    /*{ key: "5", text: "Year of Call", value: "YearOfCall" }*/
  ];
  const onOrderChange = (event:any , data:any ) => {
  
  };
  
  const loadSelectedPersonProfile = async (id: string) => {
    await setPersonProfile(id);
    router.navigate('/profile/' + id);
  };

  clerks = lodash.orderBy(clerks, ['ClerkPostTitle'], ['asc']);
  return (
    <div className='b-section center'>
       {/*order*/}
       <div className="ui stackable three column grid b-result__grid b-grid--3">
        <div className="filter">
          <div className="filter__col">
            <Dropdown
              text={ orderValues.orderName }
              icon='sort amount down'
              floating
              labeled
              button
              className='icon'
              size="small"
              onChange= { onOrderChange }
              options= { orderOptions }
            >
            </Dropdown>
          </div>
          
        </div>
      </div>
      <div className="ui stackable three column grid b-result__grid b-grid--3">
       { clerks.map((prsn: any, index: number) => {
        return (
          <div className="column" key={index}> 
            <div className="ui fluid card b-result person b-shadow">
              <div className="content">
                <span className="b-result__content">
                  <div className="header">
                    <h4>{`${prsn.FirstName} ${prsn.LastName}`}</h4>
                    <h5>
                      { prsn.DateOfCall && <span className="date">
                        Call {prsn.YearOfCall}
                        {/*{format( new Date(person.DateOfCall), 'YYYY')}*/}
                      </span>
                      }
                      { prsn.YearOfSilk && 
                      <span className="date">
                        &nbsp;&nbsp;|&nbsp;  Silk {prsn.YearOfSilk}
                      </span>
                      }
                    </h5>
                    <div className="b-result__type">
                      <div className=
                        {'ui mini circular label ' + 
                          (prsn.PersonTypeCode === 0 ? 'primary' : '') + (prsn.PersonTypeCode === 1 ? 'secondary' : '') + (prsn.PersonTypeCode === 2 ? 'tertiary' : '')} >
                        {(prsn.ClerkPostTitle) ? (prsn.ClerkPostTitle) : (prsn.PersonTypeCodeName)}
                      </div>
                      {/*<div className="ui mini circular label">Mediation</div>*/}
                    </div>
                  </div>
                  <div className="description">
                    <a /* onClick={() => loadSelectedBusinessProfile(person.BusinessId)}*/ >{prsn.BusinessName}</a> 
                    {/* <Link to={`/business/${person.BusinessId}`}>{person.Business.Name}</Link> */}
                    &nbsp;<i className="map marker small icon"></i>
                    {prsn.City}
                    <br></br>
                  </div>
                </span>
                <span className="b-result__avatar">
                  <span className="b-result__avatar-skew">
                    <span className="b-result__avatar-img"></span>
                  </span>
                </span>
              </div>              
              <div className="extra content">
                    <button className="ui small button float-right primary" onClick={() => loadSelectedPersonProfile(prsn.PersonId)}  >View Profile</button>
                    
                  {prsn.Telephone &&  <Button className='icon' size='small' circular title={ prsn.Telephone } href={'tel:' + prsn.Telephone} ><Icon name='phone' /></Button> }
                {prsn.Email && <Button className='icon' size='small' circular title={ prsn.Email } href={'mailto:' + prsn.Email}><Icon name='envelope' /></Button> }


              </div>
            </div>
        </div>
        )
    })}
    </div>
    </div>
  )
};

export default Clerks;
