import React from 'react';

const PreloaderComponent: React.FC = () => {
  return (
    <div className="preloader__wrap">   
        <div className="ui active transition visible inverted dimmer active-spinner preloader preloader--open">
          <div className="preloader__logo">
              <div className="preloader__logo-center">
                  <div className="preloader__svg">
                    <div className="ui text loader"></div>
                  </div> 
                  <div className="preloader__logo-primary owner-logo"></div>
              </div>
          </div>
        </div>
      </div>
  );
};

export default PreloaderComponent;
