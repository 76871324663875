import React, { Fragment, useContext, useEffect, useState } from 'react';

import { Button, Modal, Header } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';

export default observer (function EmailPasswordResponse() {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;

  const [responseMsg, setResponseMsg] = useState('');

  useEffect(() => {
      let msg: any = localStorage.getItem('passwordResetMsg');
      setResponseMsg(msg);
  });

  return (
    <Fragment >
        <Header content='Password Reset' />
        <Modal.Content>
          <p>{responseMsg}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            onClick={() => closeModal()} 
          />
        </Modal.Actions>
    </Fragment>
    );
})
