import React, { Fragment, useContext, useRef } from 'react';

import { Button, Icon, Label, Image, Grid, Segment, Card} from 'semantic-ui-react';
import useOnScreen from '../../app/common/util/useOnScreen'

interface IProps {

}
const Event = () => {
    
    
    const masthead: any = useRef<HTMLDivElement>();
    const isMastheadInViewport: boolean = useOnScreen<HTMLDivElement>(masthead, "0px", true);

  return (
    
    <div className='b-container__scroller'>

      <div className={'b-sticky-header__wrapper ' + (isMastheadInViewport ? '' : 'isSticky')}>
        <div className="b-sticky-header">
          <div className="b-sticky-header__row">
            <div className="b-sticky-header__col b-sticky-header__col--avatar">
              <span className="e-avatar e-avatar-circle e-avatar-large" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/events/small/exchange-example.jpg)'}}></span>
            </div>
            <div className="b-sticky-header__col">
              <h3>Annual Local Government &amp; Social Housing Seminar</h3>
              <Label size="tiny" circular className="primary">Webinar</Label>
              <div className="meta">
                <span className="card__hero-location"><Icon name="video" /> Via Zoom</span>
              </div>    
            </div>

            <div className="b-sticky-header__col right">
                <div className="card__hero-buttons">
                        <Button size="small" className="primary" href=" " >Register</Button>
                </div>
            </div>
          </div>
        </div>
      </div>



    <div className="profile-page">
      <div className='profile-page__masthead' ref={ masthead }>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column className="b-profile-header">
            <Segment className="b-profile-hero container">
            <Card className="left fluid b-border--none event">
              <Card.Content textAlign="left">
                <div className="card__hero">
                  <div className="card__hero-col">
                    <Card.Header>
                      <h2>Annual Local Government &amp; Social Housing Seminar</h2>
                    </Card.Header>
                    <Card.Meta>
                    <span className="card__hero-location">
                      <h4>Thu 24 November | 15:00 until 18:30</h4>
                      <Icon name="video" /> Via Zoom                       
                    </span>
                    <span className="card__hero-user">
                      <Label as='a' image size="small">
                        <Image src={ process.env.PUBLIC_URL + '/assets/images/avatar/tiny/exchangechambers.png'} />
                        Exchange Chambers 
                      </Label>                      
                    </span>  
                    <div className="card__hero-buttons">
                        <Button size="big" className="primary" href="#">Register</Button>
                    </div>
                    </Card.Meta>
                  </div>
                  <div className="card__hero-col">
                    <Icon.Group size='huge' className="left floated card__image">
                      <span className="e-avatar e-avatar-large" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/events/large/exchange-example.jpg)'}}></span>
                    </Icon.Group>
                  </div>
                </div>
              </Card.Content>
            </Card>
              </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
        </div>
        <div className='b-section center'>
                  <Grid  className="container margin-fix">
                      
                      
                      <Grid.Row columns={1}>
                          <Grid.Column>
                            <Segment textAlign="left" className="b-shadow spacing-x40 b-border--none">
                            <Label size='small' basic color='purple'>Personal Injury</Label><Label size='small' basic color='purple'>CPD 3.5</Label>
                              <h2>Overview</h2>
                              <p>Just how far does a bank have to go to protect customers from fraud - a look at the legal duties and practical steps banks could and should take.</p>
                              <p>This panel discussion will consider the current scope of the banker’s duty of care, including the incremental development of the Quincecare duty further to the Court of Appeal’s decision in Philipp v Barclays Bank UK PLC, as well as the systems and procedures that banks currently have in place to identify and combat fraud and what can realistically be achieved to enhance such systems.</p>
                              <p>Our panel will be chaired by Robert-Jan Temmink KC, and includes Nicola Allsop, Simon Oakes and we are pleased to welcome Katy Worobec (UK Finance Managing Director of Economic Crime).</p>
                              <p>Please register using the registration button below. This event will be held as a hybrid event. We would be very pleased to see you in person at Quadrant House. On the registration link, there is an option to register interest for attending in person, where your place will be confirmed separately.</p>
                              

                              
                              <h2>Programme</h2>
                              <div className="programme">
                                  <div className='programme__item'>
                                    <div className="programme__time">08:30</div>
                                    <div className="programme__des">Registration/Breakfast</div>
                                  </div>
                                  <div className='programme__item'>
                                    <div className="programme__time">09:00</div>
                                    <div className="programme__des">Co-chair’s welcome and opening comments<br/> 
                                    <strong>Bea Tormey, Freshfields</strong></div>
                                  </div>
                                  <div className='programme__item'>
                                    <div className="programme__time">09:05</div>
                                    <div className="programme__des">Keynote Address – The class action landscape<br/> 
                                    <strong>Paul Lomas, Competition Appeal Tribunal</strong> </div>
                                  </div>
                                  <div className='programme__item'>
                                    <div className="programme__time">09:30</div>
                                    <div className="programme__des">Panel 1 – Private Antitrust Litigation – The year in review<br/>  
                                    <strong>MLex moderator: Robert McLeod </strong></div>
                                  </div>
                              </div>

                              
                              <h2>Speakers</h2>
                              <div className='b-list-people__wrapper'>

                                <div className="b-list-people__list left row">
                                      <div className="b-list-people__item center">
                                        <a href=" " className='b-list-people__link'>
                                          <span className='b-list-people__image large border'>
                                              <span className="e-avatar e-avatar-circle"  style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png)'}}></span>
                                          </span>
                                          <span className='b-list-people__info'>
                                            <span className='b-list-people__name'>James Smith</span>
                                            <span className='b-list-people__job'>Barrister</span>
                                          </span>
                                        </a> 
                                      </div>
                                      <div className="b-list-people__item center">
                                        <a href=" " className='b-list-people__link'>
                                          <span className='b-list-people__image large border'>
                                              <span className="e-avatar e-avatar-circle"  style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png)'}}></span>
                                          </span>
                                          <span className='b-list-people__info'>
                                            <span className='b-list-people__name'>Justin Elliott Neal</span>
                                            <span className='b-list-people__job'>Barrister</span>
                                          </span>
                                        </a> 
                                      </div>
                                      
                                      <div className="b-list-people__item center">
                                        <a href=" " className='b-list-people__link'>
                                          <span className='b-list-people__image large border'>
                                              <span className="e-avatar e-avatar-circle"  style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png)'}}></span>
                                          </span>
                                          <span className='b-list-people__info'>
                                            <span className='b-list-people__name'>Mark Soos</span>
                                            <span className='b-list-people__job'>Barrister</span>
                                          </span>
                                        </a> 
                                      </div>
                                </div>
                              </div>
                            </Segment>
                          </Grid.Column>
                      </Grid.Row>
                  </Grid>
          </div>
    </div>
    </div>
  )
}

export default Event;
