import { useField } from 'formik';
import React, { useState , KeyboardEvent} from 'react';
// @ts-ignore
import { Form, Label, Icon, Button } from 'semantic-ui-react';
interface Props {
  KeyDownHandler?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  name: string;
  type?: string;
  placeholder: string;
  label?: string;
  disabled?: boolean;
  autoComplete?: string;
  tabIndex?: number;
}

export default function MyTextInput(props: Props) {
  const [field, meta] = useField(props.name);
  const [showPassword, setShowPassword] = useState(true);
  const clickHandler = () => {
    return (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        setShowPassword(!showPassword);
    }
  }
  return (
    <Form.Field style={{width: '100%'}} error={meta.touched && !!meta.error}>
      <label>{props.label}</label>
      <input {...field} tabIndex={ props.tabIndex } placeholder = {props.placeholder} autoComplete="on" type={ showPassword ? 'password' : 'text' } onKeyPressCapture={props.KeyDownHandler} />
      <Button className="input__reveal-btn" type="button" tabIndex={-100} title={ showPassword ? 'Reveal' : 'Hide'} onClick={ clickHandler() } onKeyPress={(e:React.KeyboardEvent) => { e.stopPropagation();e.preventDefault();return false; }}><Icon name={ showPassword ? 'eye' : 'eye slash'} /></Button>
      {meta.touched && meta.error ? (
        <Label basic color='red'> <Icon name="ban" fitted /> {meta.error}</Label>
      ) : null}
    </Form.Field>
    
  )
}
