import { RootStore } from "./rootStore";
import { observable, action, reaction, makeObservable, runInAction } from "mobx";
export default class CommonStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      token: observable,
      appLoaded: observable,
      twoStepLogin: observable,
      scrollPosition: observable,
      chambersActiveTab: observable,
      personActiveTab: observable,
      previousActiveChambersPage: observable,
      setToken: action,
      setTwoStepLogin: action,
      setAppLoaded: action,
      setInitialScrollPositionsPerPage: action,
      setScrollPosOnPage: action,
      getScrollPosition: action,
      setChambersActiveTab: action,
      getChambersActiveTab: action,
      setPersonActiveTab: action,
      getPersonActiveTab: action,
      setPreviousActiveChambersPage: action,
      getPreviousActiveChambersPage: action,
    });

    this.rootStore = rootStore;

    reaction(
      () => this.token,
      (token) => {
        console.log("In commmonStore jwt reaction");
        if (token) {
          window.localStorage.setItem("jwt", token);
        } else {
          console.log("Login attempt must have failed");
          window.localStorage.removeItem("jwt");
          window.localStorage.removeItem("LoginRequiresTwoFactorViaEmail");
        }
      }
    );

    reaction(
      () => this.twoStepLogin,
      (twoStepLogin) => {
        console.log("In commmonStore twoStepLogin reaction");
        if (twoStepLogin) {
          window.localStorage.setItem(
            "LoginRequiresTwoFactorViaEmail",
            twoStepLogin
          );
        } else {
          window.localStorage.removeItem("LoginRequiresTwoFactorViaEmail");
        }
      }
    );
  }

  token: string | null = window.localStorage.getItem("jwt");
  twoStepLogin: string | null = window.localStorage.getItem(
    "LoginRequiresTwoFactorViaEmail"
  );
  appLoaded = false;
  scrollPosition: any = [];
  chambersActiveTab: number = 0;
  personActiveTab: number = 0;
  previousActiveChambersPage: number = 1;

  setToken = (token: string | null) => {
    this.token = token;
  };

  setTwoStepLogin = (twoStepLogin: string | null) => {
    this.twoStepLogin = twoStepLogin;
  };

  setAppLoaded = () => {
    this.appLoaded = true;
  };

  setScrollPosOnPage = (pageScroll: any) => {
    //Find index of scrollPosition object that matches pageScroll.page    
    const objIndex = this.scrollPosition.findIndex(((obj: any) => obj.page == pageScroll.page));

    runInAction(() => {
      this.scrollPosition[objIndex].scrollPos = pageScroll.scrollPos; // update property of matching row 
    });

  };

  setInitialScrollPositionsPerPage = (pageCountAndScrolDefaults: any) => {
    this.scrollPosition = pageCountAndScrolDefaults;
  };

  getScrollPosition = () => {
    return this.scrollPosition;
  };
  
  setChambersActiveTab = (activeTab: number) => {
    runInAction(() => {
      this.chambersActiveTab = activeTab;
    });
  };
  
  setPreviousActiveChambersPage = (activePage: number) => {
    runInAction(() => {
      this.previousActiveChambersPage = activePage;
    });
  };
  
  getChambersActiveTab = () => {
    return this.chambersActiveTab;
  };
  
  setPersonActiveTab = (activeTab: number) => {
    runInAction(() => {
      this.personActiveTab = activeTab;
    });
  };
  
  getPersonActiveTab = () => {
    return this.personActiveTab;
  };
  
  getPreviousActiveChambersPage = () => {
    return this.previousActiveChambersPage;
  };
}