import React from "react";
import { createBrowserRouter, Navigate, Route, RouteObject } from "react-router-dom";
import ChambersDashboard from "../../features/businesses/dashboard/ChambersDashboard";
import CaseSearch from "../../features/case/CaseSearch";
import ContactDashboard from "../../features/contacts/dashboard/ContactDashboard";
import Dashboard from "../../features/dashboard/Dashboard";
import EventsDashboard from "../../features/events/EventsDashboard";
import EventPage from "../../features/events/Event";
import HomePage from "../../features/home/HomePage";
import ReadingList from "../../features/news/ReadingList";
import PeopleDashboard from "../../features/people/dashboard/PeopleDashboard";
import ProfilePage from "../../features/profiles/ProfilePage";
import MyDetails from "../../features/registration/MyDetails";
import LEXProfileCheck from "../../features/registration/LEXProfileCheck";
import PasswordReset from "../../features/registration/PasswordReset";
import SolicitorsDashboard from "../../features/solicitors/dashboard/SolicitorsDashboard";
import BusinessProfile from "../../features/solicitors/profile/BusinessProfile";
import App from "../layout/App";
import NotFound from "../layout/NotFound";
import ProtectedRoute from "./ProtectedRoute";

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {path: '*', element: <NotFound />},
      {path: 'setPassword/*', element: <PasswordReset />},
      {path: 'contacts', element: <ProtectedRoute><ContactDashboard /></ProtectedRoute>},
      {path: 'logout', element: <HomePage />},
      {path: '/', element: <HomePage />},
      {path: 'all-about-me', element: <ProtectedRoute><MyDetails /></ProtectedRoute>},
      {path: 'profiles/:username', element: <ProtectedRoute><ProfilePage username={""} /></ProtectedRoute>},
      {path: 'profile/:id', element: <ProtectedRoute><ProfilePage username={""} /></ProtectedRoute>},
      {path: 'people', element: <ProtectedRoute><PeopleDashboard /></ProtectedRoute>},
      {path: 'people/barristers', element: <ProtectedRoute><PeopleDashboard /></ProtectedRoute>},
      {path: 'people/navstack/:id', element: <ProtectedRoute><PeopleDashboard /></ProtectedRoute>},
      {path: 'LEXProfileCheck', element: <ProtectedRoute><LEXProfileCheck /></ProtectedRoute>},
      {path: 'mydetails', element: <ProtectedRoute><MyDetails /></ProtectedRoute>},
      {path: 'dashboard', element: <ProtectedRoute><Dashboard /></ProtectedRoute>},
      {path: 'reading-list', element: <ProtectedRoute><ReadingList /></ProtectedRoute>},
      {path: 'cases', element: <ProtectedRoute><CaseSearch /></ProtectedRoute>},
      {path: 'events', element: <ProtectedRoute><EventsDashboard /></ProtectedRoute>},
      {path: 'event', element: <ProtectedRoute><EventPage /></ProtectedRoute>},
      {path: 'chambers', element: <ProtectedRoute><ChambersDashboard /></ProtectedRoute>},
      {path: 'chambers/:id', element: <ProtectedRoute><ChambersDashboard /></ProtectedRoute>},
      {path: 'chambers/navstack/:id', element: <ProtectedRoute><ChambersDashboard /></ProtectedRoute>},
      {path: 'solicitors', element: <ProtectedRoute><SolicitorsDashboard /></ProtectedRoute>},
      {path: 'solicitors/navstack/:id', element: <ProtectedRoute><SolicitorsDashboard /></ProtectedRoute>},
      {path: 'businesses', element: <ProtectedRoute><BusinessProfile /></ProtectedRoute>},
      {path: 'business/:id/:ind?', element: <ProtectedRoute><BusinessProfile /></ProtectedRoute>},
      
    ]
  }
]

export const router = createBrowserRouter(routes);