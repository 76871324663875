import React,
  {
    useContext,
    // useEffect, 
    useState
  } from 'react';
import { RootStoreContext } from '../../../app/stores/rootStore';
// import { toJS } from 'mobx';
import agent from '../../../app/api/agent';
import queries from '../../../app/api/queries';

import { Icon } from 'semantic-ui-react';

const ContactDashboard = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    // userContacts,
    isLoggedIn,
    loggedInUser
  } = rootStore.userStore;
  const { setShowFilter } = rootStore.navigationStore;
  // const contactsJs = toJS(userContacts);

  const [searchByEmail, setSearchByEmail] = useState('');
  const [searchResults, setSearchResults] = useState('');

  let query = queries.contactByEmail(searchByEmail);

  const getEmail = async () => {
    try {
      let emailContact = await agent.Contacts.contactByEmail(query);
      console.log('Contact email is', emailContact.data.contactByEmail);
      if (emailContact.data.contactByEmail) {
        setSearchResults(`${emailContact.data.contactByEmail.firstName} ${emailContact.data.contactByEmail.lastName}`)
      }
      else setSearchResults('No contact found for that email')
      

    } catch (error) {
      console.log('Error', error);
    }  
  }

  const updateState = (e: any) => {
    setSearchByEmail(e.target.value);
  }

  const getContactIfEnterKey = (e: any) => {
    if (e.key === 'Enter') {
      setSearchByEmail(e.target.value);
      getEmail();
    }
  }
  
  return (
    <>
    <div className='b-container__scroller'>
      <div className="b-section__wrap b-search-page">
        <div className="b-section center">
          <h1>Contact search</h1>
          <div className="ui grid padded b-searchbar">
            <div className="sixteen wide column ">
              <div className='ui segment b-shadow b-border--radius-12'>
                <form className="ui form">
                  <div className="equal width fields">
                    <div className="field">
                      <input type="text" name="contact" placeholder="Contact name" />
                    </div>
                    <div className="field">
                      <input type="text" name="email" placeholder="Email address" />
                    </div>
                    <button className="primary ui button" title="Advanced filters" onClick={(e) => { setShowFilter(true);e.preventDefault();return false; }}><Icon name="sliders horizontal" /></button>
                 
                    <button className="secondary ui button" type="submit"><i className="search icon"></i></button>
                  </div>
                </form>          
              </div>
            </div>
          </div>
          <div className="ui stackable three column grid b-result__grid b-grid--3">
            <div className="column">
              <div className="ui fluid card b-result person b-shadow">
                <div className="content">
                  <span className="b-result__content">
                    <div className="header">
                      <h4>FirstName LastName</h4>
                      <h5>
                        <span className="date">
                          Call
                        </span>
                        |
                        <span className="date">
                          Silk
                        </span>
                      </h5>
                      <div className="b-result__type">
                        <div className="ui mini circular label secondary">Barrister</div>
                        <div className="ui mini circular label">Mediation</div>
                      </div>
                    </div>
                    <div className="description">
                      <a href="#">1 Hare Court</a>
                      <i className="map marker small icon"></i>
                      London
                      <br></br>
                    </div>
                  </span>
                  <span className="b-result__avatar">
                    <span className="b-result__avatar-skew">
                      <span className="b-result__avatar-img"></span>
                    </span>
                  </span>
                </div>
                <div className="extra content">
                  <button className="ui small button float-right primary">View Profile</button>
                  <button className="ui small button float-right secondary">Add Contact</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
          {isLoggedIn && loggedInUser ? (
              <div className="ui grid padded b-searchbar">
                <button
                  className="ui button"
                  style={{
                    display: "inline-block",
                    margin: "0 10px 0 10px",
                  }}
                  onClick={getEmail}
                >
                  Get Contact by Email
                </button>
                  <input
                    className="e-input"
                    style={{
                      width: "250px",
                      display: "inline-block",
                      marginRight: "60%"
                    }}
                    type="email"
                    placeholder="Enter Email"
                    value={searchByEmail}
                    onChange={updateState}
                    onKeyUp={getContactIfEnterKey}
                  />
                <div style={{margin: "5px 0 0 193px"}}>{searchResults}</div>
                <br />
              </div>
          ) : (
            <span>No contacts</span>
          )}
          </div>
    </>
  );
}

export default ContactDashboard;
