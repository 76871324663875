import { RootStore } from "./rootStore";
import { observable, makeObservable, action, runInAction } from "mobx";
import agent from '../api/agent';
import queries from "../api/queries";

export default class SolicitorStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
      makeObservable(this, {
          offices: observable,
          previousSearches: observable,
          showMap: observable,
          setShowMap: action
      });

      this.rootStore = rootStore;
  }

  offices = [];
  previousSearches = [];
  showMap: boolean = false;
  setShowMap = (status: boolean) => {
    this.showMap = status;
  };

  setSraOffices = async () => {
    let query = queries.sRAOrganisationOfficeSearch('', '', '', '', true);
    
    try {
      let allOffices = await agent.Sra.sRAOrganisationOfficeSearch(query);
      
      runInAction(() => {
        if (allOffices) {
          this.offices = allOffices.data.sRAOrganisationOfficeSearch;
        }
        else {
          console.log('No solicitor offices');
        }
      });

    } catch (error) {
      console.log('We have an error getting getting ALL SRA offices: ', error); 
    }
  }

  getSraOffices = () => {
    return this.offices;
  }

  setPreviousSraSearches = (prevSearch: any) => {
    this.previousSearches = prevSearch;
  }

  getPreviousSraSearches = () => {
    return this.previousSearches;
  }
}