import React, { useContext } from 'react';
import { MenuComponent, SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx';

interface IProps {
	dockBar: any;
	toggleDockBar: any;
	toggleSidebar: any;
	configObj: any;
	systemObj: any;
	searchObj: any;
	billingObj: any;
	utilitiesObj: any;
	showDashboardObj: any;
	showCasesObj: any;
	showNewsObj: any;
	showEventsObj: any;
	apiObj: any;
	type: any;
	onCreate: () => void;
	sidebarMenuSelect: (page: string) => void;
	userMenuSelect: (args: any) => void;
	AccountMenuItem: object[];
	closeAllSidebars: () => void;
}

const PushMenu:  React.FC<IProps> = ({
		dockBar,
		toggleDockBar,
		toggleSidebar,
		configObj,
		systemObj,
		billingObj,
		utilitiesObj,
		searchObj,
		showDashboardObj,
		showCasesObj,
		showNewsObj,
		showEventsObj,
		apiObj,
		type,
		onCreate,
		sidebarMenuSelect,
		userMenuSelect,
		closeAllSidebars,
		AccountMenuItem
	}) => {

		
	const rootStore = useContext(RootStoreContext);
	const { logout, loggedInUser, getUserPermissions } = rootStore.userStore;
  const { showNavStack, setShowNavStack } = rootStore.navigationStore;
	const userName: string = loggedInUser ? `${loggedInUser!.User!.FirstName!} ${loggedInUser!.User!.LastName!}` : '';
	const userInitals: string = loggedInUser ? `${loggedInUser!.User!.FirstName.substring(0, 1) }${loggedInUser!.User!.LastName.substring(0, 1)}` : '';

	const optionToShow = toJS(getUserPermissions());

	const showChambersObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'Chambers';});
	const showSolicitorsObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'SolicitorFirms';});
	const showPeopleObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'Clerks/Admin';});
	const showBusinessesObj = optionToShow.filter(obj => { return obj['SystemOptionName'] === 'Barristers';});

		return (
			<>
			<div className={ 'stack__shade ' + (showNavStack ?  'open' : '')} onClick={() => setShowNavStack(!showNavStack)} ></div>
        	<button className="sm__pusher-close" onClick={() => closeAllSidebars()}></button>
				<nav id="sm__menu" className="sm__menu" >
					<div className="sm__branding"> 
						<button className="icos-button branding" onClick={toggleDockBar}>
							<span className="branding-logo"></span>
							<span className="icos hamburger hamburger--elastic">
								<span className="hamburger-box">
									<span className="hamburger-inner"></span>
								</span>
							</span>
						</button>
					</div>
					{/*// @ts-ignore - Ignore TS error on transform property */}
					

					<SidebarComponent id="systemObj" className='level_1_system sm__level sm__level--1' ref={systemObj} width=" " transform=" " zIndex=" " type={type} created={onCreate} >
						<div className="sm__title"><h2>System</h2></div>
						<ul className='sm__list'> 
							<li className="sm__item"><button className="sm__link" onClick={() => toggleSidebar(billingObj)} ><i className="icon angle left"></i>Billing</button></li>
							<li className="sm__item"><button className="sm__link" onClick={() => toggleSidebar(configObj)} ><i className="icon angle left"></i>Configuration</button></li>
							<li className="sm__item"><button className="sm__link" >Reporting</button></li>
							<li className="sm__item"><button className="sm__link" >Security</button></li>
							<li className="sm__item"><button className="sm__link" >System Options</button></li>
							<li className="sm__item"><button className="sm__link" onClick={() => toggleSidebar(utilitiesObj)} ><i className="icon angle left"></i>Utilities</button></li>
							{/* <li className="sm__item"><button className="sm__link" onClick={() => toggleSidebar(apiObj)}><i className="icon angle left"></i> Real API Calls</button></li> */}
						</ul>
					</SidebarComponent>
					<button className="sm__close" onClick={() => toggleSidebar(systemObj, 'home')} ><div className="sm__level-icon"><i className="icon home "></i></div></button>

					<SidebarComponent id="billingObj" className='level_2_billing sm__level sm__level--2' width=" " zIndex=" " ref={billingObj} type={type} created={onCreate} >
						<div className="sm__title"><h2>Billing</h2></div>
						<ul className='sm__list'>
							<li className="sm__item"><button className="sm__link" >Appointment Billing</button></li>
							<li className="sm__item"><button className="sm__link" >Carter Billing</button></li>
							<li className="sm__item"><button className="sm__link" >CCMS Bulk Upload</button></li>
							<li className="sm__item"><button className="sm__link" >Manual Document Processing</button></li>
							<li className="sm__item"><button className="sm__link" >Workflow Forecast</button></li>
						</ul>
					</SidebarComponent>
					<button className="sm__close" onClick={() => toggleSidebar(billingObj, 'cogs')}><div className="sm__level-icon"><i className="icon cogs "></i></div></button>

					<SidebarComponent id="configObj" className='level_2_config sm__level sm__level--2' width=" " zIndex=" " ref={configObj} type={type} created={onCreate} >
						<div className="sm__title"><h2>Configuration</h2></div>
						<ul className='sm__list'>
							<li className="sm__item"><button className="sm__link">Areas Of Specialism</button></li>
							<li className="sm__item"><button className="sm__link">Appointment Fee Codes</button></li>
							<li className="sm__item"><button className="sm__link">BMIF Codes</button></li>
							<li className="sm__item"><button className="sm__link">Case Fee Codes</button></li>
							<li className="sm__item"><button className="sm__link">Case Types </button></li>
							<li className="sm__item"><button className="sm__link">CFA Profile Editor</button></li>
							<li className="sm__item"><button className="sm__link">Contracts </button></li>
							<li className="sm__item"><button className="sm__link" >Document Templates</button></li>
							<li className="sm__item"><button className="sm__link" >Email Templates</button></li>
							<li className="sm__item"><button className="sm__link" >Hearing Types</button></li>
							<li className="sm__item"><button className="sm__link"  >Price Lists</button></li>
							<li className="sm__item"><button className="sm__link"  >Privacy Notices</button></li>
							<li className="sm__item"><button className="sm__link" >Time Recording Setup </button></li>
							<li className="sm__item"><button className="sm__link"  >UDF And Group Edit</button></li>
							<li className="sm__item"><button className="sm__link"  >Workflow</button></li>
						</ul>
					</SidebarComponent>
					<button className="sm__close" onClick={() => toggleSidebar(configObj, 'cogs')}><div className="sm__level-icon"><i className="icon cogs "></i></div></button>

					<SidebarComponent id="utilitiesObj" className='level_2_utilities sm__level sm__level--2' width=" " zIndex=" " ref={utilitiesObj} type={type} created={onCreate} >
						<div className="sm__title"><h2>Utilities</h2></div>
						<ul className='sm__list'>
							<li className="sm__item"><button className="sm__link">Utilities Check</button></li>
							<li className="sm__item"><button className="sm__link">Mail Room</button></li>
							<li className="sm__item"><button className="sm__link">Returns Labels</button></li>
							<li className="sm__item"><button className="sm__link">Scanned Documents</button></li>
							<li className="sm__item"><button className="sm__link">Registration</button></li>
							<li className="sm__item"><button className="sm__link">Support Centre</button></li>
						</ul>
					</SidebarComponent>
					<button className="sm__close" onClick={() => toggleSidebar(utilitiesObj, 'cogs')}><div className="sm__level-icon"><i className="icon cogs "></i></div></button>

					<SidebarComponent id="apiObj" className='level_2_api sm__level sm__level--2' ref={apiObj} width=" " zIndex=" " type={type} created={onCreate} >
						<div className="sm__title"><h2>Real_API_Calls</h2></div> 
						<ul className='sm__list'>
							<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Contacts")} >Contacts Search</button></li>
						</ul>
					</SidebarComponent>
					<button className="sm__close" onClick={() => toggleSidebar(apiObj, 'cogs')}><div className="sm__level-icon"><i className="icon cogs "></i></div></button>
					{/*// @ts-ignore - Ignore TS error on docWidth property */}

					<SidebarComponent id="searchObj" className='level_1_search sm__level sm__level--1' ref={searchObj} width=" " transform=" " zIndex=" " type={type} created={onCreate} >
						<div className="sm__title"><h2>Search</h2></div>
							<ul className='sm__list'>
								{/* <li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Contacts")}>Contacts</button></li> */}
								{ showChambersObj.length > 0 && (<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Chambers")}>Chambers</button></li> )}
								{ showSolicitorsObj.length > 0 && (<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Solicitors")}>Solicitors</button></li> )}
								{/* { showBusinessesObj.length > 0 && (<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Businesses")}>Businesses</button></li> )} */}
								<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Barristers")}>Barristers</button></li>
								{ showPeopleObj.length > 0 && (<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("People")}>People</button></li>)}
							</ul>
						</SidebarComponent><button className="sm__close" onClick={() => toggleSidebar(searchObj, 'home')}><div className="sm__level-icon"><i className="icon home "></i></div></button>



					<SidebarComponent id="dockSidebar" className="sm__menu" ref={dockBar} enableDock={true} width=" " zIndex=" " onClick={e => {e.preventDefault();e.stopPropagation();}}> 
						<div className="sm__level" >
							<div className="sm__header">
								<button className="sm__header-link" title="Edit User Settings" onClick={() => sidebarMenuSelect("Profile Settings")}>
									<i className="icon cog"></i>
									<span className="b-avatar">
										<span className="b-avatar__image border--none">
											{ /* synfusion control e-avatar*/}
											<span className="e-avatar e-avatar-xlarge e-avatar-circle">{userInitals}</span>
										</span>
									<span className="b-avatar__name">{ userName }</span></span>
								</button>
							</div>
							<ul className="sm__list">
							{ searchObj && (<li className="sm__item"><button className="sm__link" onClick={() => toggleSidebar(searchObj)}><i className="icon angle left"></i><i className="icon search"></i>Search</button></li> )}
							{ showDashboardObj && (	<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Dashboard")}><i className="icon tachometer alternate"></i>Dashboard</button></li> )}
							{ showCasesObj && (<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Cases")} title="Cases" ><i className="icon briefcase"></i>Cases</button></li> )}
							{ showNewsObj && (<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("News")} title="Reading List"><i className="icon newspaper"></i>Reading List</button></li> )}
							{ showEventsObj && (<li className="sm__item"><button className="sm__link" onClick={() => sidebarMenuSelect("Events")} title="Events" ><i className="icon calendar alternate outline"></i>Legal Events</button></li> )}
							</ul>
							<ul className="sm__list bottom">
							{ systemObj && (<li className="sm__item"><button className="sm__link" onClick={() => toggleSidebar(systemObj)}><i className="icon angle left"></i><i className="icon cogs"></i>System</button></li>)}
								<li className="sm__item"><button className="sm__link" onClick={() => logout() }><i className="icon sign-out"></i>Sign Out</button></li>
							</ul>
						</div>
					</SidebarComponent>
					</nav>
			</>
	);
	
}

export default observer(PushMenu);