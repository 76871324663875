import { RootStore } from './rootStore';
import { observable, makeObservable, action, runInAction, computed, reaction, toJS } from 'mobx';
import { IProfile, IPhoto, IUserActivity } from '../models/profile';
import agent from '../api/agent';
import { toast } from 'react-toastify';
export default class ProfileStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      profile: observable,
      loadingProfile: observable,
      uploadingPhoto: observable,
      loading: observable,
      followings: observable,
      activeTab: observable,
      userActivities: observable,
      loadingActivities: observable,
      profileAols: observable,
      uploading: observable,
      isCurrentUser: computed,
      loadUserActivities: action,
      setActiveTab: action,
      loadProfile: action,
      uploadPhoto: action,
      setMainPhoto: action,
      deletePhoto: action,
      updateProfile: action,
      follow: action,
      unfollow: action,
      loadFollowings: action,
      addProfileAol: action,
      removeProfileAol: action,
      getProfileAreasOfLaw: action
    });

    this.rootStore = rootStore;

    reaction(
        () => this.activeTab,
        activeTab => {
            if (activeTab === 3 || activeTab === 4) {
                const predicate = activeTab === 3 ? 'followers' : 'following';
                this.loadFollowings(predicate)
            } else {
                this.followings = [];
            }
        }
    )
  }

  uploading = false;
  profile: IProfile | null = null;
  loadingProfile = true;
  uploadingPhoto = false;
  loading = false;
  followings: IProfile[] = [];
  activeTab: number = 0;
  userActivities: IUserActivity[] = [];
  loadingActivities = false;
  profileAols: any = [];

  get isCurrentUser() {
    const selectedPersonEmail = toJS(this.rootStore.personStore.selectedPersonProfile.Email);
    // Allow barsquared or opus2 users to edit profile page
    const isBarsquared = selectedPersonEmail.includes('barsquared.com') || selectedPersonEmail.includes('opus2.com'); 

    return this.rootStore.userStore.user!.User!.Email === selectedPersonEmail || isBarsquared;
  }

  loadUserActivities = async (username: string, predicate?: string) => {
    this.loadingActivities = true;
    try {
      const activities = await agent.Profiles.listActivities(username, predicate!);
      runInAction(() => {
        this.userActivities = activities;
        this.loadingActivities = false;
      })
    } catch (error) {
      toast.error('Problem loading activities')
      runInAction(() => {
        this.loadingActivities = false;
      })
    }
  };

  setActiveTab = (activeIndex: number) => {
      this.activeTab = activeIndex;
  }; 

  loadProfile = async (username: string) => {
     this.profile = {
      displayName: 'Kevin',
      username: 'kev',
      bio: 'I enjoy flower arranging and banging two bricks together',
      image: process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png',
      following: false,
      followersCount: 0,
      followingCount: 0,
      photos: []
    }
    try {
      runInAction(() => {
        this.loadingProfile = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingProfile = false;
      });
      console.log(error);
    }
  };

  addProfileAol = ( aols: any ) => {
    try {
      runInAction(() => {
        this.profileAols.push(aols)
      });
    } catch (error) {
      console.log(error);
    }
  }

  removeProfileAol = (val: any) => {
    const foundIndex = this.profileAols.findIndex(({text}:any) => text === val)
    console.log('foundIndex =  ', foundIndex);  

    this.profileAols.splice(foundIndex, 1);
  }

  uploadPhoto = async (file: Blob) => {
    this.uploadingPhoto = true;
    try {
      // const response = await agent.Profiles.uploadPhoto(file);
      // console.log('qwertyuiop',response )
      // @ts-ignore
      // const photo = response.data;

      // Using fetch for now because Axios interceptor headers upset Cloudinary server - CORS error
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'yoteftmf');

      const photo = await fetch('https://api.cloudinary.com/v1_1/opus2-lex-network/image/upload', {
        method: 'POST', 
        body: formData
      }).then(response => response.json()); // need to convert response into json

      runInAction(() => {
        if (this.profile) {
          this.profile.photos.push(photo);
          // if (photo.isMain && this.rootStore.userStore.user) {
          if (this.rootStore.userStore.user) {
            this.rootStore.userStore.setImage(photo.url);
            this.profile.image = photo.url;
            toast.success('Your image was sucessfully uploaded');
          }
        }
        this.uploadingPhoto = false;
      });
    } catch (error) {
      console.log(error);
      toast.error('Problem uploading photo');
      runInAction(() => {
        this.uploadingPhoto = false;
      });
    }
  };

  setMainPhoto = async (photo: IPhoto) => {
    this.loading = true;
    try {
      await agent.Profiles.setMainPhoto(photo.id);
      runInAction(() => {
        this.rootStore.userStore.user!.image = photo.url;
        this.profile!.photos.find(a => a.isMain)!.isMain = false;
        this.profile!.photos.find(a => a.id === photo.id)!.isMain = true;
        this.profile!.image = photo.url;
        this.loading = false;
      });
    } catch (error) {
      toast.error('Problem setting photo as main');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  deletePhoto = async (photo: IPhoto) => {
    this.loading = true;
    try {
      await agent.Profiles.deletePhoto(photo.id);
      runInAction(() => {
        this.profile!.photos = this.profile!.photos.filter(
          a => a.id !== photo.id
        );
        this.loading = false;
      });
    } catch (error) {
      toast.error('Problem deleting the photo');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  updateProfile = async (profile: Partial<IProfile>) => {
    try {
      await agent.Profiles.updateProfile(profile);
      runInAction(() => {
        if (
          profile.displayName !== this.rootStore.userStore.user!.displayName
        ) {
          this.rootStore.userStore.user!.displayName = profile.displayName!;
        }
        this.profile = { ...this.profile!, ...profile };
      });
    } catch (error) {
      toast.error('Problem updating profile');
    }
  };

  follow = async (username: string) => {
    this.loading = true;
    try {
      await agent.Profiles.follow(username);
      runInAction(() => {
        this.profile!.following = true;
        this.profile!.followersCount++;
        this.loading = false;
      });
    } catch (error) {
      toast.error('Problem following user');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  unfollow = async (username: string) => {
    this.loading = true;
    try {
      await agent.Profiles.unfollow(username);
      runInAction(() => {
        this.profile!.following = false;
        this.profile!.followersCount--;
        this.loading = false;
      });
    } catch (error) {
      toast.error('Problem unfollowing user');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  loadFollowings = async (predicate: string) => {
    this.loading = true;
    try {
      const profiles = await agent.Profiles.listFollowings(
        this.profile!.username,
        predicate
      );
      runInAction(() => {
          this.followings = profiles;
          this.loading = false;
      })
    } catch (error) {
      toast.error('Problem loading followings');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  getProfileAreasOfLaw = () => {
    return this.profileAols;
  };

  getProfile = () => {
    return this.profile;
  };
}
