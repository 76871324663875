import React, { useContext, Fragment } from 'react';

import { Container, Segment, Header, Button, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
// import { toJS } from 'mobx';

const ForgottenPassword = () => {

  return (
    <Fragment>
      <div className="ui middle center aligned horizontally padded grid" >
        <div className="column" style={{maxWidth: '800px'}}>
          <h2 className="ui teal image header center aligned">
            <div className="app-logo"></div>
          </h2>
          <div className="ui left aligned segment b-padding--x3 b-shadow b-border--none">
          <h2 className="ui center aligned icon header">
            <i className="circular key icon secondary"></i>
          </h2>
            <h3 className="ui header">Password Reset</h3>
            <p>A request to reset your password has been sent to <strong>john.smith@barsquared.com</strong>. Please click on the link within the email to reset your password.<br /><br /></p>
            <button className="ui button primary">Resend Email</button><button className="ui button inverted primary">Back To Login</button>
          </div> 
        </div>
      </div>
      <br />
      <br />
      <br />
    </Fragment>
  );
};

export default ForgottenPassword;
