import { RootStore } from "./rootStore";
import { observable, makeObservable, action, runInAction, reaction, toJS } from "mobx";
import agent from '../api/agent';
export default class BusinessStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
      makeObservable(this, {
          businessOfficesArr: observable,
          previousOfficeSearches: observable,
          businesses: observable,
          selectedBusinessOffices: observable,
          selectedBusinessHq: observable,
          selectedBusinessPeople: observable,
          showMap: observable,
          setShowMap: action,
          setPreviousOfficeSearches: action
      });

      // reaction(
      //   () => this.selectedBusinessOffices,
      //   selectedBusinessOffices => {
      //       console.log('In businessStore reaction')
      //       if (selectedBusinessOffices) {
      //         try {
      //           console.log('selectedBusinessOffices---', toJS(selectedBusinessOffices))
      //           let businesses =  agent.Businesses.getBusinesses(toJS(selectedBusinessOffices.BusinessId));
      //           runInAction(() => {
      //             if (businesses) {
      //               this.businesses = businesses;
      //             }
      //             else {
      //               console.log('No business data');
      //             }
      //           });
      //           return this.businesses; 
      //         } catch (error) {
      //           console.log('We have an error getting business data: ', error); 
      //         };

      //       }
      //   }
      // );

      this.rootStore = rootStore;
  }

  businessOfficesArr: any[] = [];
  selectedBusinessPeople: any[] = [];
  previousOfficeSearches = [];
  businesses = [];
  selectedBusinessOffices = {};
  selectedBusinessHq = {};
  showMap: boolean = false;
  setShowMap = (status: boolean) => {
    this.showMap = status;
  };

  setBusinesses = async (params: string ) => {
    // Construct query string depending on parametes passed in e.g. '?city=nottingham&name=smith&skip5&take5' etc.
    // params: businessType(int), city, name, postcode, workAres(string(uuid)), orderBy, skip(int), take(int), 
      
    try {
      let businesses = await agent.Businesses.getBusinesses(params);
      runInAction(() => {
        if (businesses) {
          this.businesses = businesses;
        }
        else {
          console.log('No business data');
        }
      });
      return this.businesses; 
    } catch (error) {
      console.log('We have an error getting business data: ', error); 
    }
  }

  reorderBusinesses = async (busnessesReordered: any ) => { 
    runInAction(() => {
      if (busnessesReordered) {
        this.businesses = busnessesReordered;
      }
      else {
        console.log('No businesses to reorder');
      }
    });
    return this.businesses;
  }

  getBusinessOffices = () => {
    return this.businessOfficesArr;
  }

  setPreviousOfficeSearches = (prevSearch: any) => {
    // the runInAction callback prevents the "MobX: Since strict-mode is enabled,changing (observed)
    // observable values without using an action is not allowed" warning 
    runInAction(() => {
      this.previousOfficeSearches = prevSearch;
    })
  }

  getPreviousOfficeSearches = () => {
    return this.previousOfficeSearches;
  }

  getBusinesses = () => {
    return this.businesses;
  }

  getBusiness = (ind: any) => {
    return this.businesses[ind];
  }

  setBusinessOffices = async (id: string) => {
    try {
      let busOff = await agent.Businesses.getBusinesses(id);
      runInAction(() => {
        if (busOff) {
          this.businessOfficesArr = busOff;
        }
        else {
          console.log('No business offices found');
        }
      });
    } catch (error) {
      console.log('We have an error getting business offices data: ', error); 
    }  
  }

  setSelectedBusinessOffices = async (id: string) => {
    try {
      let businessProfile = await agent.Businesses.getBusinessOffices(id);
      runInAction(() => {
        if (businessProfile) {
          this.selectedBusinessOffices = businessProfile;
        }
        else {
          console.log('No business profile data');
        }
      });
    } catch (error) {
      console.log('We have an error getting business profile data: ', error); 
    }  
  }

  setBusinessPeople = async (id: string) => {
    try {
      let selectedBusinessPeople = await agent.People.getPeoplePerBusiness(id);
      runInAction(() => {
        if (selectedBusinessPeople) {
          this.selectedBusinessPeople = selectedBusinessPeople;
        }
        else {
          console.log('No business people data');
        }
      });
    } catch (error) {
      console.log('We have an error getting selectredbusiness people data: ', error); 
    }  
  }

  getBusinessProfiles = (id: string) => {

  }

  clearBusinessProfile = () => {
    
  };

  getBusinessProfile = () => {
    return this.selectedBusinessOffices;
  };

  setSelectedBusinessHq = async (id: any) => {
    let thisBusProf = await agent.Businesses.getBusinessProfile(id);
    try {
      runInAction(() => {
        if (thisBusProf) {
          this.selectedBusinessHq = thisBusProf;
        }
        else {
          console.log('No business profile data');
        }
      });
    } catch (error) {
      console.log('We have an error getting profile data: ', error); 
    }   
  };

  getSelectedBusinessHq = () => {
    return this.selectedBusinessHq; 
  };

  getBusinessPeople = () => {
    return this.selectedBusinessPeople;
  };
}