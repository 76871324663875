import React, { Fragment } from 'react';
const EventsDashboard = () => {
  return (
    <Fragment>
      <div className="b-section__wrap b-search-page">
        <div className="b-section center">
            <h2>Webinars/Seminars Dashboard</h2>
          </div>
      </div>
    </Fragment>
  )
}

export default EventsDashboard;
