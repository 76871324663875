import React, { Fragment } from 'react';
const ReadingList = () => {
  return (
    <Fragment>
      <div className="b-section__wrap b-search-page">
        <div className="b-section center">
            <h2>Reading List / News</h2>
          </div>
      </div>
    </Fragment>
  )
}

export default ReadingList;
