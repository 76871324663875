import React,{ useContext,useEffect,useRef,useState} from 'react';
import { RootStoreContext } from '../../../app/stores/rootStore';

import { Button, Dropdown, Form, Icon, Label, Pagination } from 'semantic-ui-react';
import { getPeople } from '../../../app/common/functions/commonFunctions';
import { createSpinner, hideSpinner, showSpinner } from '@syncfusion/ej2-react-popups';
import { toJS } from 'mobx';
import { v4 as uuid } from 'uuid';
import { router } from '../../../app/router/Routes';

const PeopleDashboard = () => {
  const rootStore = useContext(RootStoreContext);
  const { setShowFilter } = rootStore.navigationStore;
  const {
    getPreviousPersonSearches,
    setPreviousPersonSearches,
    setPersonProfile
  } = rootStore.personStore;
  const { setSelectedBusinessOffices, setSelectedBusinessHq } = rootStore.businessStore;
  
  const [searchByName, setSearchByName] = useState('');
  const [searchByCity, setSearchByCity] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [orderValues, setOrderValues] = useState({orderName: "Order by...", orderValue: "REORDERBY"});
  const [firstRun, setFirstRun] = useState<boolean>(true);

  const pastSearches = useRef<any[]>(toJS(getPreviousPersonSearches()));

  const orderOptions = [
    { key: "1", text: "City", value: "city" },
    { key: "2", text: "Name", value: "lastName" }
  ];

  useEffect(() => {
    if (window.location.pathname.includes('navstack') && firstRun) {
      console.log('firstRun & ', window.location.pathname)
      let searchId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
      let prevSearches: any = JSON.parse(localStorage.getItem('navstackSearchParams')!);
      let prevSearchParams = prevSearches.find(({id}: any) => id === searchId);

      getPeopleSearch(true, prevSearchParams.city, prevSearchParams.name);
    }
    if (localStorage.getItem('searchPeopleHistory') !== null && firstRun) {
      setSearchResults(JSON.parse(localStorage.getItem('searchPeopleHistory')!));
      setShowResults(true);
    };

    if (firstRun) setFirstRun(false);

    return function cleanup() {
      // if (showResults) alert('when we unmount this should fire'); 
    };
  }, [showResults, searchResults]);

  const onOrderChange = (event:any , data:any ) => {
    // deconstruct data object
    const { text, value, key } = data;

    let textLabel = data.options.filter((opt: any, index: any) => {
      return opt.value === value;
    })

    // construct object to replace default
    let selection = {'orderName': textLabel[0].text, 'orderValue': value};

    setOrderValues(selection);

    let orderby = value === 'city' ? 'City' : 'LastName';
    reorderCurrentSearch(orderby);
  };

  const reorderCurrentSearch = (orderby: any) => {
    if (localStorage.getItem('searchPeopleHistory') !== null) {
      let reorderPeople = JSON.parse(localStorage.getItem('searchPeopleHistory')!);
      reorderPeople.sort((a: any, b: any) => {
        if (!a.City) a.City = ''; // check for falsey values i.e. null, undefined, "",  
        if (!b.City) b.City = ''; //  false, NaN, 0 and just set value to empty 
        if (!a.LastName) a.LastName = ''; // string to prevent localeCompare 
        if (!b.LastName) b.LastName = ''; // throwing an error

        return a[orderby].localeCompare(b[orderby]);
      });

      localStorage.setItem('searchPeopleHistory', JSON.stringify(reorderPeople));
    
      setSearchResults(JSON.parse(localStorage.getItem('searchPeopleHistory')!));
    }
  };

  const removeSearchPeopleHistory = () => {
    localStorage.removeItem('searchPeopleHistory');
    setShowResults(false);
  };

  const onActionComplete = () => {
    try {
      hideSpinner(document.getElementById('spinner')!)
      console.log('hide spinner')
      setSearchByName('');
      setSearchByCity('');
    } catch (error) {
      console.log('In onActionComplete error', error);
    }     
  }
  
  const getPeopleSearch = (usePrev?: boolean, city?: string, name?: string, skip?: string, take?: string) => {
    createSpinner({target: document.getElementById('spinner')!});
    showSpinner(document.getElementById('spinner')!);
    setSearchResults([]);
    setShowResults(false);
    setActivePage(1);

    let n, c, t: string | number | undefined;
    let searchString = '';
    let orderBy = orderValues.orderValue !== 'REORDERBY' ? `&orderBy=${orderValues.orderValue}` : '';

    t = 'take=999'; // if omitted API defaults to 100

    if (usePrev === true) {
      n = name === '' ? '' : `&name=${name}`;
      c = city === '' ? '' : `&city=${city}`;   
    } else {
      n = searchByName === '' ? '' : `&name=${searchByName}`;
      c = searchByCity === '' ? '' : `&city=${searchByCity}`;
    }
    searchString = `?${t}${n}${c}${orderBy}`;
  
    if ( window.location.pathname === '/people/barristers') searchString = `?PersonType=1${n}${c}${orderBy}&${t}`;

    const people = getPeople(searchString);
    
    people.then((people) => {      
      
      console.log('show spinner')
      try {
        // setSearchResults(people);
        if (people.length > 0 ) {
          setSearchResults(people);

          let search = {name: searchByName, city: searchByCity, noOfResults: searchResults.length};
          let prevSearches = toJS(getPreviousPersonSearches());

          if (!usePrev) setPreviousPersonSearches([{...search}, ...prevSearches]);

          pastSearches.current = toJS(getPreviousPersonSearches());

          if (pastSearches.current.length > 5 && !usePrev) {
            pastSearches.current.splice(-1); // remove last element

            setPreviousPersonSearches(pastSearches.current);
          }

          localStorage.setItem('searchPeopleHistory', JSON.stringify(people));
          
          if (localStorage.getItem('navstackSearchParams') === null && !usePrev) {
            // need to insert a uuid before saving to localStorage
            let searchArray = [{...search, id: uuid()}];
            localStorage.setItem('navstackSearchParams', JSON.stringify(searchArray));
          } else {
            if (!usePrev) {
              let prevSearch = JSON.parse((localStorage.getItem('navstackSearchParams')!));
              let addedSearch = [search, ...prevSearch];
    
              addedSearch = addedSearch.map(search => ({ ...search, id: uuid() }))
              localStorage.setItem('navstackSearchParams', JSON.stringify(addedSearch));
            }
          }

          setShowResults(true);
          onActionComplete();
        } else if (people.length === 0) {
          setSearchResults([{noResults: 'noResults'}]);
          setShowResults(false);
          onActionComplete();
        }
      } catch (error) {
       console.log('Error:', error);
       setSearchResults([{noResults: 'noResults'}]);
       hideSpinner(document.getElementById('spinner')!)
      }   
    });
  }

  const updateSearchByNameState = (e: any) => {
    let searchVal = e.target.value;
    setSearchByName(searchVal);
  }

  const updateSearchByCityState = (e: any) => {
    let searchVal = e.target.value;
    setSearchByCity(searchVal);
  }

  const pageSettings = {
    activePage: activePage,
    boundaryRange: 1,
    siblingRange: 0,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
    totalPages: Math.ceil(searchResults.length / 20),
  }

  const changePage = (e: any, data: any) => {
    setActivePage(data.activePage);
  }

  const loadSelectedBusinessProfile = async (id: string) => {
    await setSelectedBusinessOffices(id);
    await setSelectedBusinessHq(id);
    router.navigate('/business/' + id);
  };

  const loadSelectedPersonProfile = async (id: string) => {
    await setPersonProfile(id);
    router.navigate('/profile/' + id);
  };
  const handleKeypressSearch = (e:any) => {
    
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (e.key === 'Enter') {
        getPeopleSearch(false)
      }
    }
  }
  const year = (dateString: string) => {
    if(!dateString || dateString === '0001-01-01T00:00:00') {
      return ""
    }else{
      return dateString.substring(0, 4)
    }
    
  }

  return (
    <>
    <div className='b-container__scroller'>
      <div className="b-section__wrap b-search-page">
        <div className="b-section center">
          <div className="ui grid padded b-searchbar">
            <div className="sixteen wide column ">
              <div className='ui segment b-shadow b-border--radius-12'>
              <Form>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <input type="text" name="personName" value={searchByName} placeholder="name" onChange={updateSearchByNameState} onKeyPress= {(e) => { handleKeypressSearch(e); } }/>
                  </Form.Field>
                  <Form.Field> 
                    <input type="text" name="city" value={searchByCity} placeholder="city"  onChange={updateSearchByCityState} onKeyPress= {(e) => { handleKeypressSearch(e); }}/>
                  </Form.Field>
                    <button className="primary ui button" type='button' title="Advanced filters" onClick={(e) => { setShowFilter(true);e.preventDefault();return false; }}><Icon name="sliders horizontal" /><span className="ui button__text hidden">Filter</span></button>
                    <button className="secondary ui button" type="submit" onClick={() => getPeopleSearch(false)} ><Icon name="search" /><span className="ui button__text hidden">Search</span></button>
                </Form.Group>
              </Form>         
              </div>
            </div>
          </div>
          <div className="ui stackable three column grid b-result__grid b-grid--3">
            <div className="filter">
              <div className="filter__col">
                <Dropdown
                  text={orderValues.orderName}
                  icon='sort amount down'
                  floating
                  labeled
                  button
                  className='icon'
                  size="small"
                  onChange={onOrderChange}
                  options={orderOptions}
                >
                </Dropdown>
                {showResults ?  (
                  <Label as='a' title="Clear filter" onClick={removeSearchPeopleHistory}>
                            Clear Results   
                            <Icon name='delete' />
                  </Label>
                ): ("")
                }
              </div>
              <div className="filter__col">
              
                {showResults ?  (
                  <Label className="bg--none">
                  <Icon name='search' />
                  {`${searchResults.hasOwnProperty("noResults") ? '0' : searchResults.length} found`}
                  </Label>
                ) : ("")}
            
              </div>
            </div>
          </div>
          <div id="spinner" ></div>
          {showResults ? (
            <div className="ui stackable three column grid b-result__grid b-grid--3">
              {searchResults.map((person, index) => {
                let pageMin = activePage === 1 ? 0 : ((activePage - 1) * 10) * 2;
                let pageMax = pageMin + 19;

                if (index >= pageMin && index <= pageMax) {
                  return (
                    <div className="column" key={index}>
                      <div className="ui fluid card b-result person b-shadow">
                        <div className="content">
                          <span className="b-result__content">
                            <div className="header">
                              <h4>{`${person.FirstName} ${person.LastName}`}</h4>
                             
                              <h5>
                                 { person.DateOfCall && <span className="date">
                                  Call {person.YearOfCall}
                                  {/*{format( new Date(person.DateOfCall), 'YYYY')}*/}
                                </span>
                                }
                                { person.YearOfSilk && 
                                <span className="date">
                                  &nbsp;&nbsp;|&nbsp;  Silk {person.YearOfSilk}
                                </span>
                                }
                              </h5>
                              <div className="b-result__type">
                                <div className=
                                  {'ui mini circular label ' + 
                                    (person.PersonTypeCode === 0 ? 'primary' : '') + 
                                    (person.PersonTypeCode === 1 ? 'secondary' : '') + 
                                    (person.PersonTypeCode === 2 ? 'tertiary' : '')} >{(person.ClerkPostTitle) ? (person.ClerkPostTitle) : (person.PersonTypeCodeName)}
                                    </div>
                                 {/*<div className="ui mini circular label">Mediation</div>*/}
                              </div>
                            </div>
                            <div className="description">
                              <a  className='b-result__link' onClick={() => loadSelectedBusinessProfile(person.BusinessId)}>{person.BusinessName}</a>
                              {/* <Link to={`/business/${person.BusinessId}`}>{person.Business.Name}</Link> */}
                              <span className='b-result__location'>
                              &nbsp;<i className="map marker small icon"></i>
                              {person.City}</span>
                              <br></br>
                            </div>
                          </span>
                          <span className="b-result__avatar">
                            <span className="b-result__avatar-skew">
                              <span className="b-result__avatar-img"></span>
                            </span>
                          </span>
                        </div>
                        <div className="extra content">
                          <button className="ui small button float-right primary" onClick={() => loadSelectedPersonProfile(person.PersonId)}>View Profile</button>
                          
                         {person.Telephone && <Button className='icon' size='small' circular title={ person.Telephone } href={'tel:' + person.Telephone} ><Icon name='phone' /></Button> }
                      {person.Email && <Button className='icon' size='small' circular title={ person.Email } href={'mailto:' + person.Email}><Icon name='envelope' /></Button> }


                        </div>
                      </div>
                    </div>
                  );}
                })
              }       
            </div>
          ) : ("")}
              

              {showResults ?  ("") : (
                  <>
                    { pastSearches.current.length > 0 ? (
                      <>
                        <div className="b-result-previous__header">
                          <h4><Icon name='clock outline' />Recent</h4>
                        </div>
                      </>):(
                         <></>
                      )
                    }
                    
                    {
                      pastSearches.current.map((srch, index) => (
                        <div className="b-result-previous__item" key={index}>
                          <a onClick={() => {getPeopleSearch(true, srch.city, srch.name)}}><Icon name="search" size='small' />  
                          {srch.name} {srch.name && <span className='b-result-previous__add'>+</span> }
                          {srch.city} 
                          </a>
                        </div>         
                      ))
                    } 
                    
                  </>
                )}
                  {/*
                  <Label className="bg--none">
                    <Icon name='map marker alternate' size='large' /><Checkbox className='small' toggle  />
                  </Label>
                  */}

        </div>
        {showResults && (
            (pageSettings.totalPages > 1) && (
              <div className="b-pagination">
                <div className="b-pagination-controls" style={{width: 'fit-content'}}>
                  <Pagination
                    activePage={pageSettings.activePage}
                    siblingRange={pageSettings.siblingRange}
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{ content: <Icon name='angle left' />, icon: true, className: 'b-pagination-controls__back' }} 
                    nextItem={{ content: <Icon name='angle right' />, icon: true, className: 'b-pagination-controls__forward' }}
                    totalPages={pageSettings.totalPages}
                    onPageChange={changePage}
                  />
                </div>
              </div>
            )
          )}
          
      </div>
      </div>
    </>
  );
}

export default PeopleDashboard;
