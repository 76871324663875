import React, { useContext, Fragment, useRef, useState, useEffect } from 'react';
import { Icon, IconGroup, Step, Label, Card, Image, Grid, Form, Input, Radio, 
  Select, Checkbox, Button, TextArea, Transition, TransitionGroup, Dropdown
  
 } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { HtmlEditor, Image as EImage, Inject, Link as ELink, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
import Company from './Company';
import Location from './Location';
import Details from './Details';
import Profile from './Profile';
import { RootStoreContext } from '../../app/stores/rootStore';
import { router } from '../../app/router/Routes';

const MyDetails = () => {
  
  const rootStore = useContext(RootStoreContext);
	const { setRegistrationComplete } = rootStore.userStore;
  const title = [
    { key: 'm', text: 'Mr', value: 'mr' },
    { key: 'f', text: 'Mrs', value: 'mrs' },
    { key: 'ma', text: 'Master', value: 'master' },
    { key: 'ms', text: 'Ms', value: 'ms' },
    { key: 'dr', text: 'Dr', value: 'dr' },
    { key: 'p', text: 'Prof', value: 'prof' },
    { key: 'r', text: 'Rev', value: 'rev' },
    { key: 's', text: 'Sir', value: 'sir' },
    { key: 'Lo', text: 'Lord', value: 'lord' },
    { key: 'La', text: 'Lady', value: 'lady' },
    { key: 'his', text: 'His Honour', value: 'hishonour' },
    { key: 'her', text: 'Her Honour', value: 'herhonour' },
  ]
  const options = [
    { key: 'm', text: 'Male', value: 'male' },
    { key: 'f', text: 'Female', value: 'female' },
    { key: 'o', text: 'Other', value: 'other' },
  ]
  const areasOfLaw = [
    { key: 'fra', text: 'Civil Fraud', value: 'Civil Fraud' },
    { key: 'con', text: 'Construction & Engineering', value: 'Construction & Engineering' },
    { key: 'eng', text: 'Energy', value: 'Energy' }, 
    { key: 'fin', text: 'Financial Services', value: 'Financial Services' },
    { key: 'int', text: 'International Arbitration', value: 'International Arbitration' },
  ]
  // const isCurrentUser = true;
  // const rteForRecommends: any = useRef();
  const fullToolbar: any = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|',
        'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ],
    type: 'Expand',
    enable: false
  };
  const toggleToolbar = (rte: any, btn: any, section: string) => {
    if (!rteState.current) {
      rte.current.toolbarSettings.enable = true;
      rte.current.enabled = true;
      btn.current.innerText = 'Save'
      setCancelBtn(btn.current.name);
    } else {
      // setting/getting to & from localstorage will be replaced by API calls to persist profile data
      /*window.localStorage.setItem(section, rte.current.value);
      rte.current.toolbarSettings.enable = false;
      rte.current.enabled = false;
      //rte.current.spellCheck = false;
      btn.current.innerText = 'Edit';*/
      setCancelBtn('');
    }
    rteState.current = !rteState.current;
  }
  const [cancelBtn, setCancelBtn] = useState('');

  const rteState: any = useRef(false);
  const cancelUpdate = (rte: any, btn: any, section: string) => {
    /*rte.current.value = window.localStorage.getItem(section);
    rte.current.toolbarSettings.enable = false;
    rte.current.enabled = false;
    rte.current.spellCheck = false;
    btn.current.innerText = 'Edit';
    setCancelBtn('');
    rteState.current = !rteState.current;*/
  }

  const [stepTitle, setStepTitle]: any = useState('Verify Office Location');
  const currentPnlNum: any = useRef('0');
  const stepNext: any = useRef();
  const companyPnl: any = useRef();
  const locationPnl: any = useRef();
  const yourDetailsPnl: any = useRef();
  const yourProfilePnl: any = useRef();

  const slideNext = (e: any, checkPrevReg?: any) => {
    var currentRegStatus = JSON.parse(localStorage.getItem('registrationStatus')!);
    let currentPnl;

    // Initial component load i.e. after login
    if (checkPrevReg === 'yes') {
      currentPnl = currentPnlNum.current
    } else { 
      currentPnl = e.target.dataset.navigate;
    } 
      
    const pnlEnum: {[key: string]: string} = {
      '0': 'noneComplete',
      '1': 'confirmCompanyDtls',
      '2': 'selectOffice',
      '3': 'yourDtls',
      '4': 'yourProfile'
    }
  
    // we've pressed the stepNext button or component loaded for 1st time
    if(typeof currentPnl === 'undefined' || checkPrevReg === 'yes') {
      currentPnl = pnlEnum[currentPnlNum.current];
    }

    switch (currentPnl) {
     case 'noneComplete':
        // open companyPnl
        companyPnl.current.classList.remove('slide--out-right');
        currentPnlNum.current = '1';
        break;
      case 'confirmCompanyDtls':
        // close companyPnl & open locationPnl
        locationPnl.current.classList.remove('slide--out-right');
        companyPnl.current.classList.add('slide--out-back');
        currentPnlNum.current = '2'; // set to next pnl number
        setStepTitle('Confirm Your Details');
        document.getElementById('firstStep')!.classList.add('completed');
        document.getElementById('firstStep')!.classList.remove('active');
        document.getElementById('secondStep')!.classList.add('active');
        setCurrentStepComplete(currentRegStatus, 'company');
        break;
      case 'selectOffice':
        // close locationPnl & open yourDetailsPnl
        yourDetailsPnl.current.classList.remove('slide--out-right');
        companyPnl.current.classList.add('slide--out-back');
        locationPnl.current.classList.add('slide--out-back');
        currentPnlNum.current = '3'; // set to next pnl number
        setStepTitle('Update your profile');
        document.getElementById('firstStep')!.classList.add('completed');
        document.getElementById('firstStep')!.classList.remove('active');
        document.getElementById('secondStep')!.classList.add('active');
        document.getElementById('secondStep')!.classList.add('completed');
        document.getElementById('secondStep')!.classList.remove('active'); 
        document.getElementById('thirdStep')!.classList.add('active');
        setCurrentStepComplete(currentRegStatus, 'location');    
        break;
      case 'yourDtls':
         // close yourDetailsPnl & open yourProfilePnl
        yourProfilePnl.current.classList.remove('slide--out-right');
        yourDetailsPnl.current.classList.add('slide--out-back');
        companyPnl.current.classList.add('slide--out-back');
        locationPnl.current.classList.add('slide--out-back');
        currentPnlNum.current = '4'; // set to next pnl number
        setStepTitle('Continue to LEX Network');
        document.getElementById('firstStep')!.classList.add('completed');
        document.getElementById('firstStep')!.classList.remove('active');
        document.getElementById('secondStep')!.classList.add('completed');
        document.getElementById('secondStep')!.classList.remove('active');
        document.getElementById('thirdStep')!.classList.add('completed');
        document.getElementById('thirdStep')!.classList.remove('active');
        document.getElementById('fourthStep')!.classList.add('active');
        setCurrentStepComplete(currentRegStatus, 'yourDtls');
        break;
      case 'yourProfile':
        setCurrentStepComplete(currentRegStatus, 'yourProfile');
        setRegistrationComplete(true);
        console.log('In MyDetails - switch (yourProfile)');
        router.navigate('/chambers');
        break;
    
      default:
        console.log('we shouldnt be here - navigation gone wrong!');
        break;
    }
  }

  const setCurrentStepComplete = (currentRegStat: any, step: any) => {
    currentRegStat[step] = 'complete';
    localStorage.setItem('registrationStatus', JSON.stringify(currentRegStat));
  }
  
  const slideBack = () => {
    companyPnl.current.classList.remove('slide--out-right', 'slide--out-back');
    locationPnl.current.classList.add('slide--out-right');
    locationPnl.current.classList.remove('slide--out-back');
    yourProfilePnl.current.classList.add('slide--out-right');
    yourDetailsPnl.current.classList.add('slide--out-right');
    yourDetailsPnl.current.classList.remove('slide--out-back');
    document.getElementById('firstStep')!.classList.remove('completed');
    document.getElementById('firstStep')!.classList.add('active');
    document.getElementById('secondStep')!.classList.remove('completed');
    document.getElementById('secondStep')!.classList.remove('active');
    document.getElementById('thirdStep')!.classList.remove('completed');
    document.getElementById('thirdStep')!.classList.remove('active');
    document.getElementById('fourthStep')!.classList.remove('active');
    setStepTitle('Verify Office Location');
    currentPnlNum.current = '1';

    let registrationStatus = {'company': '', 'location': '', 'yourDtls': '', 'yourProfile': ''};
    localStorage.setItem('registrationStatus', JSON.stringify(registrationStatus));  
  }

  useEffect(() => {
    let regStat = JSON.parse(localStorage.getItem('registrationStatus')!);

    if (regStat.yourProfile === 'complete') {
      currentPnlNum.current = '4';
    } else if (regStat.yourDtls === 'complete') {
      currentPnlNum.current = '3';
    } else if (regStat.location === 'complete') {
      currentPnlNum.current = '2'; 
    } else  if (regStat.company === 'complete') {
      currentPnlNum.current = '1';
    }

    slideNext(null, 'yes');
  }, []);

  return (
    <Fragment>
        <div className="b-guide__wrap fullscreen" >
            <div className="b-guide__steps">
              <Step.Group unstackable fluid widths={5}>
                  {/*}
                  <Step className="next">
                    <button ref={stepNext}  onClick={() => slideBack()} className="step__button">
                      <Icon size="big" name='arrow left' />
                      <Step.Content>
                          <Step.Description>Back</Step.Description>
                          <Step.Title>Temp back to start...</Step.Title>
                      </Step.Content>
                    </button>
                  </Step> */}
                  <Step id='firstStep' active>
                    <Icon.Group size='big'>
                      <Icon name='building'  />
                      <Icon corner name='check circle' />
                    </Icon.Group>
                    <Step.Content>
                        <Step.Title>Company</Step.Title>
                        <Step.Description>Verify your company details</Step.Description>
                    </Step.Content>
                    </Step>
                  <Step id='secondStep'>
                    <Icon.Group size='big'>
                    <Icon name='map marker alternate'  />
                    <Icon corner name='check circle' />
                    </Icon.Group>
                    <Step.Content>
                        <Step.Title>Office Location</Step.Title>
                        <Step.Description>Verify your office location</Step.Description>
                    </Step.Content>
                  </Step>
                  <Step id='thirdStep'>
                    <Icon.Group size='big'>
                    <Icon name='user circle'  />
                    <Icon corner name='check circle' />
                    </Icon.Group>
                    <Step.Content>
                        <Step.Title>Your Details</Step.Title>
                        <Step.Description>Confirm your details</Step.Description>
                    </Step.Content>
                  </Step>
                  
                  <Step id='fourthStep'>
                    <Icon.Group size='big'>
                      <Icon name='id card'  />
                      <Icon corner name='check circle' />
                    </Icon.Group>
                    <Step.Content>
                        <Step.Title>Your Profile</Step.Title>
                        <Step.Description>Enter your directory profile</Step.Description>
                    </Step.Content>
                  </Step>

                  <Step className="next">
                    <button ref={stepNext} onClick={(e) => slideNext(e)} className="step__button">
                      <Icon size="big" name='arrow right' />
                      <Step.Content>
                          <Step.Description>NEXT</Step.Description>
                          <Step.Title>{stepTitle}</Step.Title>
                      </Step.Content>
                    </button>
                  </Step>
              </Step.Group>
            </div>
            <div className="b-guide__content">
              <div className="b-guide__slider">
              <div className="b-slidedeck__container">
                <div className="b-slidedeck__wrap b-slidedeck__wrap--slide-indv">
                  {/*slidedeck_pane1 START*/ }
                  <div ref={companyPnl} className="b-slidedeck__pane slide--out-right"> {/* css classes - slide--out-right,  slide--out-back */}
                    <Company slideNext={(e) => slideNext(e)} />
                  </div>
                  {/*slidedeck_pane1 END*/ }
                  {/*slidedeck_pane2 START*/ }
                  <div ref={locationPnl} className="b-slidedeck__pane slide--out-right"> {/* css classes - slide--out-right,  slide--out-back */}
                    <Location  slideNext={(e) => slideNext(e)}  />
                  </div>
                  {/*slidedeck_pane2 END*/ }
                  {/*slidedeck_pane3 START*/ }
                  <div ref={yourDetailsPnl} className="b-slidedeck__pane slide--out-right"> {/* css classes - slide--out-right,  slide--out-back */}
                    <Details title={title} />
                  </div> 
                  {/*slidedeck_pane3 END*/ }
                  {/*slidedeck_pane4 START*/ }
                  <div ref={yourProfilePnl} className="b-slidedeck__pane  slide--out-right"> {/* css classes - ,  slide--out-back */}
                    <Profile toggleToolbar={toggleToolbar} cancelBtn={cancelBtn} cancelUpdate={cancelUpdate} isCurrentUser areasOfLaw={areasOfLaw} />
                  </div>
                  {/*slidedeck_pane4 END*/ }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyDetails;