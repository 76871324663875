import { observable, makeObservable, computed, action, runInAction, toJS } from 'mobx';
import { ISetpasswordFormValues, IUser, IUserFormValues, IUserValidationFormValues } from '../models/user';
import agent from '../api/agent';
import { RootStore } from './rootStore';
import { IContact } from '../models/contact';
import { router } from '../router/Routes';

export default class UserStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeObservable(this, {
      user: observable,
      loggedInUser: observable,
      userContacts: observable,
      appToken: observable,
      userAction: observable,
      TwoFactorConfirmationToken: observable,
      Email: observable,
      foundResetEmail: observable,
      validTwoFactorCode: observable,
      openUnconfirmedEmailModal: observable,
      registrationComplete: observable,
      userPermissions: observable,
      setPasswordResponse: observable,
      isLoggedIn: computed,
      login: action,
      register: action,
      logout: action,
      getAppToken: action,
      resetPassword: action,
      resendEmail: action,
      getUserPermissions: action
    });

    this.rootStore = rootStore;
  }

  user: IUser | null = null;
  userAction: string | null = 'userLogin';
  loggedInUser: IUser | null = null;
  userContacts: IContact | null = null;
  appToken: object | null = null;
  TwoFactorConfirmationToken: string | undefined = '';
  Email: string | undefined = '';
  foundResetEmail: boolean = false;
  validTwoFactorCode: boolean | null = null;
  openUnconfirmedEmailModal: boolean | null = null;
  registrationComplete: boolean = false;
  userPermissions = [];
  setPasswordResponse: number | null | undefined;

  get isLoggedIn() {
    // return as a boolean
    return !!this.user;
  }

  getUserPermissions = () => {
    // console.log('in getUserPermissions')
    return this.userPermissions;
  }

  getAppToken = async () => {
    try {
      // appToken stills required for GraphQL api calls
      const appToken = await agent.User.getAppToken(process.env.REACT_APP_NAME!, process.env.REACT_APP_VALUE!);
      runInAction(() => {
        this.appToken = appToken;
        localStorage.setItem('appToken', appToken.Token);
      });
    } catch (error) {
      console.log('Unable to get app token');
    }
  }

  login = async (values: IUserFormValues) => {
    try {
      const user = await agent.User.login(values);

      runInAction(() => {
        if (user.Code === 3004) { // 3004 - we have user info
          // console.log('User info', user.User!.TwoFactorConfirmationToken)
          this.TwoFactorConfirmationToken = user.User!.TwoFactorConfirmationToken;
          this.Email = user.User!.Email;
          this.userAction = 'LoginRequiresTwoFactorViaEmail';
        } else if (user.Code === 3001) { // loginOK - no two factor code required
          alert('No two factor verification required on this user account !?!');
        } else if (user.Code === 9004) { // EmailAddressIsUnconfirmed - email needs confirming
          this.openUnconfirmedEmailModal = true;
        }
      });

      this.rootStore.modalStore.closeModal();

      // this should happen only once i.e. when user logs in for 1st time
      if (!window.localStorage.getItem('registrationStatus')) {
        let registrationStatus = {'company': '', 'location': '', 'yourDtls': '', 'yourProfile': '', 'LEXProfileCheck': ''};

        localStorage.setItem('registrationStatus', JSON.stringify(registrationStatus));        
      }

      // remove any failed login attempts from localstorage.
      window.localStorage.removeItem('loginFailTime');
      window.localStorage.removeItem('loginFailCount');
    } catch (error) {
      console.error('login error', error)
      throw error;
    }
  };

  loginStepTwo = async (values: IUserValidationFormValues) => {
    console.log('inside userStore loginStepTwo')
    try {
      const user = await agent.User.loginStepTwo(values);
      const opts = await agent.User.getUserOptions(user!.User!.Id)
      runInAction(() => {
        this.user = user;
        this.loggedInUser = user;
        // const opts = agent.User.getUserOptions(user.User.Id);

        if (user.Code === 9012) { // twoFactorCode wrong or expired
          this.userAction = 'LoginRequiresTwoFactorViaEmail'; 
          this.validTwoFactorCode = false;
        } else if (user.Code === 3001) { // happy path

          // load user options
          this.userPermissions =  opts;
          // console.log('opts:::::: ', opts);
          // console.log('REACT_APP_API_URL: ', process.env.REACT_APP_API_URL);
          // console.log('REACT_APP_CAN_SIGN_UP: ', process.env.REACT_APP_CAN_SIGN_UP);

          this.rootStore.commonStore.setToken(user.Token);
          this.validTwoFactorCode = true;
          this.rootStore.modalStore.closeModal();
   
          this.rootStore.solicitorStore.setSraOffices();

          if (!window.localStorage.getItem('registrationStatus')) {
            let registrationStatus = {'company': '', 'location': '', 'yourDtls': '', 'yourProfile': ''};
  
            localStorage.setItem('registrationStatus', JSON.stringify(registrationStatus));
          }
  
          let currentRegStatus = JSON.parse(localStorage.getItem('registrationStatus')!);
  
          if (currentRegStatus.yourProfile === 'complete') {
            if (!window.localStorage.getItem('sortableList')) {
              localStorage.setItem('sortableList', JSON.stringify([]));
            }
            this.registrationComplete = true;
            router.navigate('/chambers', {replace: true});
          }
          else if((currentRegStatus.LEXProfileCheck !== 'complete')) { // do we ever get here?
            console.log('In userStore loginStepTwo - about to history.replace to /LEXProfileCheck')
            router.navigate('/LEXProfileCheck');
          } 
          else { // do we ever get here?
            console.log('In userStore loginStepTwo - about to history.replace to /mydetails')
            router.navigate('/mydetails');
          }
        } else {
          console.log('Something unexpected happened user = ', user);
        }
      });
    } catch (error) {
      console.error('login step two error', error)
      throw error;
    }
  };

  register = async (values: IUserFormValues) => {
    try {
      const user = await agent.User.register(values);
      runInAction(() => {      
        if (user.Code === 1002) { // new user as to confirm email
          const dataForResendingEmail = {
            Email: values.Email,
            ReturnUrl: values.ReturnUrl
          }
          this.setUserAction('AwaitingEmailConfirmation');
          localStorage.setItem('newUserEmailToBeConfirmed', values.Email);
          localStorage.setItem('resendVerificationEmailData', JSON.stringify(dataForResendingEmail));
        } else if (user.Code === 9003) { // user already exists
          this.setUserAction('userAlreadyExists');
        }
      });
      // history.push('/chambers')
    } catch (error) {
      throw error;
    }
  };

  resendEmail = async (values: IUserFormValues) => {
    try {
      await agent.User.resendEmail(values);
    } catch (error) {
      throw error;
    }
  }

  logout = () => {
    localStorage.removeItem('resendVerificationEmailData');
    this.setUserAction('userLogin');
    this.rootStore.commonStore.setToken(null);
    //set timeout for transition
    //setTimeout(() => { this.user = null }, 3000);
    // this.userPermissions = [];
    this.user = null;
    router.navigate('/');
  };

  setUserAction = (act: string) => {
    this.userAction = act;
  };

  setOpenUnconfirmedEmailModal = (status: boolean) => {
    this.openUnconfirmedEmailModal = status;
  };
  setRegistrationComplete = (status: boolean) => {
    this.registrationComplete = status;
  };

  resetPassword = async (email: IUserFormValues) => {
    try {
      var response = await agent.User.resetPassword(email);
      runInAction(() => {      
        if (response.Code === 2001) { // confirm email
          console.log('Password reset email sent');
          this.foundResetEmail = true;
          this.setUserAction('userLogin');
          localStorage.setItem('passwordResetMsg', `A password reset link has been sent to email address ${email.Email}.`);
        } else if (response.Code === 9002) { // can't find user with that email
          console.log('Response', response);
          this.foundResetEmail = false;
          localStorage.setItem('passwordResetMsg', `Cannot find a user associated with the email address ${email.Email}.`);
        }
      });  
    } catch (error) {
      console.log('Password reset error:', error);
    }  
  }

  setImage = (image: string) => {
    if(this.user) this.user.image = image;
  }

  getImage = () => {
    return this.user!.image;
  }

  setPassword = async (user: ISetpasswordFormValues) => {
    try {
      var response = await agent.User.setPassword(user);
      runInAction(() => {
        console.log('response.Code for setPassword: ', response.Code);
        if (response.Code === 2004) { // set password complete
          this.setSetPasswordResponse(2004);
        } else if (response.Code === 9002) { // can't find user so unable to set password
          this.setSetPasswordResponse(9002);
        } else {
          this.setSetPasswordResponse(response.Code);
        }
      });  
    } catch (error) {
      console.log('Set password error:', error);
    }
  }

  setSetPasswordResponse = (act: number) => {
    this.setPasswordResponse = act;
  };  
}
