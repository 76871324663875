import React, { Fragment, useContext, useEffect, MouseEvent, useState } from 'react';
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from '@syncfusion/ej2-react-richtexteditor';
// import { InPlaceEditorComponent } from "@syncfusion/ej2-react-inplace-editor";

import { Grid, Segment, Icon, Label, Card, Button, Tab } from 'semantic-ui-react';
// import ProfileHeader from './ProfileHeader';
// import ProfileContent from './ProfileContent';
import { useParams } from 'react-router';
import { RootStoreContext } from '../../app/stores/rootStore';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import ProfileStats from './ProfileStats';
import ProfileCases from './ProfileCases';
import ProfileModal from './modal/ProfileModal';
import useOnScreen from '../../app/common/util/useOnScreen'
import { toJS } from 'mobx';
import { toast } from 'react-toastify';
import { router } from '../../app/router/Routes';

interface RouteParams {
  username: string;
}

// interface IProps extends RouteComponentProps<RouteParams> { }

// const ProfilePage: React.FC<IProps> = ({ match }) => {
const ProfilePage: React.FC<RouteParams> = () => {
  const {username} = useParams();
  const rootStore = useContext(RootStoreContext);
  const{ openModal } = rootStore.modalStore;
  const { loadingProfile, loadProfile, isCurrentUser, getProfile } = rootStore.profileStore;
  const{ getPersonProfile } = rootStore.personStore;
  const { setSelectedBusinessOffices, setSelectedBusinessHq } = rootStore.businessStore;
  const{ getImage, getUserPermissions } = rootStore.userStore;
  const{ getPersonActiveTab, setPersonActiveTab } = rootStore.commonStore;

  // Using localstorage here until API functionality exists
  const profileImage = localStorage.getItem('profileImage') !== null && isCurrentUser ? 
    JSON.parse(localStorage.getItem('profileImage')!) :
    process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png';

  const [hideLaw, setHideLaw] = useState(true);
  const [isStickyHeader, setIsStickyHeader] = useState(false);
  // const [btnHeaderText, setBtnHeaderText] = useState('Edit');
  const [cancelBtn, setCancelBtn] = useState('');
  const [showFacebook, setShowFacebook] = useState(false);
  const [showTwitter, setShowTwitter] = useState(false);
  const [showLinkedin, setShowLinkedin] = useState(false);
  const [isActive, setActive] = useState(false);
  const [profile, setProfile]: any = useState(getPersonProfile());
  const [storedImg, setStoredImg]: any = useState(getImage());

  const rteForHeader: any = useRef();
  const rteForRecommends: any = useRef();
  const rteForAreaOfLaw: any = useRef();
  const rteState: any = useRef(false);
  const btnEditHead: any = useRef();
  const btnCancelHead: any = useRef();
  const btnEditRec: any = useRef();
  const btnCancelRec: any = useRef();
  const btnEditAol: any = useRef();
  const btnCancelAol: any = useRef();
  const ipeEmail: any = useRef();
  const ipePhone: any = useRef();
  const ipeTenancy: any = useRef();
  const activePage: any = useRef(getPersonActiveTab());
  const ipeFacebook: any = useRef();
  const ipeTwitter: any = useRef();
  const ipeLinkedin: any = useRef();
  const profileDataObj = {
    introText: '',
    recommendations: '',
    emailAddr: '',
    contactNo: '',
    tenancy: '',
    facebookLink: '',
    linkedInLink: '',
    twitterLink: ''
  };  

  const {id} = useParams<{id: string}>();

  const options = toJS(getUserPermissions());

  let profileOpts: any = options.filter(obj => {
      return obj['LocationName'] === 'Profile';
    }
  );

  profileOpts = profileOpts.map((opt: any) => opt.SystemOptionName);

  useEffect(() => {
    setProfile(getPersonProfile());
    // loadProfile(match.params.username);  
    loadProfile(username || '');
    getImage();

    if (localStorage.getItem('profileData') === null) {
      localStorage.setItem('profileData', JSON.stringify(profileDataObj));
      localStorage.setItem('prevProfileData', JSON.stringify(profileDataObj));
      setStoredImg(profileImage)
    } else {
      localStorage.setItem('prevProfileData', localStorage.getItem('profileData')!);
      setStoredImg(getImage());
    }
  }, [loadProfile, username, id, profileImage, storedImg, getImage]);

  const fullToolbar: any = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|',
        'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|',
        'CreateLink', 'Image', '|', 'ClearFormat', 'Print',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
    ],
    type: 'Expand',
    enable: false
  };

  // const insertImageSettings = {
  //   saveUrl: 'https://lex-network-style-guide.azurewebsites.net/assets/profileImages'
  // };

  const toggleToolbar = (rte: any, btn: any, section: string) => {
    if (!rteState.current) {
      rte.current.toolbarSettings.enable = true;
      rte.current.enabled = true;
      btn.current.innerText = 'Save'
      setCancelBtn(btn.current.name);
    } else {
      // setting/getting to & from localstorage will be replaced by API calls to persist profile data
      window.localStorage.setItem(section, rte.current.value);
      rte.current.toolbarSettings.enable = false;
      rte.current.enabled = false;
      rte.current.spellCheck = false;
      btn.current.innerText = 'Edit';
      setCancelBtn('');
    }
    rteState.current = !rteState.current;
  }

  const cancelUpdate = (rte: any, btn: any, section: string) => {
    rte.current.value = window.localStorage.getItem(section);
    rte.current.toolbarSettings.enable = false;
    rte.current.enabled = false;
    rte.current.spellCheck = false;
    btn.current.innerText = 'Edit';
    setCancelBtn('');
    rteState.current = !rteState.current;
  }

  // const toggleShowFacebook = () => { setShowFacebook(!showFacebook); }
  // const toggleShowTwitter = () => { setShowTwitter(!showTwitter); }
  // const toggleShowLinkedin = () => { setShowLinkedin(!showLinkedin); }
  // const saveEmail = () => { window.localStorage.setItem('profileEmail', ipeEmail.current.value); }
  // const saveTenancy = () => { window.localStorage.setItem('profileTenancy', ipeTenancy.current.value); }
  // const savePhone = () => { window.localStorage.setItem('profilePhone', ipePhone.current.value); }
  // const saveSocialMedia = (ipe: any, mediaType: string) => {
  //   window.localStorage.setItem(mediaType, ipe.current.value);
  //   if (mediaType === 'profileFacebook') setShowFacebook(false);
  //   if (mediaType === 'profileTwitter') setShowTwitter(false);
  //   if (mediaType === 'profileLinkedin') setShowLinkedin(false);
  // }

  const openProfileModal = () => {
    openModal(<ProfileModal userId={id} />, 'large');
  }

  const validationEmail = {Email: {required: true, email: true}};
  const validationPhone = {Phone: {required: true, number: true}};
  const validationTenancy = {Tenancy: {required: true}};
  const facebookContent = window.localStorage.getItem('profileFacebook');
  const twitterContent = window.localStorage.getItem('profileTwitter');
  const linkedinContent = window.localStorage.getItem('profileLinkedin');
  
  const masthead: any = useRef<HTMLDivElement>();

  const isMastheadInViewport: boolean = useOnScreen<HTMLDivElement>(masthead, "0px", true);

  const loadSelectedBusinessProfile = async (e: any, id: string) => {
    e.preventDefault();
    await setSelectedBusinessOffices(id);
    await setSelectedBusinessHq(id);
    router.navigate('/business/' + id);
  };

  const tabChange = (e: any, data: any) => {
    setPersonActiveTab(data.activeIndex);
  }
  const panes = [ 
    { menuItem: 'Profile', render: () => 
        <Tab.Pane>

        <Grid className="container margin-fix" textAlign="center">
          { profile.PersonTypeCode === 1 &&
          <Grid.Row columns={16}>
          <Grid.Column width="16">
            <Segment textAlign="center" className="b-shadow b-border--none">
                  { isCurrentUser && (
                    <button className="ui secondary icon button" name='profileHeader' ref={btnEditHead} onClick={(e) => toggleToolbar(rteForHeader, btnEditHead, 'profileHeader')} >
                      Edit
                    </button>
                  )}
                  { cancelBtn === 'profileHeader' && isCurrentUser && ( // can't pass btnCancelHead ref as a param to cancelUpdate because its null at this point 
                    <button className="ui icon button" ref={btnCancelHead} onClick={() => cancelUpdate(rteForHeader, btnEditHead, 'profileHeader')}>
                      Cancel
                    </button>
                  )}
                  <div className="ui hidden divider"></div>
                  <RichTextEditorComponent ref={rteForHeader} toolbarSettings={fullToolbar} value={window.localStorage.getItem('profileHeader')!} enabled={false} inlineMode={{enable: false, onSelection: true}} id="toolsRTE">
                    <Inject services={[HtmlEditor, Image, Link, QuickToolbar, RichTextEditorComponent, Toolbar]}/>
                  </RichTextEditorComponent>{profile.CareerSummary}
                  {/*profile.BarristerCircuits*/}
            </Segment>
          </Grid.Column>
          </Grid.Row>
          }
          <Grid.Row>
            <Grid.Column className="sixteen wide mobile ten wide tablet eleven wide computer column">
              { profile.PersonTypeCode === 1 ? 
              <Segment textAlign="left" className="b-shadow b-border--none">
              { isCurrentUser && (
                <button className="ui secondary icon button" name='profileRecommends' ref={btnEditRec} onClick={(e) => toggleToolbar(rteForRecommends, btnEditRec, 'profileRecommends')} >
                  Edit
                </button>
              )}
              { cancelBtn === 'profileRecommends' && isCurrentUser &&  ( // can't pass btnCancelRec ref as a param to cancelUpdate because its null at this point 
                <button className="ui icon button" ref={btnCancelRec} onClick={() => cancelUpdate(rteForRecommends, btnEditRec, 'profileRecommends')}>
                  Cancel
                </button>
              )}

                <h3>Recommendations</h3>
                <RichTextEditorComponent ref={rteForRecommends} toolbarSettings={fullToolbar} value={window.localStorage.getItem('profileRecommends')!} enabled={false} inlineMode={{enable: false, onSelection: false}} id="toolsRTE">
                  <Inject services={[HtmlEditor, Image, Link, QuickToolbar, RichTextEditorComponent, Toolbar]}/>
                </RichTextEditorComponent>
              </Segment>
              : 

              <Segment textAlign="center" className="b-shadow b-border--none">
              { isCurrentUser && (
                <button className="ui secondary icon button" name='profileHeader' ref={btnEditHead} onClick={(e) => toggleToolbar(rteForHeader, btnEditHead, 'profileHeader')} >
                  Edit
                </button>
              )}
              { cancelBtn === 'profileHeader' && isCurrentUser && ( // can't pass btnCancelHead ref as a param to cancelUpdate because its null at this point 
                <button className="ui icon button" ref={btnCancelHead} onClick={() => cancelUpdate(rteForHeader, btnEditHead, 'profileHeader')}>
                  Cancel
                </button>
              )}
              <div className="ui hidden divider"></div>
              <RichTextEditorComponent ref={rteForHeader} toolbarSettings={fullToolbar} value={window.localStorage.getItem('profileHeader')!} enabled={false} inlineMode={{enable: false, onSelection: true}} id="toolsRTE">
                <Inject services={[HtmlEditor, Image, Link, QuickToolbar, RichTextEditorComponent, Toolbar]}/>
              </RichTextEditorComponent>
        </Segment>
              
              
              }

              { profile.PersonTypeCode === 1 &&
              <Segment textAlign="left" className="b-shadow b-border--none">
                { isCurrentUser && (
                  <button className="ui secondary icon button" name='profileAreaOfLaw' ref={btnEditAol} onClick={(e) => toggleToolbar(rteForAreaOfLaw, btnEditAol, 'profileAreaOfLaw')} >
                    Edit
                  </button>
                )}
                { cancelBtn === 'profileAreaOfLaw' && isCurrentUser && ( // can't pass btnCancelAol ref as a param to cancelUpdate because its null at this point 
                  <button className="ui icon button" ref={btnCancelAol} onClick={() => cancelUpdate(rteForAreaOfLaw, btnEditAol, 'profileAreaOfLaw')}>
                    Cancel
                  </button>
                )}
                  <h3 className={`b-accordion-target ${!hideLaw ? '' : 'b-accordion-active'}`} onClick={() => setHideLaw(!hideLaw)}>Area of law</h3>
                  {/* <p className={ !hideLaw ? '' : 'b-accordion-show'}> */}
                  <RichTextEditorComponent ref={rteForAreaOfLaw} /* insertImageSettings={insertImageSettings} */ toolbarSettings={fullToolbar} value={window.localStorage.getItem('profileAreaOfLaw')!} enabled={false} inlineMode={{enable: false, onSelection: false}} id="toolsRTE">
                    <Inject services={[HtmlEditor, Image, Link, QuickToolbar, RichTextEditorComponent, Toolbar]}/>
                  </RichTextEditorComponent>
                  {/* </p> */}
              </Segment>
            }
            </Grid.Column>
            
            <Grid.Column className="sixteen wide mobile six wide tablet five wide computer column">
              <Segment textAlign="left" className="b-shadow b-border--none b-list__wrap primary spacing-x15">
                <dl className="b-list links">
                  
                  { <dd className="b-list__item"><a className="b-list__link" href={`tel: ${profile.Telephone} `} title="Phone" ><Icon className="phone" /><span className="b-list__text  no-wrap">{profile.Telephone}</span></a></dd> }
                  {/*<dd className="b-list__item"><a className="b-list__link" href="#" title="Mobile"><Icon className="mobile alternate" /><span className="b-list__text  no-wrap">+44 (0)7739 999 555</span></a></dd> */}
                  { profile.Email && <dd className="b-list__item"><a className="b-list__link" href={'mailto:' + profile.Email}  title="Email"><Icon className="envelope" /><span className="b-list__text  no-wrap">Email {profile.FirstName}</span></a></dd> }
                  { profile.SecureEmail &&
                  <dd className="b-list__item"><a className="b-list__link" href={'mailto:' + profile.SecureEmail} title="Secure Email">
                  <Icon.Group>
                    <Icon name='envelope' />
                    <Icon corner='bottom right' name='lock' />
                  </Icon.Group>
                  <span className="b-list__text no-wrap">Secure Email {profile.FirstName}</span></a></dd>
                  }
                  <dd className="b-list__item"><a className="b-list__link" href=" " onClick={(e) => {navigator.clipboard.writeText(`${profile.BusinessName}

                    ${profile.City}`

                    ); toast("Address copied to clipboard", {autoClose: 2500}); e.preventDefault();return false;}} title="Copy to Clipboard">
                    <Icon className="map marker alternate" />
                    <span className="b-list__text">{profile.BusinessName}<br />
                    {/* {profile.Business.AddressLine1}<br/> */}
                    {profile.City}<br/>
                    {/* {profile.Business.Postcode}</span></a> */}</span></a>
                  </dd>

                  {/* { profile.Business.Dx &&  <dd className="b-list__item"><a className="b-list__link" href=" " onClick={(e) => {navigator.clipboard.writeText(`DX: ${profile.Business.Dx}`); toast("DX copied to clipboard", {autoClose: 2500}); e.preventDefault();return false;}} title="Copy to Clipboard"><Icon className="box" /><span className="b-list__text  no-wrap">{profile.Business.Dx}</span></a></dd>} */}

                </dl>
              </Segment>
              { profile.PersonTypeCode === 0 &&
              <Segment textAlign="left" className="b-shadow b-border--none spacing-x15">
              <h2>Clerking Team</h2>
                <dl className="b-list-people compact">
                  <dd className="b-list-people__item">
                    <a href="#" className="b-list-people__link" title="Open Profile">
                      <span className="b-list-people__info">
                        <span className="b-list-people__name">Justin Elliott Neal</span>
                        <span className="b-list-people__job">Lead Civil Clerk</span>
                      </span>
                    </a>
                    <span className="b-list-people__icons">
                      <Button className="circular tiny icon" title="Email: Justin@barsquared.com"><Icon className="icon envelope" /></Button>
                      <Button className="circular tiny icon" title="Call: +442087776666"><Icon className="icon phone" /></Button>
                    </span>
                  </dd>
                  <dd className="b-list-people__item">
                    <a href="#" className="b-list-people__link" title="Open Profile">
                      <span className="b-list-people__info">
                        <span className="b-list-people__name">Mark Soos</span>
                        <span className="b-list-people__job">Civil Clerk</span>
                      </span>
                    </a>
                    <span className="b-list-people__icons">
                      <Button className="circular tiny icon" title="Email: Justin@barsquared.com"><Icon className="icon envelope" /></Button>
                      <Button className="circular tiny icon" title="Call: +442087776666"><Icon className="icon phone" /></Button>
                    </span>
                  </dd>
                  <dd className="b-list-people__item">
                    <a href="#" className="b-list-people__link" title="Open Profile">
                      <span className="b-list-people__info">
                        <span className="b-list-people__name">Damein Coaten</span>
                        <span className="b-list-people__job">Civil Clerk</span>
                      </span>
                    </a>
                    <span className="b-list-people__icons">
                      
                      <Button className="circular tiny icon" title="Email: Justin@barsquared.com"><Icon className="icon envelope" /></Button>
                      <Button className="circular tiny icon" title="Call: +442087776666"><Icon className="icon phone" /></Button>
                    </span>
                  </dd>
                </dl>
              
              </Segment> 
              }
              
               { (profile.PersonTypeCode < 2 && profile.BarristerCircuits && profile.BarristerCircuits.length > 0) &&
              <Segment textAlign="left" className="b-shadow b-border--none spacing-x15">
                <h2>Circuits</h2>
                <p>{profile.BarristerCircuits}</p>
              </Segment>
              }
              { (profile.Languagesspoken && profile.Languagesspoken.length > 0) &&
              <> 
                <Segment textAlign="left" className="b-shadow b-border--none spacing-x15">
                <h2>Languages</h2>
                {profile.Languagesspoken.map((lang: string, index: React.Key) => {
                  return <div key={index}>{lang}</div>;
                })}
                
              </Segment> 
              </>
              }
              {profile.PersonTypeCode < 2 &&
              <Segment textAlign="left" className="b-border--none b-bg--none spacing-x15">
                <div className="b-award__wrap small">
                  
                  <div className="b-award">
                    <img src="/assets/images/awards/the-legal-500__2021__top-tier-set__black.svg" alt='Legal 500' />
                  </div>
                  <div className="b-award">
                    <img src="/assets/images/awards/the-legal-500__2021__leading-set__black.svg" alt='Legal 500'  />
                  </div>
                  <div className="b-award">
                    <img src="/assets/images/awards/chambers-and-partners__2020__shortlisted-uk-bar.svg" alt='Chambers and Partners - Shortlisted UK Bar'  />
                  </div>
                </div>
              </Segment>
            }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Tab.Pane> 
    },
    { menuItem: 'Cases', render: () => <Tab.Pane><ProfileCases /></Tab.Pane> },
    { menuItem: 'Stats', render: () => <Tab.Pane><ProfileStats /></Tab.Pane> },
  ]

  const panesToShow = panes.filter(pane => profileOpts.includes(pane.menuItem));


  if (loadingProfile) return <LoadingComponent content='Loading profile...' />;

  return (
    <Fragment>
    
    <div className='b-container__scroller'>
    <div className={'b-sticky-header__wrapper ' + (isMastheadInViewport ? '' : 'isSticky')}>
      <div className="b-sticky-header">
        <div className="b-sticky-header__row">
          <div className="b-sticky-header__col b-sticky-header__col--avatar">
            <span className="e-avatar e-avatar-circle e-avatar-large" style={{ backgroundImage: `url(${profileImage.imgUrl ? profileImage.imgUrl : profileImage})` }}></span>
          </div>
          <div className="b-sticky-header__col">
            <h3>{profile.FullName}</h3>
            <Label size="tiny" circular className={
              (profile.PersonTypeCode === 0 ? 'primary' : '') + 
              (profile.PersonTypeCode === 1 ? 'secondary' : '') + 
              (profile.PersonTypeCode === 2 ? 'tertiary' : '')}>{(profile.ClerkPostTitle) ? (profile.ClerkPostTitle) : (profile.PersonTypeCodeName)}
            </Label>
            <div className="meta">
              <span className="card__hero-location"><a href="#" onClick={(e) => loadSelectedBusinessProfile(e, profile.BusinessId) }></a> <Icon name="map marker alternate" /> <a href="#"> (HQ)</a></span>
            </div>    
          </div>

          <div className="b-sticky-header__col right">
              <div className="card__hero-buttons">
                  {profile.Telephone && <Button size="small" className="primary" href={`tel: ${profile.Telephone} `} >Call</Button>}
                  {profile.Email && <Button size="small" inverted className="primary" href={`mailto:${profile.Email}`}>Email</Button>}
                </div>
              </div>
          </div>
      </div>
    </div>
    <div className="profile-page">
      
      <div className='profile-page__masthead' ref={ masthead }>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column className="b-profile-header">
            <Segment className="b-profile-hero container"> 

            <Card className="left fluid b-border--none contact">
              <Card.Content textAlign="left">
              <div className="card__hero" >
                    <div className="card__hero-col">
                    <Card.Header>
                        <h2>{profile.FullName}</h2>
                        <h4>Call 2008  &nbsp;|&nbsp; Silk 2019</h4>
                        <Label size="small" circular className={
                                    (profile.PersonTypeCode === 0 ? 'primary' : '') + 
                                    (profile.PersonTypeCode === 1 ? 'secondary' : '') + 
                                    (profile.PersonTypeCode === 2 ? 'tertiary' : '')}>{(profile.ClerkPostTitle) ? (profile.ClerkPostTitle) : (profile.PersonTypeCodeName)}</Label>
                                    
                  <span className='b-list-people__job'></span>
                                    {/* <Label size="small" circular className="primary"><Icon className="handshake outline" />Mediator &nbsp;|&nbsp; Arbitrator</Label> */}
                    </Card.Header>
                    <Card.Meta>
                      <span className="card__hero-location">
                        <a href="#" onClick={(e) => loadSelectedBusinessProfile(e, profile.BusinessId) }>{profile.BusinessName}</a> <Icon name="map marker alternate" /> <a onClick={(e) => loadSelectedBusinessProfile(e, profile.BusinessId) }>{profile.City} (HQ)</a>
                      </span>  
                      <div className="card__hero-buttons">
                         {profile.Telephone && <Button size="big" className="primary" href={`tel: ${profile.Telephone} `} >Call</Button>}
                        {profile.Email && <Button size="big" inverted className="primary" href={`mailto:${profile.Email}`}>Email</Button> }
                       { isCurrentUser && <Button size="big" inverted className="primary" href=" " onClick={(e: { preventDefault: () => void; }) => {openProfileModal(); e.preventDefault();return false;}}>Edit</Button> }
                      </div>
                    </Card.Meta>
                        </div>
                        <div className="card__hero-col">               
                        <Icon.Group size='huge' className="left floated card__image">
                          <div className="card__hero-socials">
                            <div className="card__hero-socials-center">
                              {/* { profile.Business.WebsiteUrl &&  <Button circular icon='world' target="_blank" href={ profile.Business.WebsiteUrl }  /> }
                              { profile.Business.Twitter &&  <Button circular icon='linkedin'target="_blank" href={ profile.Business.Twitter }   /> }
                              { profile.Business.LinkedIn &&  <Button circular icon='twitter' target="_blank" href={ profile.Business.LinkedIn }   /> } */}

                            </div>
                          </div>
                          {/* with background image */}
                          <span className="e-avatar e-avatar-large" style={{ backgroundImage: `url(${profileImage.imgUrl ? profileImage.imgUrl : profileImage})` }}></span>
                        </Icon.Group>
                        </div>
                      </div>
                    </Card.Content>
              </Card>
              </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </div>
      <Tab className="tabs tabs--main"
        panes={panesToShow}
        defaultActiveIndex={activePage.current}
        onTabChange={tabChange}
      />
    </div>
    </div>
    </Fragment>
  );
};

export default observer(ProfilePage);
