import React, { Fragment, useContext } from 'react';
import { RootStoreContext } from '../../app/stores/rootStore';
// @ts-ignore
import { Button, Modal } from 'semantic-ui-react';
import { router } from '../../app/router/Routes';

const SubmitSetPassword = () => {
  const rootStore = useContext(RootStoreContext);
  const { closeModal } = rootStore.modalStore;
  const { setPasswordResponse } = rootStore.userStore;
  const firstLine = localStorage.getItem('resendVerificationEmailData') ? 'Registration details resent.' : 'Thank you for registering your details.';

  const passwordSetComplete = <Fragment>
          <Modal.Header>Password set successfully</Modal.Header>
          <Modal.Content>
            <Modal.Description>
            <p>Your password has been set and you can now log into Opus 2 Lex Network</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Close"
              onClick={() => {router.navigate('/', {replace: true}); closeModal()}} 
              positive
            />
          </Modal.Actions>
        </Fragment>;

  const userNotFound = <Fragment>
          <Modal.Header>User not found<i className="icon small exclamation"></i></Modal.Header>
          <Modal.Content>
            <Modal.Description>
            <p>User cannot be found please contact Opus 2 Lex for support</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Close"
              onClick={() => closeModal()} 
              positive
            />
          </Modal.Actions>
        </Fragment>;

  const setPasswordError = <Fragment>
          <Modal.Header>Unable to set your password<i className="icon small exclamation"></i></Modal.Header>
          <Modal.Content>
            <Modal.Description>
            <p>Ooops, something went wrong please contact Opus 2 Lex for support</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Close"
              onClick={() => closeModal()} 
              positive
            />
          </Modal.Actions>
        </Fragment>;
  
  const jsx = () => {
    switch(setPasswordResponse) {
      case 2004:
        return passwordSetComplete;
      case 9002:
        return userNotFound;
      default:
        return setPasswordError;
    }
  }

  return jsx();
};

export default SubmitSetPassword;
