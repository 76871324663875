import React, { Fragment, useContext, useEffect, MouseEvent, useState, useRef } from 'react';

import { Grid, Segment, Icon, Label, Card, Button, Tab } from 'semantic-ui-react';
import { RootStoreContext } from '../../../app/stores/rootStore';
import Slider from "react-slick";
import { toJS } from 'mobx';
import Offices from './tabs/Offices';
import People from './tabs/People';
import Barristers from './tabs/Barristers';
import Clerks from './tabs/Clerks';
import BusinessEvents from './tabs/BusinessEvents';
import useOnScreen from '../../../app/common/util/useOnScreen'
import lodash from 'lodash';
import { router } from '../../../app/router/Routes';
import { useParams } from 'react-router-dom';
// import sanitizeHtml from 'sanitize-html';


interface RouteParams {
  id: string;
  ind: string
}

const BusinessProfile: React.FC = () => {
  const {id} = useParams()
  const rootStore = useContext(RootStoreContext);
  const{ openModal } = rootStore.modalStore;
  const{ getBusinessProfile, getSelectedBusinessHq, getBusinessPeople, getBusiness } = rootStore.businessStore;
  const{ getUserPermissions } = rootStore.userStore;
  const { setPersonProfile } = rootStore.personStore;
  const { setChambersActiveTab, getChambersActiveTab } = rootStore.commonStore;

  const options = toJS(getUserPermissions());

  let profileOpts: any = options.filter(obj => {
      return obj['LocationName'] === 'Profile';
    }
  );

  profileOpts = profileOpts.map((opt: any) => opt.SystemOptionName);
  profileOpts.push('Barristers'); // THIS IS TEMPORARY UNTIL WE HAVE USER ROLE FROM API/DB
  profileOpts.push('Staff'); // -----------------------||----------------------------

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    play: true
  };

  const [profile, setProfile]: any = useState(getBusinessProfile());
  const [thisProfile, setThisProfile]: any = useState(getBusiness(id));
  const [businessProfile, setBusinessProfile]: any = useState(getSelectedBusinessHq()); 
  const [businessPeople, setBusinessPeople]: any = useState(getBusinessPeople());
  const activePage: any = useRef(getChambersActiveTab());
  //custom hook to see if element is in viewport
  const masthead: any = useRef<HTMLDivElement>();
  const isMastheadInViewport: boolean = useOnScreen<HTMLDivElement>(masthead, "0px", true);
  const getLocations = (location: number) =>{
    if ( location > 2){
      return `- ${location - 1} other locations`;
    }else if ( location === 2){
      return `- 1 other location`;
    }
    else{
      return;
    }
  }
  
  // const cleanHtml = sanitizeHtml(businessDesc);
  
  let barristerType: any = [];
  let clerkType: any = [];
  
  useEffect(() => {
    setProfile(getBusinessProfile());
    setBusinessPeople(getBusinessPeople());
    setThisProfile(getBusiness(id));
    setBusinessProfile(getSelectedBusinessHq());
  }, []);

  let toJSbarristerType = toJS(businessPeople);
  barristerType = toJSbarristerType.filter((person: { PersonTypeCode: number; }) => person.PersonTypeCode == 1);

  // Lodash lets us orderby multiple fields e.g. orderby YearOfSilk then DiaryOrder & either ascending or descending
  let orderBybarristerType = lodash.orderBy(barristerType, ['YearOfSilk', 'DiaryOrder'], ['asc', 'asc']);
  barristerType = orderBybarristerType;

  // just clerks wanted i.e. with PersonTypeCode of 2
  clerkType = toJSbarristerType.filter((person: { PersonTypeCode: number; }) => person.PersonTypeCode == 2);
  // order clerkType by LastName
  clerkType = lodash.orderBy(clerkType, 'LastName', 'asc');



  const loadSelectedPersonProfile = async (id: string) => {
    await setPersonProfile(id);
    router.navigate('/profile/' + id);
  };

  const tabChange = (e: any, data: any) => {
    setChambersActiveTab(data.activeIndex);
  }

  const panes = [
    { menuItem: 'Profile', render: () => 
        <Tab.Pane>
        <Grid className="container margin-fix" textAlign="center">
          <Grid.Row columns={16}>
          <Grid.Column width="16">  
            <Segment textAlign="center" className="b-shadow b-border--none">
            <Slider {...sliderSettings}>
                <div className='slick__card'>
                    <blockquote className='slick__quote'>“Unfailingly helpful and accommodating. You always feel that you are receiving an absolutely premium service.”</blockquote>
                    <p className='slick__title'>The Legal 500</p>
                </div>
                <div className='slick__card'>
                    <blockquote className='slick__quote'>"An outstanding bench of barristers, from market-leading silks to rising star juniors"</blockquote>
                    <p className='slick__title'>Chambers and Partners</p>
                </div>
                <div className='slick__card'>
                    <blockquote className='slick__quote'>“The set consistently handles the most significant, high-value and high-profile disputes both domestically and internationally.”</blockquote>
                    <p className='slick__title'>Chambers and Partners</p>
                </div>
            </Slider>
            <div className="b-award__wrap small">
                  <div className="b-award">
                    <img src="/assets/images/awards/the-legal-500__2021__top-tier-set__black.svg" alt='Legal 500' />
                  </div>
                  <div className="b-award">
                    <img src="/assets/images/awards/the-legal-500__2021__leading-set__black.svg" alt='Legal 500'  />
                  </div>
                  <div className="b-award">
                    <img src="/assets/images/awards/chambers-and-partners__2020__shortlisted-uk-bar.svg" alt='Chambers and Partners - Shortlisted UK Bar'  />
                  </div> 
            </div>
            </Segment>
          </Grid.Column>
          
          </Grid.Row>
          <Grid.Row>
          <Grid.Column className={`column sixteen wide ${(businessProfile.HeadsOfChambers && businessProfile.HeadsOfChambers.length > 0) ||  (businessProfile.SeniorManagementTeam && businessProfile.SeniorManagementTeam.length > 0) ? 'mobile ten wide tablet eleven wide computer' : ''}`} >
                       
            <Segment textAlign="left" className="b-shadow spacing-x30 b-border--none">

              { (businessProfile.Description && businessProfile.Description.length > 0) ?
                <> 
                  <p><div dangerouslySetInnerHTML={{__html: businessProfile.Description}}/></p><br /><br /> 
                </>
              : 
                <><i>No Description</i><br /><br /></>
              }  
             { (businessProfile.BarMutualAreasOfPractice && businessProfile.BarMutualAreasOfPractice.length > 0) ?
              <> 
                <h2 className='center'><strong>Bar Mutual Areas Of Practice</strong></h2>
                
                {businessProfile.BarMutualAreasOfPractice.map((aop: any) => {
                  return <Button compact>{aop}</Button>;
                })}
              </>
              : ''
              }
            </Segment>
          </Grid.Column>
          {(businessProfile.HeadsOfChambers && businessProfile.HeadsOfChambers.length > 0) ||  (businessProfile.SeniorManagementTeam && businessProfile.SeniorManagementTeam.length > 0)  ? 
          <Grid.Column className="sixteen wide mobile six wide tablet five wide computer column">
            <Segment textAlign="left" className="b-shadow spacing-x30 b-border--none">
              
  { ( businessProfile.HeadsOfChambers &&  businessProfile.HeadsOfChambers.length > 0) ? 
    <>
    <div className='b-list-people__wrapper'>
      <h3><strong>Head{ (businessProfile.HeadsOfChambers.length > 1) && 's' } of {businessProfile.BusinessTypeCodeName}</strong></h3>
      <div className="b-list-people__list compact left">
      
        { businessProfile.HeadsOfChambers.map((hocProfile: any, index: any) => (
          <>
            <div className="b-list-people__item"> 
              <span className='b-list-people__image small border'>
                    <span className="e-avatar e-avatar-circle"  style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png)'}}></span>
              </span>
              <a href=" " className='b-list-people__link' onClick={(e) => {loadSelectedPersonProfile(hocProfile.PersonId);e.stopPropagation();e.preventDefault();return false;}}>
                
                <span className='b-list-people__info'>
                  <span className='b-list-people__name'>{hocProfile.FullName}</span>
                  <span className='b-list-people__job'>{(hocProfile.ClerkPostTitle) ? (hocProfile.ClerkPostTitle) : (hocProfile.PersonTypeCodeName)}</span>
                </span>
              </a> 
            </div>
          </>
        ))} 
      </div>
    </div>
  </>
  : ''                
  }    
  { (businessProfile.SeniorManagementTeam && businessProfile.SeniorManagementTeam.length > 0) ? 
    <>
    <div className='b-list-people__wrapper'>

      <div className="b-list-people__title left"><h3><strong>Senior Management Team</strong></h3> </div>
      <div className="b-list-people__list compact left">
        { businessProfile.SeniorManagementTeam.map((hocProfile: any, index: any) => (
          <>
            <div className="b-list-people__item">
              <span className='b-list-people__image small border'>
                  <span className="e-avatar e-avatar-circle"  style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/user-blank.png)'}}></span>
              </span>
              <a href=" " className='b-list-people__link' onClick={(e) => {loadSelectedPersonProfile(hocProfile.PersonId);e.stopPropagation();e.preventDefault();return false;}}>
                
                <span className='b-list-people__info'>
                  <span className='b-list-people__name'>{hocProfile.FullName}</span>
                  <span className='b-list-people__job'>{(hocProfile.ClerkPostTitle) ? (hocProfile.ClerkPostTitle) : (hocProfile.PersonTypeCodeName)}</span>
                </span>
              </a> 
            </div>
          </>
        ))} 
      </div>
    </div>
  </>
  : ''                
  }<br />
  </Segment>

          </Grid.Column> : ''} 

          </Grid.Row>
        </Grid>
        </Tab.Pane>
    },
    { menuItem: `Offices (${profile.length})`, menuItem2: 'Offices', render: () => 
      <Tab.Pane> 
        <Grid className="container margin-fix" textAlign="center">
          <Grid.Row columns={16}>
          <Grid.Column width="16">
             <Offices offices={toJS(profile)} />
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </Tab.Pane> },
      // { menuItem: `People (${businessPeople.length})`, menuItem2: 'People', render: () => 
      // <Tab.Pane key={barristerType.PersonId}> 
      //   <Grid className="container margin-fix" textAlign="center">
      //     <Grid.Row columns={16}>
      //     <Grid.Column width="16">
      //       <People people={toJS(businessPeople)} />
      //     </Grid.Column>
      //     </Grid.Row>
      //   </Grid>
      // </Tab.Pane> },
    { menuItem: `Barristers (${barristerType.length})`, menuItem2: 'Barristers', render: () => 
      <Tab.Pane key={barristerType.PersonId} > 
          <Barristers barristers={barristerType} />
      </Tab.Pane> },
    { menuItem: `Staff (${clerkType.length})`, menuItem2: 'Staff', render: () => 
      <Tab.Pane key={clerkType.PersonId} > 
          <Clerks clerks={clerkType} />
      </Tab.Pane> },
    { menuItem: 'Cases', render: () => <Tab.Pane>Cases</Tab.Pane> },
    { menuItem: 'Stats', render: () => <Tab.Pane>Stats</Tab.Pane> },
    { menuItem: 'Events', render: () => <Tab.Pane><BusinessEvents /></Tab.Pane> },
  ]

  const panesToShow = panes.filter(pane => profileOpts.includes(pane.menuItem) || profileOpts.includes(pane.menuItem2));

  // if (loadingProfile) return <LoadingComponent content='Loading profile...' />;

  return (
    <Fragment>
    <div className='b-container__scroller'>
    <div className={'b-sticky-header__wrapper ' + (isMastheadInViewport ? '' : 'isSticky')}>
      <div className="b-sticky-header">
        <div className="b-sticky-header__row">
          <div className="b-sticky-header__col b-sticky-header__col--avatar">
            <span className="e-avatar e-avatar-circle e-avatar-large" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/company-blank.png)'}}></span>
          </div>
          <div className="b-sticky-header__col">
            {/* <h3>{businessProfile.Name}</h3> */}
            <Label size="tiny" circular className={
              (businessProfile.BusinessTypeCode === 0 ? 'primary' : '') + 
              (businessProfile.BusinessTypeCode === 1 ? 'secondary' : '') + 
              (businessProfile.BusinessTypeCode === 2 ? 'tertiary' : '')}>{businessProfile.BusinessTypeCodeName}</Label>
            <div className="meta">
              <span className="card__hero-location"><Icon name="map marker alternate" /> <a href="#">{businessProfile.City}</a></span>
            </div>    
          </div>
          <div className="b-sticky-header__col right">
              <div className="card__hero-buttons">
                {businessProfile.Telephone && <Button size="small" className="primary" href={'tel:' + businessProfile.Telephone} >Call</Button> }
                {businessProfile.EmailAddress &&  <Button size="small" inverted className="primary" href={`mailto:${businessProfile.EmailAddress}`}>Email</Button>}
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className="profile-page">
      <div className='profile-page__masthead' ref={ masthead }>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column className="b-profile-header">
            <Segment className="b-profile-hero container">
            <Card className="left fluid b-border--none contact">
              <Card.Content textAlign="left">
                <div className="card__hero">
                  <div className="card__hero-col">
                    <Card.Header>
                      <h2>{businessProfile.Name}</h2>
                      {<Label size="small" circular className={
                          (businessProfile.BusinessTypeCode === 0 ? 'primary' : '') + 
                          (businessProfile.BusinessTypeCode === 1 ? 'secondary' : '') + 
                          (businessProfile.BusinessTypeCode === 2 ? 'tertiary' : '')
                          }
                        >
                        {businessProfile.BusinessTypeCodeName}</Label>
                      }
                    </Card.Header>
                    <Card.Meta>
                      <span className="card__hero-location">
                        {businessProfile.AddressLine1}{businessProfile.AddressLine1 && <br /> }
                        {businessProfile.AddressLine2}{businessProfile.AddressLine2 && <br /> }
                        {businessProfile.AddressLine3}{businessProfile.AddressLine3 && <br /> } 
                        {businessProfile.City} &nbsp;{businessProfile.PostCode} <br /><br />
                      <Icon name="map marker alternate" /> {businessProfile.City} { (businessProfile.HeadOffice && businessProfile.NumberOfOffices > 1) && <span> (HQ)</span> } {getLocations(businessProfile.NumberOfOffices)}
                                                                         
                      </span>  
                      <div className="card__hero-buttons">
                         {businessProfile.Telephone && <Button size="big" className="primary" href={'tel:' + businessProfile.Telephone} >Call</Button> }
                         {businessProfile.EmailAddress &&  <Button size="big" inverted className="primary" href={`mailto:${businessProfile.EmailAddress}`}>Email</Button>}
                      </div>
                    </Card.Meta>
                  </div>
                  <div className="card__hero-col">
                    <Icon.Group size='huge' className="left floated card__image">
                      <div className="card__hero-socials">
                        <div className="card__hero-socials-center">
                          { businessProfile.WebsiteUrl &&  <Button circular icon='world' target="_blank" href={ businessProfile.WebsiteUrl }  /> }
                          { businessProfile.TwitterUrl &&  <Button circular icon='linkedin'target="_blank" href={ businessProfile.TwitterUrl }   /> }
                          { businessProfile.LinkedInUrl &&  <Button circular icon='twitter' target="_blank" href={ businessProfile.LinkedInUrl }   /> }
                        </div>
                      </div>
                      {/* with background image */}
                      <span className="e-avatar e-avatar-large" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/images/avatar/large/company-blank.png)'}}></span>
                    </Icon.Group>
                  </div>
                </div>
              </Card.Content>
            </Card>
              </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </div>
      <Tab 
        panes={panesToShow}
        className="tabs tabs--main"
        defaultActiveIndex={activePage.current}
        onTabChange={tabChange}
      />
    </div>
    </div>
    </Fragment>
  );
};

export default BusinessProfile;
