import React, { FC, useState, useCallback, useContext, useEffect } from 'react'
import { Card } from './Card'
import update from 'immutability-helper'
import { RootStoreContext } from '../../../app/stores/rootStore'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

export const ListContainer: FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { profile, profileAols, getProfileAreasOfLaw } = rootStore.profileStore;
  
  const [cards, setCards] = useState(profileAols);
  // const [someotherstate, setSomeotherstate] = useState(profile!.areasOfLaw);
  // const [cards, setCards] = useState(JSON.parse(localStorage.getItem('sortableList')!));

  console.log('JSON.parse(localStorage.getItem(sortableList) ', JSON.parse(localStorage.getItem('sortableList')!))
  console.log('in ListContainer cards state var = ', cards)
  console.log('in ListContainer cards state var = ', toJS(getProfileAreasOfLaw()))

  useEffect(() => {
    console.log('Cards was updated', toJS(cards))
    localStorage.setItem('sortableList', JSON.stringify(toJS(cards)))
  }, [cards])

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    const dragCard = toJS(cards[dragIndex]);

    console.log('In moveCard') 
    console.log('dragIndex ===== ', dragIndex)
    console.log('hoverIndex ==== ', hoverIndex)
    console.log('dragCard ======', dragCard)

    setCards(
      update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    )
  },[cards])

  const reorderMultiselect = useCallback((dragIndex: number, hoverIndex: number) => {
    const dragCard = toJS(cards[dragIndex]);
    console.log('In reorderMultiselect') 
    console.log('dragIndex ===== ', dragIndex)
    console.log('hoverIndex ==== ', hoverIndex)
    console.log('dragCard ======', dragCard)
    localStorage.setItem('reorderMultiselect', JSON.stringify(cards));
    localStorage.setItem('sortableList', JSON.stringify(cards));
    localStorage.setItem('multiselectcomboElement', JSON.stringify(cards));
  },[cards])

  const renderCard = (card: { id: number; text: string }, index: number) => {
    console.log('card s= ', JSON.stringify(card))
    // console.log('index = ', index)

    console.info('In renderCard')
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
        reorderMultiselect={reorderMultiselect}
      />
    )
  }

  return (
    <>
      <div className="dragdrop-container">{cards.map((card: any, i: any) => renderCard(card, i))}</div>
    </>
  )
}

export default observer(ListContainer);