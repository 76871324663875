import React from 'react';

import { Card, Icon } from 'semantic-ui-react';
import { MapContainer, Marker, TileLayer, Popup, ZoomControl } from "react-leaflet";
import { toast } from 'react-toastify';
import { mapMarker } from "../../../../app/common/map/MapMarker";

interface IProps {
  offices: any;
}

const Offices: React.FC<IProps> = ({ offices }) => {
  
  return (
    < >
      { offices.map((office: any, index: number) => {
        let lat: number = 0;
        let lon: number = 0;

        if (office.Latitude) {
           lat = office.Latitude;
           lon = office.Longitude;
        }

        return (
          <Card className="left fluid b-shadow b-border--none b-address__card" key={index}>
            <Card.Content textAlign="left">
              <Card.Description className="small">
                <div className="b-address">
                  <div className="b-address__map">
                    <div className="b-address__map-inner">
                      { lat !== 0 ? (
                        <MapContainer center={[lat, lon]} zoom={15} zoomControl={false} scrollWheelZoom={false} wheelPxPerZoomLevel={50}>
                          <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />      
                          <ZoomControl position="bottomright"/>
                          <Marker key={`marker-${lat}${lon}`} position={[lat, lon]}  icon={mapMarker} >
                            <Popup position={[lat, lon]} offset={[-3, 150]}>
                              <span>{office.BusinessName}</span>
                            </Popup>
                          </Marker>
                        </MapContainer>
                       ) : (
                        <div className='b-result__map-error'>
                            <Icon name='map marker alternate' size='big' />
                            <p>Sorry no map information is available</p>
                        </div>
                       ) }
                    </div>
                  </div>
                  <div className="b-address__content">
                    <div className="b-address__title">{office.City}</div>
                    <div className="b-address__info">
                      <div className="b-address__info-column">
                        <a onClick={() => {navigator.clipboard.writeText(`${office.AddressLine1} 
                          ${office.AddressLine2}
                          ${office.AddressLine3}
                          ${office.City}
                          ${office.Postcode}`); toast("Address copied to clipboard", {autoClose: 2500});}} title="Copy to Clipboard"
                        >
                        {office.AddressLine1}<br />
                        {office.AddressLine2}{office.AddressLine2 && <br />}
                        {office.AddressLine3}{office.AddressLine3 && <br />}
                        {office.City}{office.City && <br />}
                        {office.Postcode}{office.Postcode && <br />}
                        </a>
                      </div>
                      <div className="b-address__info-column">
                        {office.Dx && <a href="#" className="b-address__link placeholder"><Icon name="box" size="small" />{office.Dx}</a>}
                        {office.Telephone1 &&  <a href={`tel:${office.Telephone1}`} className="b-address__link"><Icon name="phone" size="small" />{office.Telephone1}</a> }
                        {office.Fax1 &&  <a href={ `mailto:${office.Fax1}` }className="b-address__link"><Icon name="fax" size="small" />{office.Fax1}</a> }
                        {office.EmailAddress1 &&  <a href={ `mailto:${office.EmailAddress1}` } className="b-address__link"><Icon name="envelope" size="small" />{office.EmailAddress1}</a> }
                      </div>  
                    </div>  
                  </div>
                </div>
              </Card.Description>                            
            </Card.Content>
          </Card>
        ); 
    })}
    </>
  );
};

export default Offices;
