import React, { Fragment, useContext, useEffect } from 'react';
import Navigation from '../../features/nav/Navigation';
// import DockSideBar from '../../features/nav/DockSideBar';
import { observer } from 'mobx-react-lite';
import { Outlet, useLocation } from 'react-router-dom';
// import EmailConfirmation from '../../features/registration/EmailConfirmation';
// import ForgottenPassword from '../../features/registration/ForgottenPassword';
// import EmailVerified from '../../features/registration/EmailVerified';
import { ToastContainer } from 'react-toastify';
import { RootStoreContext } from '../stores/rootStore';
import PreloaderComponent from './PreloaderComponent';
import ModalContainer from '../common/modals/ModalContainer';
import TopNavigation from '../../features/nav/TopNavigation';
import { CSSTransition, TransitionGroup} from 'react-transition-group';
import FilterContainer from '../common/modals/FilterContainer';

// const App: React.FC<RouteComponentProps> = ({ location }) => {
const App = () => { //udemy guy uses function App() {... syntax
  const location = useLocation();
  const rootStore = useContext(RootStoreContext);
  const {
    setAppLoaded,
    // token,
    appLoaded 
  } = rootStore.commonStore;

	const { loggedInUser, isLoggedIn, registrationComplete } = rootStore.userStore;

  const AnimatedSwitch = () => (
    <div className="b-container">
      <div className={ 'sm__pusher ' + (isLoggedIn && loggedInUser && registrationComplete ? '' : 'sm--hidden')} id="sm__pusher">
        { isLoggedIn && loggedInUser && registrationComplete  ? ( <Navigation />) : ''}
          <div className="b-container__main">
            { isLoggedIn && loggedInUser && registrationComplete ? ( <TopNavigation />) : ''}
            <TransitionGroup className="slide__wrap">
              <CSSTransition key={location.key} classNames="slide" timeout={1500}>
                <div className='b-container__inner'>
                  <Outlet />
                </div>
              </CSSTransition>
            </TransitionGroup>
        </div>
      </div>
    </div>
  );
  // const {getUser} = rootStore.userStore;

  // useEffect(() => {
  //   if (token) {
  //     getUser().finally(() => setAppLoaded())
  //   } else {
  //     setAppLoaded();
  //   }
  // }, [getUser, setAppLoaded, token])

  useEffect(() => {
    setAppLoaded();
  }, [ setAppLoaded])

  if (!appLoaded) return <PreloaderComponent />
  
  return (
    <Fragment>
      <ModalContainer />
      <FilterContainer />
      <ToastContainer position='bottom-right' />
      {/* <Route exact path='/confirmEmailAddress' component={EmailConfirmation} />
      <Route exact path='/forgottenPassword' component={ForgottenPassword} />
      <Route exact path='/emailVerified' component={EmailVerified} /> */}
      <AnimatedSwitch />
      
    </Fragment>
  );
};

export default observer(App);
