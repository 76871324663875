import React from 'react';
const ProfileStats = () => {
  return (
    <div className="ui center aligned grid container margin-fix">
        Stats
    </div>
  );
};

export default ProfileStats;
